import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Dropdown from "../../components/Dropdown";
import ProfileInformation from "./ProfileInformation";
import Login from "./Login";
import Notifications from "./Notifications";
import Payment from "./Payment";
import axiosInstance from "../../utils/axios";
import { SELLER_PROFILE } from "../../utils/api";

const Settings = () => {
	const [sellerData, setSellerData] = useState(null);
	function getSellerData() {
		axiosInstance
			.get(SELLER_PROFILE)
			.then((res) => {
				setSellerData(res?.data?.payload);
			})
			.catch(console.error);
	}
	useEffect(getSellerData, []);

	const options = [];
	// navigation.map((x) => options.push(x.title));
	const [activeTab, setActiveTab] = useState(options[0]);

	const [activeIndex, setActiveIndex] = useState(0);
	const scrollToProfile = useRef(null);
	const scrollToLogin = useRef(null);
	const scrollToNotifications = useRef(null);
	const scrollToPayment = useRef(null);

	const handleClick = (x, index) => {
		setActiveIndex(index);
		x.action();
	};
	const [image, setImage] = useState("");
	const imageRef = useRef(null);

	function useDisplayImage() {
		const [banner, setBanner] = useState("");

		function uploader(e) {
			const imageFile = e.target?.files[0];
			const reader = new FileReader();
			reader.addEventListener("load", (e) => {
				setBanner(e.target?.result);
			});
			reader.readAsDataURL(imageFile);
		}

		return { banner, uploader };
	}

	const { banner, uploader } = useDisplayImage();
	function resetFields(e) {
		e.target.firstName.value = sellerData?.seller?.firstName;
		e.target.lastName.value = sellerData?.seller?.lastName;
		e.target.mobileNumber.value = sellerData?.mobileNumber;
		e.target.email.value = sellerData?.email;
		e.target.businessName.value = sellerData?.seller?.businessName;
		e.target.customerCareNumber.value = sellerData?.seller?.customerCareNumber;
		e.target.customerCareEmail.value = sellerData?.seller?.customerCareEmail;
		e.target.address.value = sellerData?.seller?.address;
	}
	async function handleSubmit(e) {
		e.preventDefault();
		const firstName = e.target?.firstName?.value;
		const lastName = e.target?.lastName?.value;
		const mobileNumber = e.target?.mobileNumber?.value;
		const email = e.target?.email?.value;
		const businessName = e.target?.businessName?.value;
		const customerCareNumber = e.target?.customerCareNumber?.value;
		const customerCareEmail = e.target?.customerCareEmail?.value;
		const address = e.target?.address?.value;
		const payloadData = {
			firstName,
			lastName,
			mobileNumber,
			email,
			businessName,
			customerCareNumber,
			customerCareEmail,
			address,
			banner, // image base64
		};
		console.table(payloadData);
		/*  */
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		// Update the seller here
		/*  */
	}
	return (
		<>
			<form
				onSubmit={handleSubmit}
				onReset={resetFields}
				className="flex flex-col items-center grow px-4 py-2 rounded-lg"
			>
				<div className="flex grow items-center">
					<div className="flex flex-col justify-center">
						{/* name, mobile start*/}
						<div className="flex gap-x-4 max-md:flex-col">
							<div className="border w-1/3 p-1  transition-all">
								<p className="font-semibold">Name</p>
								<input
									type="text"
									placeholder="Name"
									name="name"
									required
									defaultValue={sellerData?.name}
									className="px-3 py-2 w-full rounded-md text-black"
								/>
							</div>
							{/* <div className="border w-1/3 p-1  transition-all">
								<p className="font-semibold">Last Name</p>
								<input
									type="text"
									placeholder="Last Name"
									name="lastName"
									required
									defaultValue={sellerData?.seller?.lastName}
									className="px-3 py-2 w-full rounded-md text-black"
								/>
							</div> */}
							<div className="border w-1/3 p-1  transition-all">
								<p className="font-semibold">Mobile</p>
								<input
									type="number"
									placeholder="Mobile"
									name="mobileNumber"
									required
									defaultValue={sellerData?.mobileNumber}
									className="px-3 py-2 w-full rounded-md text-black"
								/>
							</div>
						</div>
						{/* name, mobile end*/}

						{/* Email business start*/}
						<div className="flex gap-x-4 max-md:flex-col">
							<div className="border w-1/2 p-1  transition-all">
								<p className="font-semibold">Email</p>
								<input
									type="email"
									placeholder="Email"
									name="email"
									required
									defaultValue={sellerData?.email}
									className="px-3 py-2 w-full rounded-md text-black"
								/>
							</div>

							<div className="border w-1/2 p-1  transition-all">
								<p className="font-semibold">Business Name</p>
								<input
									type="text"
									name="businessName"
									placeholder="Business Name"
									required
									defaultValue={sellerData?.seller?.businessName}
									className="px-3 py-2 w-full rounded-md text-black"
								/>
							</div>
						</div>
						{/* Email business start*/}

						{/* customer care number & email start*/}
						<div className="flex gap-x-4 max-md:flex-col">
							<div className="border p-1  transition-all w-full">
								<p className="font-semibold">Customer Care Number</p>
								<input
									type="text"
									placeholder="Customer Care Number"
									name="customerCareNumber"
									required
									defaultValue={sellerData?.seller?.customerCareNumber}
									className="px-3 py-2 w-full rounded-md text-black"
								/>
							</div>
							<div className="border p-1  transition-all w-full">
								<p className="font-semibold">Customer Care Number</p>
								<input
									type="text"
									placeholder="Customer Care Email"
									name="customerCareEmail"
									required
									defaultValue={sellerData?.seller?.customerCareEmail}
									className="px-3 py-2 w-full rounded-md text-black"
								/>
							</div>
						</div>
						{/* customer care number & email end*/}

						{/* Address start*/}
						<div className="flex gap-x-4 max-md:flex-col">
							<div className="border p-1  transition-all w-full">
								<p className="font-semibold">Address</p>
								<textarea
									type="text"
									placeholder="Address"
									name="address"
									required
									defaultValue={sellerData?.seller?.address}
									className="px-3 py-2 w-full rounded-md text-black"
								/>
							</div>
						</div>
						{/* Address end*/}

						{/* Banner start */}
						<div className="flex gap-x-4 max-md:flex-col">
							<div className="border p-1  transition-all w-full">
								<p className="font-semibold">Banner Image</p>
								{(banner || sellerData?.seller?.bannerUrl) && (
									<img
										ref={imageRef}
										src={banner || sellerData?.seller?.bannerUrl}
										alt="Banner"
										className="w-full max-h-44 object-contain bg-black/30 rounded-md"
									/>
								)}
								<input
									type="file"
									required
									onChange={(e) => {
										setImage(e.target?.files[0]);
										uploader(e);
									}}
								/>
							</div>
						</div>
						{/* Banner end */}
					</div>
				</div>
				<div className="flex gap-x-4">
					<button
						type="submit"
						className="bg-pink-600 px-12 py-2 rounded-md text-white hover:bg-green-400 active:bg-pink-600 transition-all"
					>
						Save
					</button>
					<button
						type="reset"
						className="bg-pink-600 px-12 py-2 rounded-md text-white hover:bg-red-500 active:bg-pink-600 transition-all"
					>
						Reset
					</button>
				</div>
			</form>
			<TooltipGlodal />
			<style>{`
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}			
			`}</style>
		</>
	);
};

export default Settings;
