import React, { useEffect, useRef } from "react";
import { Button } from "antd";
import { CountrySelector, usePhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const AntPhone = ({ value, onChange }) => {
	const phoneInput = usePhoneInput({
		defaultCountry: "ae",
		value,
		onChange: (data) => {
			const countryCode = data.phone.substring(0, data.phone.length - 1);
			onChange(countryCode);
		},
	});

	const inputRef = useRef(null);
	
	useEffect(() => {
		if (phoneInput.inputRef && inputRef.current?.input) {
			phoneInput.inputRef.current = inputRef.current.input;
		}
	}, [inputRef, phoneInput.inputRef]);

	return (
		<div className="flex group">
			<CountrySelector
				selectedCountry={phoneInput.country}
				onSelect={(country) => phoneInput.setCountry(country.iso2)}
				renderButtonWrapper={({ children, rootProps }) => (
					<Button
						{...rootProps}
						className="flex p-0 m-0 px-1 items-center justify-center mr-1 w-[100px] gap-x-2 bg-gray-100 border-none h-full rounded-xl group-hover:bg-white group-focus:bg-white transition-all"
					>
						{children}
						<input
							name="countryCode"
							type="tel"
							value={phoneInput.phone}
							ref={inputRef}
							className="p-0 text-center bg-gray-100  rounded-r-xl w-12 outline-none border-none group-hover:bg-white group-focus:bg-white transition-all font-['Inter'] font-semibold"
							disabled={true}
						/>
					</Button>
				)}
				dropdownStyleProps={{
					style: {
						top: "35px",
						// width:"auto"
					},
				}}
			/>
		</div>
	);
};

export default AntPhone;
