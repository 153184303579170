import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import { useProduct } from "../ProductContext";
import axiosInstance from "../../../utils/axios";
import {
	PRODUCT_DETAIL,
	GET_ALL_CATEGORY,
	ADD_PRODUCT,
	EDIT_PRODUCT,
} from "../../../utils/api";
import { EditorState, ContentState } from "draft-js";
import {
	showToast,
	TOAST_ERROR,
	TOAST_SUCCESS,
} from "../../../utils/constants";
import { logger } from "../../../utils/logger";
import { RotatingLines } from "react-loader-spinner";
import CommonInfoDialog from "../../../components/CommonInfoDialog/CommonInfoDialog";
import { FaCheckCircle } from "react-icons/fa";
import InfoModal from "./Modal";
import { toast } from "react-toastify";

const NameAndDescription = ({ className }) => {
	const [content, setContent] = useState();
	const [isLoading, setisLoading] = useState();
	const [currentMilun, setcurrentMilun] = useState("");
	const [infoPopupStatus, setinfoPopupStatus] = useState(false);

	const [categories, setCategories] = useState([]);
	const [fullCategoryData, setFullCategoryData] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [features, setFeatures] = useState([]);
	const { productId } = useParams();
	const navigate = useNavigate();
	const [showCpsiaWarning, setShowCpsiaWarning] = useState(false);

	const [productData, setProductData] = useState({
		brand: "",
		manufacturer: "",
	});
	const [selectedType, setSelectedType] = useState("");
	const [subCategory, setSubCategory] = useState({});
	const [selectedSubCategory, setSelectedSubCategory] = useState(null);
	const [subCategoryId, setSubCategoryId] = useState([]);
	const [currentSubCategoryId, setCurrentSubCategoryId] = useState("");
	const [subCategoryOptions, setSubCategoryOptions] = useState([]);
	const handleTypeChange = (value) => {
		setSelectedType(value);

		// Reset the other field when the type is changed
		if (value === "brand") {
			setProductData({ ...productData, manufacturer: "" });
		} else if (value === "manufacturer") {
			setProductData({ ...productData, brand: "" });
		}
	};

	const handleInputChange = (name, value, index) => {
		setProductData({ ...productData, [name]: value });
	};

	// async function getSubCategories() {
	// 	try {
	// 		const { data } = await axiosInstance.get(GET_ALL_CATEGORY);
	// 		if (data?.error) throw data?.message;
	// 		let subCatsId = [];
	// 		const result = Object.fromEntries(
	// 			data?.payload?.map((category) => [
	// 				category.name,
	// 				category.subCategory.map((sub) => {
	// 					subCatsId.push(sub._id);
	// 					return sub.name;
	// 				}),
	// 			])
	// 		);
	// 		console.log({
	// 			result,
	// 		});
	// 		console.log({
	// 			subCatsId,
	// 		});
	// 		setSubCategoryId(subCatsId);
	// 		setSubCategory(result);
	// 	} catch (error) {
	// 		console.error(error);
	// 		toast.error("No sub category found");
	// 	}
	// }

	useEffect(() => {
		console.log({ fullCategoryData });
		console.log({ selectedCategory });
		if (fullCategoryData && selectedCategory) {
			fullCategoryData.forEach((category) => {
				if (category._id.toString() === selectedCategory.value.toString()) {
					console.log({
						SELECTED: category,
					});
					const options = category.subCategory.map((ele) => ({
						value: ele._id,
						label: ele.name,
					}));
					setSubCategoryOptions(options);
				}
			});
		}
		// 	setSubCategoryOptions(
		// 		subCategory[selectedCategory?.label]?.map((sub, index) => ({
		// 			value: sub,
		// 			label: sub,
		// 			index,
		// 		}))
		// 	);
		// setTimeout(() => {
		// 	console.log({ subCategoryOptions });
		// }, 4000);
	}, [selectedCategory]);

	// useEffect(() => getSubCategories, []);
	// ------------------------------------------------------------USe for Madal---------------------------------------------------------------

	const [modalContent, setModalContent] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleModalOpen = (content) => {
		setModalContent(content);
		setIsModalOpen(true);
	};

	useEffect(() => {
		axiosInstance
			.get(GET_ALL_CATEGORY)
			.then((response) => {
				const categoryOptions = response.data.payload.map((cat) => ({
					value: cat._id,
					label: cat.name,
				}));
				setCategories(categoryOptions);
				setFullCategoryData(response.data.payload);
			})
			.catch((error) => console.error("Error fetching categories:", error));

		if (productId) {
			axiosInstance
				.get(`${PRODUCT_DETAIL.replace(":id", productId)}`)
				.then((response) => {
					const product = response.data.payload;
					setProductData(product);

					setSelectedCategory({
						value: product.category[0].categoryId,
						label: product.category[0].name,
					});

					if (product.features) {
						const extractedFeatures = product.features.map((f) => {
							const [key, value] = f.split(": ");
							return { key, value };
						});
						setFeatures(extractedFeatures);
					}
					const contentState = ContentState.createFromText(product.description);
					setContent(EditorState.createWithContent(contentState));
				})
				.catch((error) =>
					console.error("Error fetching product details:", error)
				);
		} else {
			setProductData({});
			setFeatures([]);
			setSelectedCategory(null);
		}
	}, [productId]);

	// const handleInputChange = (name, value) => {
	// 	setProductData((prev) => ({ ...prev, [name]: value }));
	// };

	const handleCpsiaCheckboxChange = () => {
		setShowCpsiaWarning(!showCpsiaWarning);
		if (showCpsiaWarning) {
			setContent(EditorState.createEmpty());
		}
	};

	// ════════════════════║ THIS FUNCTION CHECKS THE MANDATORY INPUT FIELDS   ║═════════════════════════
	const requiredCheck = (updatedProductData) => {
		console.log("the product data is...", updatedProductData);
		// if (
		// 	updatedProductData?.category === "" ||
		// 	updatedProductData?.category === undefined
		// ) {
		// 	showToast("Select Category", TOAST_ERROR);
		// 	return false;
		// }
		// if (updatedProductData?.brand === '' || updatedProductData?.brand === undefined) {
		// 	showToast("Enter brand name", TOAST_ERROR);
		// 	return false
		// }
		if (
			updatedProductData?.description === "" ||
			updatedProductData?.description === undefined
		) {
			showToast("Enter description", TOAST_ERROR);
			return false;
		}
		if (!updatedProductData?.subCategoryId) {
			showToast("Select Sub category", TOAST_ERROR);
			return false;
		}
		// if (updatedProductData?.descriptionInArabic === '' || updatedProductData?.descriptionInArabic === undefined) {
		// 	showToast("Enter description in arabic", TOAST_ERROR);
		// 	return false
		// }

		// if (updatedProductData?.manufacturer === '' || updatedProductData?.manufacturer === undefined) {
		// 	showToast("Enter manufacturer", TOAST_ERROR);
		// 	return false
		// }
		if (
			updatedProductData?.name === "" ||
			updatedProductData?.name === undefined
		) {
			showToast("Enter name", TOAST_ERROR);
			return false;
		}
		// if (updatedProductData?.nameInArabic === '' || updatedProductData?.nameInArabic === undefined) {
		// 	showToast("Enter name in arabic", TOAST_ERROR);
		// 	return false
		// }
		if (
			updatedProductData?.warrantyDetails === "" ||
			updatedProductData?.warrantyDetails === undefined
		) {
			showToast("Enter warranty months", TOAST_ERROR);
			return false;
		}
		if (
			updatedProductData?.warrantyExtraDetails === "" ||
			updatedProductData?.warrantyExtraDetails === undefined
		) {
			showToast("Enter warranty details", TOAST_ERROR);
			return false;
		}

		return true;
	};

	const handlePublish = async () => {
		const transformedFeatures = features.map(
			(feature) => `${feature.key}: ${feature.value}`
		);
		logger.log({ productData });
		let productDataNew = null;
		let updatedProductData = null;
		if (productId) {
			productDataNew = {
				...productData,
				category: productData?.category[0]?.categoryId,
			};
			updatedProductData = {
				...productDataNew,
				features: transformedFeatures,
			};
		} else {
			updatedProductData = {
				...productData,
				subCategoryId: selectedSubCategory?.value,
				features: transformedFeatures,
			};
		}

		let requiredStatus = requiredCheck(updatedProductData);
		if (!requiredStatus) {
			return;
		}
		const endpoint = productId ? `${EDIT_PRODUCT}/${productId}` : ADD_PRODUCT;
		try {
			setisLoading(true);
			const response = await axiosInstance.post(endpoint, updatedProductData);
			if (!response.data.error) {
				console.log("inside error");
				if (!productId) {
					const newProductId = response.data.payload._id;
					navigate(`/variant/add/${newProductId}?newProduct=true`);
				} else {
					showToast("Product updated successfully", TOAST_SUCCESS);
					// Optionally navigate to a different page or refresh the page
					// navigate('/products/released');
					setcurrentMilun(
						response?.data?.payload?.variants[
							response?.data?.payload?.variants?.length - 1
						]?.SKU
					);
					setinfoPopupStatus(true);
				}
			} else {
				console.log("inside error");

				console.error(
					productId ? "Failed to update product" : "Failed to add product"
				);
				showToast("Failed to update/add product", TOAST_ERROR);
			}
			setisLoading(false);
		} catch (error) {
			console.error(
				productId ? "Error updating product:" : "Error adding product:",
				error
			);

			showToast("Error occurred while updating/adding product", TOAST_ERROR);
			setisLoading(false);
		}
	};

	return (
		<>
			<CommonInfoDialog
				infoPopupStatus={infoPopupStatus}
				setinfoPopupStatus={setinfoPopupStatus}
			>
				<div className="w-full md:w-[500px] min-w-fit flex justify-center items-center flex-col space-y-5">
					<div class="font-bold text-center text-3xl mb-2 w-full flex justify-center items-center space-x-4">
						<span>
							<FaCheckCircle className="inline text-green-400 mr-2" />{" "}
						</span>
						Product Added Successfully
					</div>
					<div class="text-gray-700 text-lg text-semibold w-full text-center">
						<span>Milun - </span>
						<span>{currentMilun}</span>
					</div>
					<button
						onClick={() => navigate("/products/released")}
						type="button"
						className="rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-600/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
					>
						Explore Products
					</button>
				</div>
			</CommonInfoDialog>
			<Card title="Category Name & Description" classTitle="title-green">
				<div className={`${styles.field}`}>
					<label className={styles.label}>
						Category <span className="text-red-600">*</span>{" "}
					</label>
					<Select
						value={selectedCategory}
						onChange={(option) => {
							console.log({ option });
							setSelectedCategory(option);
							setSelectedSubCategory(null); // Reset subcategory when category changes
							handleInputChange("category", option.value);
						}}
						options={categories}
					/>
				</div>
				{selectedCategory && (
					<div className={`${styles.field} mt-5`}>
						<label className={styles.label}>
							Sub Category <span className="text-red-600">*</span>{" "}
						</label>
						<Select
							value={selectedSubCategory}
							onChange={(option) => {
								setSelectedSubCategory(option);
								const id = subCategoryId[option.index];
								setCurrentSubCategoryId(id);
								console.log("The index is ", option.index);
								console.log("The Id is ", id);
								console.log(option);
							}}
							options={subCategoryOptions}
						/>
					</div>
				)}
				<div className={`${styles.description} mt-8`}>
					<label className={styles.label}>
						Product title (Eng) <span className="text-red-600">*</span>{" "}
					</label>
					<TextInput
						className={`${styles.field} border-pink-600`}
						name="name"
						value={productData.name}
						onChange={(e) => handleInputChange("name", e.target.value)}
					/>
					<label className={styles.label}>Product title (Arabic)<span className="text-red-600">*</span></label>
					<TextInput
						className={`${styles.field} border-pink-600`}
						name="nameInArabic"
						value={productData.nameInArabic}
						onChange={(e) => handleInputChange("nameInArabic", e.target.value)}
					/>

					<div className="mt-2">
						<label className="block text-lg font-semibold text-gray-700 mb-2">
							Product Description (Eng) <span className="text-red-600">*</span>
						</label>
						<textarea
							className="mt-1 block w-full p-2 rounded-lg border-2 border-gray-300 bg-white shadow-sm focus:border-pink-500 focus:ring focus:ring-pink-200 focus:ring-opacity-50 transition duration-300 ease-in-out hover:border-pink-300"
							rows="4"
							maxLength={500}
							onChange={(e) => handleInputChange("description", e.target.value)}
							value={productData.description || ""}
							placeholder="Enter the product description here"
						></textarea>
						<p className="text-right text-sm text-gray-600 mt-1">
							{productData.description?.length || 0}/500
						</p>
					</div>

					<div className="mt-2">
						<label className={styles.label}>Product Description (Arabic)<span className="text-red-600">*</span></label>
						<textarea
							className="mt-1 block w-full p-2 rounded-lg border-2 border-gray-300 bg-white shadow-sm focus:border-pink-500 focus:ring focus:ring-pink-200 focus:ring-opacity-50 transition duration-300 ease-in-out hover:border-pink-300"
							rows="4"
							onChange={(e) =>
								handleInputChange("descriptionInArabic", e.target.value)
							}
							maxLength={500}
							value={productData.descriptionInArabic || ""}
							placeholder="Enter the product description here"
						></textarea>
						<p className="text-right text-sm text-gray-600 mt-1">
							{productData.descriptionInArabic?.length || 0}/500
						</p>
					</div>

					<div className="max-w-md  my-10">
						<div className="flex flex-col mb-4">
							<label className="mb-2 font-bold text-lg text-gray-900">
								Select Brand or Manufacturer
								<span className="text-red-600">*</span>
							</label>
							<select
								className="border border-gray-300 rounded py-2 px-3 text-gray-700"
								name="type"
								onChange={(e) => handleTypeChange(e.target.value)}
							>
								<option value="">--Choose an option--</option>
								<option value="brand">Brand</option>
								<option value="manufacturer">Manufacturer</option>
							</select>
						</div>

						{selectedType && (
							<div className="flex flex-col mb-4">
								<label className="mb-2 font-bold text-lg text-gray-900">
									{selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}
									<span className="text-red-600">*</span>
								</label>
								<input
									type="text"
									className="border border-gray-300 bg-gray-100 rounded py-2 px-3 text-gray-700"
									name={selectedType}
									value={productData[selectedType]}
									onChange={(e) =>
										handleInputChange(selectedType, e.target.value)
									}
								/>
							</div>
						)}
					</div>

					<label className={styles.label}>
						Warranty (in months) <span className="text-red-600">*</span>{" "}
					</label>
					<TextInput
						className={styles.field}
						name="warrantyDetails"
						type={"number"}
						value={productData.warrantyDetails}
						onChange={(e) =>
							handleInputChange("warrantyDetails", e.target.value)
						}
					/>
					<div className="flex">
						<label className={styles.label}>
							Warranty Details <span className="text-red-600">*</span>{" "}
						</label>

						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-6 h-6 text-blue-500 hover:text-blue-700 cursor-pointer"
							onClick={() =>
								handleModalOpen(
									<div className="p-4 bg-white shadow-lg rounded-lg">
										<h2 className="text-lg font-semibold">Warranty Details</h2>
										<p className="text-sm text-gray-600 mt-2">
											<span className="font-medium">
												Warranty Extra Details:
											</span>
											<span>
												[Provide additional warranty details here or, if not
												applicable, fill in 'NO']
											</span>
										</p>
									</div>
								)
							}
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
							/>
						</svg>
					</div>
					<TextInput
						className={styles.field}
						name="warrantyExtraDetails"
						type={"text"}
						value={productData.warrantyExtraDetails}
						onChange={(e) =>
							handleInputChange("warrantyExtraDetails", e.target.value)
						}
					/>

					{["isCodEnabled", "isFreeDelivery"].map((name) => (
						<div key={name} className="flex items-center mt-4">
							<label className="flex items-center space-x-3">
								<div className="relative">
									<input
										type="checkbox"
										className="hidden"
										checked={productData[name]}
										onChange={(e) => handleInputChange(name, e.target.checked)}
									/>
									<div className="w-5 h-5 bg-gray-200 border-2 rounded-md border-blue-500 cursor-pointer"></div>
									{productData[name] && (
										<svg
											className="absolute top-0 left-0 w-5 h-5 text-blue-600 fill-current"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
										>
											<path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
										</svg>
									)}
								</div>
								<span className="text-gray-700 select-none">
									{name === "isCodEnabled"
										? "COD Enabled"
										: name
												.replace("is", "")
												.split(/(?=[A-Z])/)
												.join(" ")}
								</span>
							</label>
						</div>
					))}

					<div className="mt-10">
						<label className="block text-sm font-medium text-gray-700 mb-2">
							Legal Disclaimer
						</label>
						<textarea
							className="mt-1 p-2  block w-full rounded-lg border-2 border-gray-300 bg-white shadow-sm focus:border-pink-500 focus:ring focus:ring-pink-200 focus:ring-opacity-50 transition duration-300 ease-in-out"
							rows="4"
							onChange={(e) =>
								handleInputChange("legalDisclaimer", e.target.value)
							}
							value={productData.legalDisclaimer || ""}
							type="string"
							placeholder="Write any legal disclaimer here..."
						></textarea>
					</div>
					<div className="mt-8">
						{/* CPSIA Warning Product Checkbox */}

						{/* Conditional Rendering for CPSIA Warning Description */}

						<div className=" mt-2">
							<label className="block text-sm font-medium text-gray-700 mb-2">
								CPSIA Warning Product
							</label>
							<textarea
								className="mt-1 p-2 block w-full rounded-lg border-2 border-gray-300 bg-white shadow-sm focus:border-pink-500 focus:ring focus:ring-pink-200 focus:ring-opacity-50 transition duration-300 ease-in-out"
								rows="4"
								onChange={(e) =>
									handleInputChange("CPSIA_warning_description", e.target.value)
								}
								value={productData.CPSIA_warning_description || ""}
								type="string"
								placeholder="Enter CPSIA description here..."
							></textarea>
						</div>

						{/* ...rest of the JSX elements (TextInput, Select, etc.) ... */}
						{/* Ensure to include your existing JSX elements like TextInput, Select here as per your original code */}

						<div className="flex justify-end mt-5">
							{isLoading ? (
								<button
									disabled
									className=" bg-pink-600 hover:bg-pink-700 py-3 rounded-md text-white px-6"
								>
									<RotatingLines
										strokeColor="white"
										strokeWidth="5"
										animationDuration="0.75"
										width="25"
										visible={true}
									/>
								</button>
							) : (
								<button
									onClick={handlePublish}
									className=" bg-pink-600 hover:bg-pink-700 py-3 rounded-md text-white px-6"
								>
									{productId ? "Update" : "Next"}
								</button>
							)}
						</div>
					</div>
				</div>
			</Card>
			{/* ------------------------------------------------------------USe for Madal-------------------------------------------------------- */}
			<InfoModal
				isOpen={isModalOpen}
				content={modalContent}
				onClose={() => setIsModalOpen(false)}
			/>
		</>
	);
};

export default NameAndDescription;
