import React from "react";
import { Link } from "react-router-dom";
import Presentation from "../../components/Presentation";
import { useRef } from "react";

const Welcome = function Welcome() {
	const presentationRef = useRef(null);
	localStorage.setItem("darkMode", false);



	return (
		<>
			{/* <style>
				{`
				@import url("https://fonts.googleapis.com/css2?family=Inter&family=Roboto&family=Space+Grotesk&display=swap");		
				@keyframes rotate {
					from {transform: rotate(0deg);}
					to {transform: rotate(360deg);}
				}
				@keyframes antiRotate {
					from {transform: rotate(360deg);}
					to {transform: rotate(0deg);}
				}
				.wave.-w1 {
					animation: rotate 25s infinite ease-in;
				}
				.wave.-w2 {
					animation: antiRotate 30s infinite ease-out;
				}
				.wave.-w3 {
					animation: rotate 40s infinite ease-in-out;
				}
				.wave.-w4 {
					animation: antiRotate 50s infinite ease-in;
				}`}
			</style> */}
			{/* <div  className="box  w-full fixed top-0 left-0 flex items-center justify-center h-screen -z-20">
				<div   className="wave -w2 absolute max-md:w-[600px] max-lg:w-[900px] w-[1250px] max-md:h-[450px] max-lg:h-[700px] h-[1050px] rounded-[40%] bg-pink-400/80 transition-all duration-[3000ms]"></div>
				<div  ref={firstImageRef}  className="wave -w1 absolute max-md:w-[700px] max-lg:w-[950px] w-[1300px] max-md:h-[500px] max-lg:h-[750px] h-[1100px] rounded-[41%] bg-pink-400/70 transition-all duration-[3000ms]"></div>
				<div className="wave -w3 absolute max-md:w-[800px] max-lg:w-[1000px] w-[1400px] max-md:h-[700px] max-lg:h-[850px] h-[1150px] rounded-[42%] bg-pink-400/60 transition-all duration-[3000ms]"></div>
				<div className="wave -w4 absolute max-md:w-[900px] max-lg:w-[1100px] w-[1500px] max-md:h-[750px] max-lg:h-[950px] h-[1200px] rounded-[43%] bg-pink-400/50 transition-all duration-[3000ms]"></div>
			</div> */}
			<section className="flex flex-col w-full h-screen bg-white bg-opacity-0 z-50 font-['space_grotesk']">
			<div className="h-24 bg-red-100 w-full flex items-center z-50">
				<nav className="w-full flex justify-around items-center h-[65px] px-3 lg:px-7 max-lg:text-sm font-['space_grotesk']">
					<Link
						to={"/welcome"}
						className="max-sm:hidden font-['space_grotesk']"
					>
						<img
							className="w-44 hover:cursor-pointer object-contain"
							src="https://res.cloudinary.com/dcpte972l/image/upload/v1701063947/logo_Full_inn5jk.png"
							alt="Core"
						/>
					</Link>
					<div className=" flex justify-center gap-x-3 max-md:gap-x-2 max-sm:grow font-['space_grotesk']">
						<button
onClick={() =>
	presentationRef.current?.scrollIntoView({
		behavior: "smooth",
	})
}
							className="max-lg:px-2 max-lg:py-2 max-lg:w-28 w-40 px-4 py-3 text-center rounded-full hover:bg-pink-600 text-zinc-900 hover:text-white transition-all duration-300 cursor-pointer"
						>
							Why MakeItLess?
						</button>
						<Link
							to={"/termsandconditions"}
							className="max-lg:px-2 max-lg:py-2 max-lg:w-28 w-40 px-4 py-3 text-center rounded-full hover:bg-pink-600 text-zinc-900 hover:text-white transition-all duration-300 cursor-pointer"
						>
							Terms & Condition
						</Link>
						<Link
							to={"/fee-and-commisions"}
							className="max-lg:px-2 max-lg:py-2 max-lg:w-28 w-40 px-4 py-3 text-center rounded-full hover:bg-pink-600 text-zinc-900 hover:text-white transition-all duration-300 cursor-pointer"
						>
							Fee & Commission
						</Link>
					</div>
					<div className="max-md:hidden text-sm text-white">
						<Link to={"/login"}>
							<button className="font-['space_grotesk'] bg-pink-600 py-[7px] w-[90px] rounded-3xl hover:rounded-lg transition-all duration-300 mr-2">
								Sign in
							</button>
						</Link>
						<Link to={"/sign-up"}>
							<button className="font-['space_grotesk'] bg-pink-600 py-[7px] w-[90px] rounded-3xl hover:rounded-lg transition-all duration-300">
								Sign up
							</button>
						</Link>
					</div>
				</nav>
			</div>
				<div className="flex justify-center items-center grow z-50">
					<div className="flex max-md:flex-col gap-x-10 justify-center items-center h-full z-50">
						<img
							className="w-auto max-w-[18rem] mb-5 hover:cursor-pointer object-contain md:hidden"
							src="https://lh3.google.com/u/0/d/1ErK0aqbLADM2pe1RvmO1KLuzNUanvM1M=w1920-h868-iv1"
							alt="Core"
						/>
						<span className="text-5xl">
							<h1 className="text-pink-600 text-center">Explore The Realm of</h1>
							<h1 className="text-black text-center text-[44px]">
								Automated Bargaining!
							</h1>
							<div className="text-base mt-5 flex flex-col gap-y-2 max-md:items-center">
								<div className="flex gap-x-2 max-lg:justify-center">
									<Link to="/sign-up">
										<button className=" bg-pink-600 rounded-lg py-1 w-32 text-lg text-white hover:scale-110 transition-all ease-out font-['space_grotesk']">
											Sign up
										</button>
									</Link>
									<button
										onClick={() =>
											presentationRef.current?.scrollIntoView({
												behavior: "smooth",
											})
										}
										className="bg-green-500 rounded-lg py-1 w-32 text-lg text-white hover:scale-110 transition-all ease-out font-['space_grotesk']"
									>
										Learn more
									</button>
								</div>
								<Link to="/login" className="w-full flex justify-center">
									<button className="md:hidden bg-blue-500 rounded-lg py-1 px-4 max-md:px-[102px] text-lg text-white hover:scale-110 transition-all ease-out font-['space_grotesk']">
										Sign in
									</button>
								</Link>
							</div>
							{/* Explore The Realm Of Automated Bargaining */}
						</span>
						<div className="max-md:hidden">
							<div className="flex flex-col w-80 h-[28rem] rounded-xl bg-gradient-to-br from-white via-yellow-50 to-white">
								<img
									src="/images/content/samsungAd.jpeg"
									alt=""
									srcset=""
									className="relative bottom-0 w-60 ml-28 mt-4 rounded-md shadow-[-25px_15px_2px_white]"
								/>
								<img
									src="/images/content/clock.png"
									alt=""
									className="absolute w-48 mt-60 mr-96 drop-shadow-2xl"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div  ref={presentationRef}>
				<Presentation />
			</div>
		</>
	);
};
export function Header() {
	return (
		<>
			<div className="h-24 w-full flex items-center z-50">
				<nav className="w-full flex justify-around items-center h-[65px] px-3 lg:px-7 max-lg:text-sm font-['space_grotesk']">
					<Link
						to={"/welcome"}
						className="max-sm:hidden font-['space_grotesk']"
					>
						<img
							className="w-44 hover:cursor-pointer object-contain"
							src="https://res.cloudinary.com/dcpte972l/image/upload/v1701063947/logo_Full_inn5jk.png"
							alt="Core"
						/>
					</Link>
					<div className=" flex justify-center gap-x-3 max-md:gap-x-2 max-sm:grow font-['space_grotesk']">
						<Link
							to={"/welcome"}
							className="max-lg:px-2 max-lg:py-2 max-lg:w-28 w-40 px-4 py-3 text-center rounded-full hover:bg-pink-600 text-zinc-900 hover:text-white transition-all duration-300 cursor-pointer"
						>
							Why MakeItLess?
						</Link>
						<Link
							to={"/termsandconditions"}
							className="max-lg:px-2 max-lg:py-2 max-lg:w-28 w-40 px-4 py-3 text-center rounded-full hover:bg-pink-600 text-zinc-900 hover:text-white transition-all duration-300 cursor-pointer"
						>
							Terms & Condition
						</Link>
						<Link
							to={"/fee-and-commisions"}
							className="max-lg:px-2 max-lg:py-2 max-lg:w-28 w-40 px-4 py-3 text-center rounded-full hover:bg-pink-600 text-zinc-900 hover:text-white transition-all duration-300 cursor-pointer"
						>
							Fee & Commission
						</Link>
					</div>
					<div className="max-md:hidden text-sm text-white">
						<Link to={"/login"}>
							<button className="font-['space_grotesk'] bg-pink-600 py-[7px] w-[90px] rounded-3xl hover:rounded-lg transition-all duration-300 mr-2">
								Sign in
							</button>
						</Link>
						<Link to={"/sign-up"}>
							<button className="font-['space_grotesk'] bg-pink-600 py-[7px] w-[90px] rounded-3xl hover:rounded-lg transition-all duration-300">
								Sign up
							</button>
						</Link>
					</div>
				</nav>
			</div>
		</>
	);
}

export default Welcome;
