import React from "react";
import Image from "../components/Image";
import { Link } from "react-router-dom";
import { Header } from "../screens/Welcome";
import Footer from "../components/Footer";

const TermsAndConditions = () => {
	return (
		<>
			<Header
				upperButton={{
					text: "Sign in",
					href: "/login",
				}}
			/>
			<header className=" text-pink-600 text-center">
				<h1 className="text-4xl font-bold">Make It Less</h1>
				<p>Terms & Condition</p>
			</header>
			<div className=" min-h-screen py-4 px-4 sm:px-6 lg:px-8">
				<div className="max-w-3xl bg-slate-200 p-6 rounded-xl mx-auto">
					<div
						className={`mb-10 h-24 w-36 text-center cursor-pointer justify-center flex content-center items-center`}
					>
						<Link to={"/welcome"}>
							<Image
								className={`w-36`}
								src="https://res.cloudinary.com/dcpte972l/image/upload/v1692191670/Holiday_Vacation_Travel_Agent_Promotion_Banner_Landscape_wvx6bq.png"
								alt="Core"
							/>
						</Link>
					</div>
					<h1 className="text-3xl font-semibold text-pink-600 mb-8">
						Online Marketplace Seller Agreement
					</h1>

					<p className="mb-4">
						This Online Marketplace Seller Agreement ("Agreement") is entered
						into between [Make It Less Portal] ("Company") and sellers to
						establish the terms and conditions governing the Seller's
						participation on our online marketplace platform (the "Platform").
					</p>

					<ol className="list-decimal pl-5">
						<li className="mb-4">
							<strong>Platform Usage:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Company grants the Seller a non-exclusive,
									non-transferable right to use the Platform solely for the
									purpose of listing and selling their products.
								</li>
								<li className="mb-2">
									The Seller agrees to comply with all applicable laws,
									regulations, and policies while using the Platform.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Product Listings:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller is responsible for accurately describing and
									listing their products on the Platform, including product
									descriptions, prices, and any other relevant information.
								</li>
								<li className="mb-2">
									The Seller warrants that all product listings comply with
									applicable laws, are accurate, and do not infringe upon any
									third-party rights.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong> Platform Fees:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller acknowledges and agrees that the Platform may
									charge various fees for the use of its services, which may
									include but are not limited to:
									<ul>
										<li>
											<strong>i.</strong> Referral, Commission or transaction
											fees based on a percentage of the sale price.
										</li>
										<li>
											<strong>ii.</strong> Marketing or advertising fees for
											promoting the Seller's products on the Platform.
										</li>
										<li>
											<strong>iii.</strong> Subscription fees for access to
											premium features or enhanced seller tools.
										</li>
										<li>
											<strong>iv.</strong> Shipping fees or fulfillment fees for
											services related to order processing, packaging, and
											delivery.
										</li>
										<li>
											<strong>v.</strong> Any other applicable fees determined
											by the Platform.
										</li>
									</ul>
								</li>
								<li className="mb-2">
									The Seller agrees to pay the applicable fees as outlined by
									the Platform, and the Platform reserves the right to modify or
									update the fee structure upon providing reasonable notice to
									the Seller.
								</li>
								<li className=" mb-2">
									The Platform may deduct the applicable fees directly from the
									Seller's sales proceeds or invoice the Seller for payment, as
									per the Platform's policies and procedures. a. The Company
									will charge a referral fee to the Seller for each successful
									sale made through the Platform. The referral fee will be a
									percentage of the total sale value, as outlined in the fee
									structure provided separately.
								</li>
								<li className=" mb-2">
									The Seller agrees to pay the referral fees within the
									timeframe specified by the Company.
								</li>
							</ul>
						</li>
						<li className="mb-4">
							<strong>Order Fulfillment and Customer Service:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller is responsible for fulfilling orders promptly and
									accurately, including packaging, shipping, returns, and
									handling customer inquiries or complaints related to their
									products.
								</li>
								<li className="mb-2">
									The Company may, at its discretion, provide customer service
									support on behalf of the Seller or redirect customer inquiries
									to the Seller for resolution.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Intellectual Property:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller grants the Company a non-exclusive, worldwide,
									royalty-free license to use, display, and reproduce their
									product listings, trademarks, and other intellectual property
									solely for the purpose of promoting and operating the
									Platform.
								</li>
								<li className="mb-2">
									The Seller represents and warrants that they have the
									necessary rights and permissions to grant such a license and
									that their product listings do not infringe upon any
									third-party intellectual property rights.
								</li>
							</ul>
						</li>
						<li className="mb-4">
							<strong>Indemnification:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller agrees to indemnify, defend, and hold the Company,
									its affiliates, officers, directors, employees, and agents
									harmless from any claims, losses, damages, liabilities, or
									expenses (including attorneys' fees) arising out of or in
									connection with the Seller's use of the Platform, products, or
									any breach of this Agreement.
								</li>
								<li className="mb-2">
									The Seller's indemnification obligations shall survive the
									termination or expiration of this Agreement.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Termination:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									Either party may terminate this Agreement with prior written
									notice.
								</li>
								<li className="mb-2">
									Upon termination, the Seller shall fulfill all pending orders
									and comply with any outstanding obligations.
								</li>
							</ul>
						</li>
						<li className="mb-4">
							<strong>Governing Law and Jurisdiction:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									This Agreement shall be governed by and construed in
									accordance with the laws of [UAE].
								</li>
								<li className="mb-2">
									Any disputes arising out of or in connection with this
									Agreement shall be subject to the exclusive jurisdiction of
									the courts of [UAE].
								</li>
							</ul>
						</li>
						<li className="mb-4">
							<strong>Seller Account and Information:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller is responsible for maintaining the security and
									confidentiality of their account login credentials.
								</li>
								<li className="mb-2">
									The Seller agrees to provide accurate and up-to-date
									information, including contact details, banking information
									for payment processing, and tax identification numbers.
								</li>
							</ul>
						</li>
						<li className="mb-4">
							<strong>Product Quality and Compliance:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller warrants that their products meet all applicable
									quality standards, safety regulations, and certifications
									required by law.
								</li>
								<li className="mb-2">
									The Seller agrees to promptly address any product quality
									issues and recalls in accordance with applicable laws and
									regulations.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Data Protection and Privacy:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Company shall handle and process any personal data
									collected from the Seller in accordance with applicable data
									protection laws and its privacy policy.
								</li>
								<li className="mb-2">
									The Seller acknowledges and agrees that the Company may use
									certain data and analytics to improve the Platform's
									performance, user experience, and marketing efforts.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Product Liability and Insurance:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller acknowledges that they are solely responsible for
									any product liability claims arising from the sale of their
									products on the Platform.
								</li>
								<li className="mb-2">
									The Seller agrees to maintain appropriate insurance coverage
									to protect against any potential product liability claims and
									provide proof of insurance upon request.
								</li>
							</ul>
						</li>
						{/* ... other points if any ... */}
						<li className="mb-4">
							<strong>Reviews and Ratings:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Platform may allow customers to leave reviews and ratings
									for products sold by the Seller. The Seller acknowledges that
									these reviews and ratings may be publicly displayed on the
									Platform.
								</li>
								<li className="mb-2">
									The Seller agrees not to manipulate or artificially inflate
									their reviews and ratings, and to address any legitimate
									customer concerns or complaints promptly and professionally.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Confidentiality:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									Both parties agree to treat any non-public information
									exchanged during the course of their business relationship as
									confidential.
								</li>
								<li className="mb-2">
									The Seller agrees not to disclose any confidential information
									about the Company's operations, technology, or business
									practices without prior written consent.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Amendments:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									This Agreement may be amended or modified only in writing and
									signed by both parties.
								</li>
								<li className="mb-2">
									Any modifications or amendments shall be deemed effective upon
									the agreed-upon date specified in the written amendment.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Severability:</strong>
							If any provision of this Agreement is held to be invalid, illegal,
							or unenforceable, the validity, legality, and enforceability of
							the remaining provisions shall not in any way be affected or
							impaired.
						</li>

						<li className="mb-4">
							<strong>Entire Agreement:</strong>
							This Agreement constitutes the entire understanding between the
							parties regarding the Seller's use of the Platform and supersedes
							any prior agreements or understandings, whether written or oral.
						</li>

						<li className="mb-4">
							<strong>Product Listing Approval:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Company reserves the right to review and approve all
									product listings before they are published on the Platform.
								</li>
								<li className="mb-2">
									The Company may request modifications or reject product
									listings that do not meet the Platform's guidelines or quality
									standards.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Marketing and Promotions:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Company may promote the Seller's products through
									marketing campaigns, advertising, or featured placements on
									the Platform.
								</li>
								<li className="mb-2">
									The Seller agrees to cooperate with the Company in providing
									necessary product information, images, and promotional
									materials for marketing purposes.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Payment Processing:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Company shall collect payments from customers on behalf of
									the Seller and deduct the referral fees and any other
									applicable charges before remitting the remaining funds to the
									Seller.
								</li>
								<li className="mb-2">
									The Company shall provide regular statements or reports
									detailing the Seller's sales, fees, and payments.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Dispute Resolution:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									Any disputes arising between the parties in connection with
									this Agreement shall be resolved amicably through good-faith
									negotiations.
								</li>
								<li className="mb-2">
									If the parties are unable to reach a resolution, either party
									may initiate mediation or arbitration in accordance with the
									laws of [UAE].
								</li>
							</ul>
						</li>
						<li className="mb-4">
							<strong>Limitation of Liability:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Company shall not be liable for any indirect, incidental,
									consequential, or punitive damages arising out of or in
									connection with the Seller's use of the Platform, including
									but not limited to lost profits, business interruption, or
									data loss.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Force Majeure:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									Neither party shall be held liable for any delay or failure in
									performance of their obligations under this Agreement caused
									by events beyond their reasonable control, such as natural
									disasters, acts of government, or labor disputes.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Survival:</strong>
							The provisions of this Agreement that, by their nature, extend
							beyond the termination or expiration of this Agreement shall
							survive and remain in effect.
						</li>

						<li className="mb-4">
							<strong>Waiver:</strong>
							The failure of either party to enforce any provision of this
							Agreement shall not be deemed as a waiver of that provision or the
							right to enforce it in the future.
						</li>

						<li className="mb-4">
							<strong>Non-Exclusive Relationship:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The relationship between the Company and the Seller is
									non-exclusive, and the Seller is free to engage in similar
									business activities with other platforms or marketplaces.
								</li>
								<li className="mb-2">
									The Seller acknowledges that the Company may onboard and
									promote other sellers offering similar or competing products
									on the Platform.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Data Protection and Privacy:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Company shall handle and process any personal data
									collected from the Seller in accordance with applicable data
									protection laws and its privacy policy.
								</li>
								<li className="mb-2">
									The Seller acknowledges and agrees that the Company may use
									certain data and analytics to improve the Platform's
									performance, user experience, and marketing efforts.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Intellectual Property Infringement:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller shall promptly notify the Company of any suspected
									intellectual property infringement related to their products
									or listings on the Platform.
								</li>
								<li className="mb-2">
									The Company may take appropriate actions, such as removing
									infringing content or terminating the Seller's account, to
									address intellectual property infringement concerns.
								</li>
								<li className="mb-2">
									Seller agrees on any decision taken by technical inspection
									department of our company related to the quality and
									authenticity of their product.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Non-Solicitation:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									During the term of this Agreement and for a period of six
									months after its termination, the Seller shall not directly or
									indirectly solicit or hire any employees, contractors, or
									agents of the Company.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Independent Contractor Relationship:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller is an independent contractor and not an employee,
									partner, or agent of the Company.
								</li>
								<li className="mb-2">
									The Seller shall be solely responsible for any taxes,
									licenses, permits, or obligations arising from their
									operations as an independent contractor.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Assignment:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller may not assign or transfer this Agreement, in whole
									or in part, without the prior written consent of the Company.
								</li>
								<li className="mb-2">
									The Company may assign or transfer this Agreement to any
									affiliated entity or in connection with a merger, acquisition,
									or sale of assets.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Notices:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									All notices, requests, or communications required or permitted
									under this Agreement shall be in writing and delivered via
									email, registered mail, or recognized courier service to the
									addresses provided by the parties.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Entire Agreement:</strong>
							This Agreement, together with any attachments, exhibits, or
							addendums, constitutes the entire agreement between the parties
							and supersedes any prior agreements or understandings, whether
							written or oral.
						</li>

						<li className="mb-4">
							<strong>Customer Data:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Company may collect and process customer data in
									connection with the Seller's transactions on the Platform.
								</li>
								<li className="mb-2">
									The Seller acknowledges that they do not have direct access to
									customer data and shall not use or disclose such data for any
									purposes other than fulfilling orders and providing customer
									support.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Platform Modifications:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Company reserves the right to modify, update, or enhance
									the Platform, including its features, functionalities, and
									user interface, at its sole discretion.
								</li>
								<li className="mb-2">
									The Seller acknowledges that such modifications may require
									adjustments to their product listings or operational
									processes, and they shall cooperate with the Company in
									implementing any necessary changes.
								</li>
							</ul>
						</li>
						<li className="mb-4">
							<strong>Product Removal:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Company may, at its discretion, remove or suspend any
									product listings on the Platform that violate this Agreement,
									infringe upon intellectual property rights, or are deemed
									inappropriate or unsafe.
								</li>
								<li className="mb-2">
									The Seller shall promptly address any concerns raised by the
									Company regarding their product listings and comply with any
									requests for modifications or removal.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Feedback and Suggestions:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Company welcomes feedback and suggestions from the Seller
									regarding the Platform's features, functionalities, or
									improvements.
								</li>
								<li className="mb-2">
									The Seller acknowledges that any feedback or suggestions
									provided to the Company may be used by the Company without any
									obligation or compensation.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Publicity:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller grants the Company the right to use their business
									name, logo, and product images for promotional purposes,
									including but not limited to marketing materials, press
									releases, and social media posts.
								</li>
								<li className="mb-2">
									The Seller may request the removal or modification of their
									branding materials from the Company's promotional materials
									upon written notice.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Anti-Bribery and Anti-Corruption:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller represents and warrants that they have not and will
									not engage in any form of bribery, corruption, or illegal
									activities in connection with their use of the Platform.
								</li>
								<li className="mb-2">
									The Seller shall comply with all applicable anti-bribery and
									anti-corruption laws and regulations.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Insurance:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller shall maintain appropriate insurance coverage,
									including but not limited to general liability insurance,
									product liability insurance, and any other insurance required
									by law or reasonably requested by the Company.
								</li>
								<li className="mb-2">
									The Seller shall provide proof of insurance coverage to the
									Company upon request and notify the Company of any changes or
									cancellations to their insurance policies.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Successors and Assigns:</strong>
							This Agreement shall be binding upon and inure to the benefit of
							the parties and their respective successors, assigns, and legal
							representatives.
						</li>

						<li className="mb-4">
							<strong>Counterparts:</strong>
							This Agreement may be executed in counterparts, each of which
							shall be deemed an original, but all of which together shall
							constitute one and the same instrument.
						</li>

						<li className="mb-4">
							<strong>Termination:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									Either party may terminate this Agreement for convenience by
									providing written notice to the other party [1month] in
									advance.
								</li>
								<li className="mb-2">
									The Company may terminate this Agreement immediately if the
									Seller breaches any material provision of this Agreement or
									engages in fraudulent or illegal activities.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Return and Refund Policy:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller shall comply with the Company's return and refund
									policy, which may include accepting returns, issuing refunds,
									or providing customer support for returns and refunds.
								</li>
								<li className="mb-2">
									The Seller shall promptly process any return requests or
									refund claims in accordance with the Company's policies.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Tax Obligations:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller is solely responsible for complying with all
									applicable tax laws and regulations, including the collection,
									reporting, and remittance of any applicable sales taxes or
									value-added taxes.
								</li>
								<li className="mb-2">
									The Seller agrees to indemnify and hold the Company harmless
									from any claims, penalties, or liabilities arising from the
									Seller's failure to comply with tax obligations.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Governing Law:</strong>
							This Agreement shall be governed by and construed in accordance
							with the laws of [UAE]. Any disputes arising under this Agreement
							shall be subject to the exclusive jurisdiction of the courts
							located in [UAE].
						</li>

						<li className="mb-4">
							<strong>Relationship with Customers:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller agrees to provide accurate and truthful information
									about their products, pricing, and shipping details to
									customers on the Platform.
								</li>
								<li className="mb-2">
									The Seller shall promptly respond to customer inquiries,
									concerns, or complaints in a professional and courteous
									manner.
								</li>
							</ul>
						</li>
						<li className="mb-4">
							<strong>Export Control:</strong>
							The Seller shall comply with all applicable export control laws
							and regulations, including obtaining any necessary export licenses
							or permits for the sale and shipment of products to customers
							located outside the Seller's jurisdiction.
						</li>

						<li className="mb-4">
							<strong>No Agency:</strong>
							This Agreement does not create any agency, partnership, joint
							venture, or employment relationship between the parties. The
							Seller shall not represent themselves as an agent or
							representative of the Company.
						</li>

						<li className="mb-4">
							<strong>Headings:</strong>
							The headings in this Agreement are for convenience only and shall
							not affect the interpretation or construction of this Agreement.
						</li>

						<li className="mb-4">
							<strong>Language:</strong>
							This Agreement is drafted in the [English] language. In the event
							of any inconsistencies or discrepancies between translations, the
							[English] version shall prevail.
						</li>

						<li className="mb-4">
							<strong>Electronic Communication:</strong>
							The parties agree that electronic communication, including email,
							electronic signatures, or electronic records, shall be considered
							valid and enforceable for all purposes under this Agreement.
						</li>

						<li className="mb-4">
							<strong>No Third-Party Beneficiaries:</strong>
							This Agreement is solely for the benefit of the parties and does
							not create any rights or benefits for any third parties.
						</li>

						<li className="mb-4">
							<strong>Survival of Obligations:</strong>
							Any provisions of this Agreement that expressly or by their nature
							survive termination or expiration of this Agreement shall continue
							in full force and effect.
						</li>

						<li className="mb-4">
							<strong>Execution:</strong>
							This Agreement may be executed in counterparts, each of which
							shall be deemed an original, but all of which together shall
							constitute one and the same instrument.
						</li>

						<li className="mb-4">
							<strong>No Waiver:</strong>
							The failure of either party to enforce any right or provision of
							this Agreement shall not constitute a waiver of such right or
							provision unless acknowledged and agreed to in writing.
						</li>
						<br />

						<h2 className="text-3xl text-center text-pink-600 font-bold mb-6">
							International Sellers Terms:
						</h2>

						<li className="mb-4">
							<strong>International Shipping:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller acknowledges and agrees to comply with all
									applicable laws, regulations, and customs requirements for
									international shipping of their products.
								</li>
								<li className="mb-2">
									The Seller shall be responsible for accurately declaring the
									value of products, providing necessary customs documentation,
									and paying any applicable import duties, taxes, or fees.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Currency Conversion:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller acknowledges that transactions on the Platform may
									involve currency conversion for international customers.
								</li>
								<li className="mb-2">
									The Seller agrees that the Company may use a third-party
									payment processor or financial institution to facilitate
									currency conversion and may deduct any applicable currency
									conversion fees or charges.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Compliance with Export Controls:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller represents and warrants that their products comply
									with all applicable export control laws and regulations of
									their home country and the destination country.
								</li>
								<li className="mb-2">
									The Seller shall not export or ship any products that are
									prohibited or restricted by the export control laws or
									regulations of the Seller's home country or the destination
									country.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>International Returns and Refunds:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller shall establish clear policies and procedures for
									international returns and refunds, taking into consideration
									any additional costs, logistics, or customs requirements.
								</li>
								<li className="mb-2">
									The Seller shall promptly process international return
									requests and refunds in accordance with their established
									policies and applicable laws.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Language and Localization:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller acknowledges that customers from different
									countries may have varying language preferences and cultural
									expectations.
								</li>
								<li className="mb-2">
									The Seller agrees to make reasonable efforts to provide
									accurate product descriptions, customer support, and
									communication in the languages or locales relevant to the
									target international markets.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Compliance with International Laws:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller shall comply with all applicable laws, regulations,
									and standards of the countries where their products are sold
									or shipped.
								</li>
								<li className="mb-2">
									The Seller shall be responsible for understanding and
									complying with international consumer protection laws, data
									protection laws, labeling requirements, and any other
									applicable regulations.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>International Disputes:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									In the event of a dispute arising between the Seller and an
									international customer, the parties shall attempt to resolve
									the dispute amicably through good-faith negotiations.
								</li>
								<li className="mb-2">
									If a resolution cannot be reached, the parties agree to submit
									the dispute to binding arbitration in accordance with [UAE]
									and under the laws of [UAE].
								</li>
							</ul>
						</li>
						<li className="mb-4">
							<strong>International Tax Obligations:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller acknowledges that selling internationally may
									involve additional tax obligations, such as value-added tax
									(VAT), goods and services tax (GST), or customs duties.
								</li>
								<li className="mb-2">
									The Seller shall be responsible for understanding and
									complying with the international tax requirements applicable
									to their sales and shall accurately report and remit any
									applicable taxes or duties.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Import/Export Licenses:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller acknowledges that certain products may require
									import or export licenses or permits in accordance with the
									laws and regulations of the Seller's home country and the
									destination country.
								</li>
								<li className="mb-2">
									The Seller shall obtain and maintain any necessary import or
									export licenses or permits and shall provide the Company with
									copies upon request.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>International Intellectual Property Rights:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller represents and warrants that their products,
									including any trademarks, copyrights, or patents associated
									with the products, do not infringe upon the intellectual
									property rights of any third party.
								</li>
								<li className="mb-2">
									The Seller shall be solely responsible for any claims or
									disputes regarding intellectual property infringement arising
									from the sale or distribution of their products
									internationally.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Compliance with Product Safety Regulations:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller shall ensure that their products comply with all
									applicable product safety regulations and standards in the
									Seller's home country and the destination countries.
								</li>
								<li className="mb-2">
									The Seller shall provide accurate and complete product safety
									information, warnings, and instructions to international
									customers.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Localization of Product Listings:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller acknowledges that optimizing product listings for
									international markets may require localization, including
									translations, relevant local product descriptions, and
									appropriate unit conversions.
								</li>
								<li className="mb-2">
									The Seller shall make reasonable efforts to adapt their
									product listings to the cultural, linguistic, and regulatory
									requirements of the target international markets.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>Cross-Border Data Transfers:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller understands and agrees that personal data of
									international customers may be transferred to countries
									outside their home country for processing and fulfillment
									purposes.
								</li>
								<li className="mb-2">
									The Seller shall comply with applicable data protection laws
									and regulations and shall provide adequate privacy notices and
									obtain necessary consents from international customers.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>International Marketing and Advertising:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller may engage in marketing and advertising activities
									to target international customers.
								</li>
								<li className="mb-2">
									The Seller shall comply with the advertising and marketing
									laws and regulations of the countries where their marketing
									efforts are directed, including any requirements for
									disclosures, disclaimers, or specific marketing restrictions.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>International Customer Support:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller shall provide appropriate customer support to
									international customers, including addressing inquiries,
									resolving issues, and providing assistance in a timely and
									culturally sensitive manner.
								</li>
								<li className="mb-2">
									The Seller shall consider language capabilities, time zone
									differences, and cultural nuances when providing customer
									support to international customers.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>International Product Liability:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller shall ensure that their products meet the
									applicable product liability standards and requirements of the
									Seller's home country and the destination countries.
								</li>
								<li className="mb-2">
									The Seller shall be solely responsible for any product-related
									claims or liabilities arising from the sale or distribution of
									their products internationally.
								</li>
							</ul>
						</li>

						<li className="mb-4">
							<strong>International Warranty and Returns:</strong>
							<ul className="list-disc pl-5 mt-2">
								<li className="mb-2">
									The Seller shall establish clear warranty terms and conditions
									for international customers, including any limitations or
									restrictions applicable to international warranty coverage.
								</li>
								<li className="mb-2">
									The Seller shall handle warranty claims and returns from
									international customers in accordance with their established
									warranty policies and applicable laws.
								</li>
							</ul>
						</li>

						<p className="mt-6 mb-4">
							Please review this Agreement carefully. By using the Make It Less
							Platform, the Seller acknowledges and agrees to be bound by its
							terms and conditions. This Agreement constitutes the entire
							understanding between the parties and supersedes any prior
							agreements or understandings, whether written or oral.
						</p>

						<p className="mt-4 text-lg font-bold">
							If you have any questions or concerns regarding this Agreement,
							please contact us at{" "}
							<a
								href="mailto:sellers@makeitless.com"
								className="text-pink-600 underline"
							>
								sellers@makeitless.com
							</a>
							.
						</p>
					</ol>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default TermsAndConditions;
