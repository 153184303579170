import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import toast from "react-hot-toast";
import { firebaseApp } from "../../components/config/firebase";
import { setConfirmationResult } from "../../store/globalSlice";

export const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();

export const _initSendOTP = async (phoneNumber, globalDispatch) => {
	console.log("error.... inside..");
	try {
		let recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
			size: "invisible",
			callback: (response) => {
				if (response) {
					_sendOTP(recaptchaVerifier, phoneNumber, globalDispatch);
					return;
				}
			},
			"expired-callback": () => {
				toast.error("Re-captcha Verification Expired, Please solve again");
			},
		});

		recaptchaVerifier
			.verify()
			.then((widgetId) => {
				console.log({ widgetId });
			})
			.catch((err) => {
				console.log("error at _initiate...", phoneNumber);
			});
	} catch (error) {
		console.log({ error });
		console.log("cpatch erro...", error);
	}
};

const _sendOTP = async (recaptchaVerifier, phoneNumber, globalDispatch) => {
	console.log({ phoneNumber });
	console.log("before sending otp...", phoneNumber);
	signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
		.then((confirmationResult) => {
			console.log({ confirmationResult });
			toast.success("SMS send, please enter the OTP");
			globalDispatch(setConfirmationResult(confirmationResult));
		})
		.catch((error) => {
			console.log({ error });
			toast.error("Something went wrong, SMS not sent");
		});
};

export const _verifyOTP = async (
	code,
	confirmationResult,
	globalDispatch,
	setotpStatus
) => {
	try {
		confirmationResult
			.confirm(code)
			.then((result) => {
				toast.success("Welcome!");
				localStorage.setItem("otpStatus", JSON.stringify(true));
				setotpStatus(true);
				console.log("Verification success..", result);
				// Perform any additional actions after successful verification
			})
			.catch((error) => {
				console.log({ error });
				toast.error("Something went wrong, please enter correct OTP");
			});
	} catch (error) {
		console.log({ error });
	}
};
