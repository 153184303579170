import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axios";
import { useParams, useNavigate } from "react-router-dom";

const VariantBids = () => {
	const { variantId } = useParams();
	const [bids, setBids] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		axiosInstance
			.get(`/v1/seller/all-bids?variant=${variantId}`)
			.then((response) => {
				setBids(response.data.payload.docs);
			})
			.catch((error) => {
				console.error("Error fetching bids:", error);
			});
	}, [variantId]);

	return (
		<div className="bg-white p-6 mt-10 rounded-xl shadow-md relative">
			<button onClick={() => navigate(-1)} className="absolute top-6 right-6">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth={1.5}
					stroke="currentColor"
					className="w-6 h-6"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
					/>
				</svg>
			</button>
			<h2 className="text-2xl font-semibold mb-6 text-center">
				Bids for Variant {variantId}
			</h2>
			<table className="min-w-full bg-white border rounded-md">
				<thead className="bg-gray-200">
					<tr>
						<th className="py-2 px-3 border-b">Price</th>
						<th className="py-2 px-3 border-b">Status</th>
						<th className="py-2 px-3 border-b">Created At</th>
					</tr>
				</thead>
				<tbody>
					{bids.map((bid, index) => (
						<tr
							key={index}
							className={`${index % 2 === 0 ? "bg-gray-100" : ""}`}
						>
							<td className="py-2 px-3 border-b text-center">
								<span
									className={`text-${
										bid.status === "PROCCEDED_TO_CHECKOUT" ? "black" : "black"
									}-500`}
								>
									DiscountPrice-{bid.discountPrice}
									<br />
									<span className="text-yellow-500">
										GeneratedPrice-{bid.generatedPrice}
									</span>
									<br />
									<span
										className={`text-${
											bid.status === "PROCCEDED_TO_CHECKOUT" ? "green" : "red"
										}-500`}
									>
										{bid.status === "PROCCEDED_TO_CHECKOUT"
											? `BidPrice-${bid.bidPrice} ⬆`
											: `BidPrice-${bid.bidPrice} ⬇`}
									</span>
									<br />
									SellingPrice-{bid.sellingPrice}
								</span>
							</td>
							<td
								className={`py-2  px-3 border-b text-center ${
									bid.status === "PROCCEDED_TO_CHECKOUT"
										? "text-green-500"
										: "text-red-500"
								}`}
							>
								{bid.status}
							</td>
							<td className="py-2 px-3 border-b text-center">
								{new Date(bid.createdAt).toLocaleDateString()}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default VariantBids;
