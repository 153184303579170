import React from "react";
import Loader from "./Loader";

const LoadingComponent = () => {
	return (
		<div className="min-h-screen flex justify-center items-center">
			<Loader />
		</div>
	);
};

export default LoadingComponent;
