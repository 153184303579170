/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef, useState } from "react";
import cn from "classnames";
import Card from "../../../components/Card";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import axiosInstance from "../../../utils/axios";
import { IMAGE_UPLOAD_API } from "../../../utils/api";
import {
	IMAGE,
	PDF,
	SOMETHING_WENT_WRONG,
	showToast,
} from "../../../utils/constants";
import { logger } from "../../../utils/logger";
import Loader from "../../../components/Loader";
import { Upload } from "lucide-react";
import { toast } from "react-toastify";

const GenericImageUpload = ({
	className,
	maxImages = 1,
	images = [],
	onImageUpload,
	onImageRemove,
	setSortedImages,
	assetType = IMAGE,
	type,
}) => {
	const [isImageUploading, setIsImageUploading] = useState(false);
	const actualUpload = async (formData) => {
		try {
			setIsImageUploading(true);
			const { data } = await axiosInstance.post(IMAGE_UPLOAD_API, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			if (data?.error) return showToast(data?.message || SOMETHING_WENT_WRONG);
			logger.log({
				data: data,
			});
			onImageUpload(data?.payload?.full_url);
			toast.success(`${type || "Image"} Uploaded successfully`);
		} catch (error) {
			console.log({ error });
			showToast(error?.response?.message || SOMETHING_WENT_WRONG);
		} finally {
			setIsImageUploading(false);
		}
	};
	const [filename, setFilename] = useState(false);
	const handleImageUpload = (e) => {
		const file = e.target.files[0];
		// console.log("Filename ", file.name);
		if (!file) return;
		if (type == "PDF") {
			const str = file.name.toUpperCase();
			if (!str.includes(".PDF")) {
				console.log(str);
				toast.error("Select a pdf file");
				setFilename(false);
				return;
			}
		}
		setFilename(file.name);
		const formData = new FormData();
		formData.append("image", file);
		actualUpload(formData);
	};

	const SortableImage = SortableElement(({ image, index }) => (
		<div key={image} className="flex flex-col items-center gap-y-2">
			{type != "PDF" ? (
				<div className="text-center">
					<img
						className={`w-64 h-64 object-contain ${
							index !== 0 && "rounded-xl"
						}`} // Increase size for the first image
						src={image}
						alt={`Image ${index + 1}`}
					/>
					{filename}
				</div>
			) : (
				<div className="min-w-min h-32 px-4 flex justify-center items-center bg-green-300/10">
					<svg
						viewBox="0 0 512 512"
						fill="#D81B60"
						stroke="#D81B60"
						className="w-6 h-6"
					>
						<g id="SVGRepo_iconCarrier">
							<title>upload</title>
							<g
								id="Page-1"
								stroke="none"
								stroke-width="1"
								fill="none"
								fill-rule="evenodd"
							>
								<g
									id="icon"
									fill="#D81B60"
									transform="translate(85.333333, 64.000000)"
								>
									{" "}
									<path
										d="M170.666667,0 L28.992,118.037333 L56.32,150.826667 L149.333333,73.3226667 L149.333333,283.776 L192,283.776 L192,73.3226667 L284.992,150.826667 L312.32,118.037333 L170.666667,0 Z M1.42108547e-14,384 L341.333333,384 L341.333333,341.333333 L1.42108547e-14,341.333333 L1.42108547e-14,384 Z"
										id="Mask"
									>
										{" "}
									</path>
								</g>
							</g>
						</g>
					</svg>
					{filename}
				</div>
			)}
			<button
				className="text-red-500 hover:text-red-700 bg-green-500/10 p-1 rounded"
				type="button"
				onClick={() => onImageRemove(image)}
			>
				Remove
			</button>
		</div>
	));
	const SortableImageList = SortableContainer(({ images }) => (
		<div className="flex flex-wrap space-x-4 overflow-auto md:overflow-auto lg:overflow-visible">
			{images.map((image, index) => (
				<SortableImage key={image} index={index} image={image} />
			))}
		</div>
	));

	const onSortEnd = ({ oldIndex, newIndex }) => {
		const updatedImages = [...images];
		const movedImage = updatedImages[oldIndex];
		updatedImages.splice(oldIndex, 1);
		updatedImages.splice(newIndex, 0, movedImage);
		// setSortedImages(updatedImages);
	};
	const fileInputRef = useRef();

	return (
		<div className={"mt-4"}>
			<SortableImageList images={images} onSortEnd={onSortEnd} axis="xy" />
			{isImageUploading && (
				<div className="flex justify-center">
					<Loader />
				</div>
			)}
			{images.length < maxImages && (
				<div className="mb-4">
					<button
						type="button"
						onClick={() => fileInputRef.current.click()}
						className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-pink-700 rounded-lg hover:bg-pink-600 focus:outline-none focus:ring focus:ring-pink-300 focus:ring-opacity-80"
					>
						<Upload color="white" size={22} />
						<span class="mx-10">Upload {type}</span>
					</button>
					<input
						hidden
						ref={fileInputRef}
						className="border p-2 rounded"
						type="file"
						accept={assetType === PDF ? PDF : "image/*"}
						multiple={false}
						onChange={handleImageUpload}
					/>
				</div>
			)}

			<div className="mt-4">
				Remaining {type} to upload: {maxImages - images.length}
			</div>
		</div>
	);
};

export default GenericImageUpload;
