import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logger } from "../../utils/logger";

const Page = ({ wide, children, title }) => {
	const [visible, setVisible] = useState(false);
	const {
		isAuthenticated,
		isLoading,
		isProfile,
		isLoadingForProfileCheck,
		profile,
		profileData,
	} = useSelector((state) => state.global);
	const nav = useNavigate();
	useEffect(() => {
		if (isLoading) return;
		if (!isAuthenticated) return nav("/welcome", { replace: true });
	}, [isAuthenticated, isLoading, isProfile]);
	useEffect(() => {
		// logger.log({ profileData });
		if (isLoadingForProfileCheck) return;
		if (!profileData?.isProfile)
			return nav("/profile/create", { replace: true });
		if (profileData?.error) return nav("/approval", { replace: true });
	}, [isLoadingForProfileCheck, profileData]);

	return (
		<>
			<div className={styles.page}>
				<Sidebar
					className={cn(styles.sidebar, {
						[styles.visible]: visible,
					})}
					onClose={() => setVisible(false)}
				/>
				<Header onOpen={() => setVisible(true)} />
				<div className={styles.inner}>
					<div
						className={cn(styles.container, {
							[styles.wide]: wide,
						})}
					>
						{title && <div className={cn("h3", styles.title)}>{title}</div>}
						{children}
					</div>
				</div>
			</div>
		</>
	);
};

export default Page;
