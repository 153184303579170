import React, { useState, useEffect } from "react";
import styles from "./Row.module.sass";
import axiosInstance from "../../../../utils/axios";
import { Link } from "react-router-dom";
import cn from "classnames";
import { GET_ALL_PRODUCTS, DELETE_PRODUCT } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import VariantAdd from "../../../../pages/AddVariant";
import Loader from "../../../../components/Loader";
import DeleteModal from "../../../../components/DeleteModal";

const Row = () => {
	const navigate = useNavigate();
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isVariantModalOpen, setIsVariantModalOpen] = useState(false);
	const [currentProductId, setCurrentProductId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedProductId, setSelectedProductId] = useState(null);

	const toggleVariantModal = (productId) => {
		navigate(`/variant/add/${productId}`);
	};

  const openModal = (productId) => {
		setSelectedProductId(productId);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedProductId(null);
	};

	const confirmDelete = () => {
		if (selectedProductId) {
			handleDelete(selectedProductId);
			closeModal();
		}
	};

	useEffect(() => {
		axiosInstance
			.get(GET_ALL_PRODUCTS)
			.then((response) => {
				if (
					response.data.error === false &&
					Array.isArray(response.data.payload.docs)
				) {
					setProducts(response.data.payload.docs);
				} else {
					throw new Error(
						"Unexpected data structure from the backend or an error occurred."
					);
				}
				setLoading(false);
			})
			.catch((err) => {
				console.error("Error fetching products:", err);
				setError(err);
				setLoading(false);
			});
	}, []);

	// For delete product

	const handleDelete = (productId) => {
		// Send a DELETE request to delete the product with the specified ID
		axiosInstance
			.delete(`${DELETE_PRODUCT}/${productId}`)
			.then((response) => {
				// Handle successful deletion, maybe refresh products or show a notification
				console.log("Product deleted successfully!");

				// Update the products state by filtering out the deleted product
				setProducts((prevProducts) =>
					prevProducts.filter((product) => product._id !== productId)
				);
			})
			.catch((err) => {
				console.error("Error deleting product:", err);
			});
	};

	if (loading) {
		return <Loader />;
	}

	if (error) {
		return <div>Error fetching products. Please try again later.</div>;
	}
	if (products.length === 0) {
		return (
			<div className="flex text-center h-screen">
				<p className="text-lg text-gray-700">
					You have not added any products yet.
				</p>
			</div>
		);
	}

	return (
		<>
<div className="overflow-x-auto">
        <table className="min-w-full overflow-hidden shadow-lg rounded-lg">
          <thead className="">
            <tr className="">
             
              <th
                scope="col"
                className="px-2 md:px-6 py-2 md:py-4 text-xs md:text-sm font-semibold md:font-bold tracking-wider text-center text-white uppercase bg-pink-600"
              >
                Main Image
              </th>

              <th
                scope="col"
                className="px-2 md:px-6 py-2 md:py-4 text-xs md:text-sm font-semibold md:font-bold tracking-wider text-center text-white uppercase bg-pink-600"
              >
                Product Name
              </th>

              <th
                scope="col"
                className="px-2 md:px-6 py-2 md:py-4 text-xs md:text-sm font-semibold md:font-bold tracking-wider text-center text-white uppercase bg-pink-600"
              >
                Variants
              </th>
              <th
                scope="col"
                className="px-2 md:px-6 py-2 md:py-4 text-xs md:text-sm font-semibold md:font-bold tracking-wider text-center text-white uppercase bg-pink-600"
              >
                Edit
              </th>
              <th
                scope="col"
                className="px-2 md:px-6 py-2 md:py-4 text-xs md:text-sm font-semibold md:font-bold tracking-wider text-center text-white uppercase bg-pink-600"
              >
                Delete
              </th>
              <th
                scope="col"
                className="px-2 md:px-6 py-2 md:py-4 text-xs md:text-sm font-semibold md:font-bold tracking-wider text-center text-white uppercase bg-pink-600"
              >
                Add Variant
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700">
            {Array.isArray(products) &&
              products.map((data, index) => (
                <tr key={index}>
                 
                  <td className="px-2 md:px-6 py-4 text-xs md:text-sm justify-center flex text-gray-900 whitespace-nowrap dark:text-gray-900">
                    {data.variants &&
                    data.variants.length > 0 &&
                    data.variants[0].thumbnailImage ? (
                      <img
                        src={data.variants[0].thumbnailImage}
                        alt="Thumbnail"
                        className="rounded-md w-10 h-10 object-cover"
                      />
                    ) : (
                      "No Thumbnail"
                    )}
                  </td>

                  <td
                    className="cursor-pointer text-center hover:text-pink-600 text-xs md:text-sm  text-gray-900 truncate-35-words dark:text-gray-900"
                    onClick={() =>
                      (window.location.href = `/productdetails/${data._id}`)
                    }
                  ><div className="line-clamp-1">
                    {data.name}
					</div>
                  </td>

                  {/* Number of Variants */}
                  <td className="px-2 md:px-6 py-4 text-center text-xs md:text-sm text-gray-900 whitespace-nowrap dark:text-gray-900">
                    {data.variants ? data.variants.length : 0}
                  </td>
                  <td className="items-center flex justify-center text-center">
                    <Link to={`/products/add/edit/${data._id}`}>
                      <button className="bg-pink-600 p-2 px-4 rounded hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </button>
                    </Link>
                  </td>
                  <td className="items-center justify-center text-center">
                    <button
                      className="bg-pink-600 p-2 px-4 rounded hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                      onClick={() => openModal(data._id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </td>
                  <td className="items-center justify-center text-center">
                    <button
                      className="bg-pink-600 p-2 px-4 rounded hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                      onClick={() => toggleVariantModal(data._id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </button>
                    {isVariantModalOpen && (
                      <VariantAdd productId={currentProductId} />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <DeleteModal
				isOpen={isModalOpen}
				onClose={closeModal}
				onConfirm={confirmDelete}
			/>
      </div>
		</>
	);
};

export default Row;
