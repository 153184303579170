// import React from "react";
// import cn from "classnames";
// import styles from "./Price.module.sass";
// import Card from "../../../components/Card";
// import TextInput from "../../../components/TextInput";
// import { useProduct } from "../ProductContext";

// const Price = ({ className }) => {
//   const { productData, setProductData } = useProduct();

//   const handleInputChange = (name, value) => {
//     setProductData(prevData => ({ ...prevData, [name]: value }));
//   };

//   return (
//     <Card
//       className={cn(styles.card, className)}
//       title="Price & Stock"
//       classTitle="title-green"
//     >
//       <div className={styles.price}>
//         <TextInput
//           className={styles.field}
//           label="Market Price"
//           name="marketPrice"
//           type="text"
//           tooltip="Small description"
//           required
//           currency="AED"
//           value={String(productData.marketPrice || '')} // Ensure the value is a string
//           onChange={(e) => handleInputChange("marketPrice", e.target.value)}
//         />
        
//         <div className={styles.fieldset}>
//           <TextInput
//             className={styles.field}
//             classLabel={styles.label}
//             label="Discount Price"
//             name="discountPrice"
//             type="text"
//             required
//             currency="AED"
//             value={String(productData.discountPrice || '')} // Ensure the value is a string
//             onChange={(e) => handleInputChange("discountPrice", e.target.value)}
//           />
//           <TextInput
//             className={styles.field}
//             classLabel={styles.label}
//             label="Minimum Selling Price"
//             name="sellingPrice"
//             type="text"
//             required
//             currency="AED"
//             value={String(productData.sellingPrice || '')} // Ensure the value is a string
//             onChange={(e) => handleInputChange("sellingPrice", e.target.value)}
//           />
//         </div>
//         <br />
       
//         <TextInput
//             className={styles.field}
//             classLabel={styles.label}
//             label="stock"
//             placeholder="Enter Your Product Stock"
//             name="stock"
//             type="number"
//             required
//             value={String(productData.stock || '')} // Ensure the value is a string
//             onChange={(e) => handleInputChange("stock", e.target.value)}
//           />
//            <br />
//            <TextInput
//             className={styles.field}
//             classLabel={styles.label}
//             label="brand"
//             placeholder="Enter Your brand "
//             name="brand"
//             type="text"
//             required
//             value={String(productData.brand || '')} // Ensure the value is a string
//             onChange={(e) => handleInputChange("brand", e.target.value)}
//           />
//       </div>
//     </Card>
//   );
// };

// export default Price;

import React from 'react'

const Price = () => {
  return (
    <></>
  )
}

export default Price;
