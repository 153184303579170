export const BASE_URL = 'https://makeitless-backend.onrender.com/api';
// export const BASE_URL = "http://localhost:8000/api";

export const CREATE_SELLER = "/v1/seller/create";
export const SELLER_SIGNIN = "/v1/seller/signin";
export const SELLER_PROFILE = "/v1/seller/profile";
export const ADMIN_CREATE = "/v1/admin/create";
export const ADMIN_SIGNIN = "/v1/admin/login";
export const SELLER_ACCOUNT_API = "/v1/seller/account/create";
export const ADD_PRODUCT = "/v1/product/add";
export const GET_ALL_PRODUCTS = "/v1/product/all";
export const IMAGE_UPLOAD_API = "/v1/helper/image/upload";
export const DELETE_PRODUCT = "/v1/product/remove";
export const EDIT_PRODUCT = "/v1/product/edit";
export const ADD_VARIANT = "/v1/product/add/variant/:id";
export const GET_ALL_CATEGORY = "/v1/category/public/all";
export const PRODUCT_DETAIL = "/v1/product/detail/:id";
export const ALL_BIDS = "/v1/seller/all-bids/:id";
export const ALL_ORDERS = "/v1/seller/all-orders";
export const ORDER_DETAIL = "/v1/seller/order/detail";
export const CUSTOM_SIGNUP = "/v1/auth/send-otp";
export const SEND_AND_VERIFY_OTP_API = "/v1/auth/send-verify-otp";
export const VERIFY_OTP = "/v1/auth/verify-otp";
export const UPDATE_DOCUMENTS = "/v1/seller/update-provided-document";
export const UPDATE_ORDER_STATUS = "/v1/seller/order/update-status";
export const UPDATE_TRACKING_STATUS = "/v1/seller/order/update-tracking-status";
export const SEND_QUERIES = "v1/seller/contactadmin";
export const GET_VARIANT = "/v1/product/variant-detail";
export const RESET_PASSWORD = "/v1/seller/reset-password";
