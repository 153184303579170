// Import the functions you need from the SDKs you need
import { getAuth } from '@firebase/auth';
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyAkyPgDB7io6-teLTX_sRyALvdRmI5jcw8',
	authDomain: 'makeitless-9e1da.firebaseapp.com',
	projectId: 'makeitless-9e1da',
	storageBucket: 'makeitless-9e1da.appspot.com',
	messagingSenderId: '922459410090',
	appId: '1:922459410090:web:32e02269da6dd65aaa4ad3',
	measurementId: 'G-C8570PMMFL',
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
