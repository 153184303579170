import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../../components/TextInput";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
	showToast,
	SOMETHING_WENT_WRONG,
	TOAST_SUCCESS,
	TOAST_ERROR,
} from "../../utils/constants";
import "./style.css";
import axiosInstance from "../../utils/axios";
import "react-international-phone/style.css";
import { SEND_AND_VERIFY_OTP_API, VERIFY_OTP } from "../../utils/api";
import { setAuthentication } from "../../store/globalSlice";
import { setSession } from "../../utils/jwt";
import { RotatingLines } from "react-loader-spinner";
import AntPhone from "../../../src/components/AntPhone";
import { Header } from "../Welcome/index";
import Footer from "../../components/Footer";

const SignUp = () => {
	const { isAuthenticated, isLoading } = useSelector((state) => state.global);
	const nav = useNavigate();
	const dispatch = useDispatch();
	const [countryCode, setCountryCode] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [isLoadingSendOtp, setisLoadingSendOtp] = useState("");
	const [isLoadingVerifyOtp, setisLoadingVerifyOtp] = useState("");
	const [otpState, setotpState] = useState("");
	// const [isModalOpen, setIsModalOpen] = useState("");
	const [otpToken, setOtpToken] = useState("");
	const [OTP, setOTP] = useState("");
	localStorage.setItem("darkMode", false);
	useEffect(() => {
		if (isAuthenticated && !isLoading) {
			nav("/", { replace: true });
		}
	}, [isAuthenticated, isLoading, nav]);
	// ════════════════════║ THIS FUNCTION VERIFIES THE OTP TO REGISTER ║═════════════════════════
	const verifyOtp = async () => {
		let requestBody = {
			otp: parseInt(OTP),
			token: otpToken,
		};

		console.log("before signup...", requestBody);
		try {
			setisLoadingVerifyOtp(true);
			const response = await axiosInstance.post(VERIFY_OTP, requestBody);

			if (response.data.error) {
				showToast(response.data.message || SOMETHING_WENT_WRONG);
			} else {
				console.log("Profile created", response);
				setSession(response?.data?.payload);
				dispatch(setAuthentication(true));
				// nav("/login");
				window.location.replace("/login");
				showToast(response.data.message, TOAST_SUCCESS);
				// _getProfile();
			}
		} catch (error) {
			showToast(error.response?.data?.message || SOMETHING_WENT_WRONG);
		} finally {
			setisLoadingVerifyOtp(false);
		}
	};

	// ════════════════════║ THIS FUNCTION SENDS THE OTP TO REGISTER ║═════════════════════════
	const sendOtp = async () => {
		try {
			let requestBody = {
				name,
				email,
				password,
				accountType: "SELLER",
				countryCode,
				mobileNumber,
			};

			console.table(requestBody);
			setisLoadingSendOtp(true);
			const response = await axiosInstance.post(
				SEND_AND_VERIFY_OTP_API,
				requestBody
			);

			if (response.data.error) {
				showToast(response.data.message || SOMETHING_WENT_WRONG);
			} else {
				// console.log("signup result....", response);
				// setotpState(true);
				// setOtpToken(response?.data?.payload);
				// showToast(response.data.message, TOAST_SUCCESS);
				window.location.replace("/login");
			}
		} catch (error) {
			showToast(error.response?.data?.message || SOMETHING_WENT_WRONG);
		} finally {
			setisLoadingSendOtp(false);
		}
	};

	return (
		<div className="w-screen h-screen flex flex-col bg-[rgb(255,255,247)]">
			<Header
				upperButton={{
					text: "Sign in",
					href: "/login",
				}}
			/>
			<div className="flex w-full grow items-center justify-center">
				<div
					className={cn(
						"w-96 h-[38rem] max-sm:w-[19rem] flex flex-col justify-center bg-slate-300 border border-gray-300 shadow-2xl rounded-lg p-5 space-y-4"
					)}
				>
					<div className={cn("h2", styles.title, " text-center w-full")}>
						Sign Up
					</div>

					<form
						className="flex flex-col space-y-4"
						onSubmit={(e) => {
							e.preventDefault();
							if (password !== password2) {
								showToast("Passwords do not match", TOAST_ERROR);
								return;
							}
							otpState ? verifyOtp() : sendOtp();
						}}
						autoComplete="off"
					>
						{otpState ? (
							<>
								<label htmlFor="otp" className="text-sm text-black">
									OTP:
								</label>
								<TextInput
									className={styles.field}
									name="otp"
									type="otp"
									placeholder="OTP"
									required
									icon="lock"
									value={OTP}
									onChange={(e) => setOTP(e.target.value)}
									id="otp"
								/>
								<button
									className="px-4 py-2 rounded-lg bg-pink-600 btns w-full text-white flex justify-center"
									type="submit"
								>
									{isLoadingVerifyOtp ? (
										<RotatingLines
											strokeColor="white"
											strokeWidth="5"
											animationDuration="0.75"
											width="25"
											visible={true}
										/>
									) : (
										"Verify"
									)}
								</button>
							</>
						) : (
							<>
								<div className="flex">
									<AntPhone value={countryCode} onChange={setCountryCode} />
									<TextInput
										className={"w-full"}
										name="number"
										type="number"
										placeholder="Number"
										required
										icon="phone"
										value={mobileNumber}
										onChange={(e) => {
											setMobileNumber(e.target.value);
										}}
									/>
								</div>
								{/* <label htmlFor="name" className="text-xs text-black">
									Name:
								</label> */}
								<TextInput
									className={styles.field}
									name="name"
									type="text"
									placeholder="Store Name"
									required
									icon="smile"
									value={name}
									onChange={(e) => setName(e.target.value)}
									id="name"
								/>
								{/* <label htmlFor="email" className="text-xs text-black">
									Email:
								</label> */}
								<TextInput
									className={styles.field}
									name="email"
									type="email"
									placeholder="Email"
									required
									icon="mail"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									id="email"
								/>

								<TextInput
									className={styles.field}
									name="password"
									type="password"
									placeholder="Password"
									required
									icon="lock"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									id="password"
								/>

								<TextInput
									className={styles.field}
									name="confirmPassword"
									type="password"
									placeholder="Confirm Password"
									required
									icon="lock"
									value={password2}
									onChange={(e) => setPassword2(e.target.value)}
									id="confirmPassword"
								/>

								{/* <label htmlFor="confirmPassword" className="text-sm text-black">
									Confirm Password:
								</label> */}
								<div className="text-center">
									<label
										htmlFor="terms"
										className="text-sm text-center text-black"
									>
										By creating an account, you acknowledge and accept Make It
										Less Portal L.L.C's
										<br />
										<Link
											to="/termsandconditions"
											className="text-pink-600 decoration-2 hover:underline font-medium"
										>
											Terms and Conditions.
										</Link>
									</label>
								</div>
								<button
									className="signup-button px-4 py-2 rounded-lg bg-pink-600 btns w-full text-white flex justify-center"
									type="submit"
								>
									{isLoadingSendOtp ? (
										<RotatingLines
											strokeColor="white"
											strokeWidth="5"
											animationDuration="0.75"
											width="25"
											visible={true}
										/>
									) : (
										"Sign Up"
									)}
								</button>
							</>
						)}
					</form>
					<div className={styles.info + " flex justify-center"}>
						<span className="mr-1">Already have an account?</span>
						<Link to="/login">
							<span className="text-pink-600 hover:text-pink-500 transition-all">
								Sign In
							</span>
						</Link>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
export default SignUp;
