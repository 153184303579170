/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Image from "../components/Image";
import { Link } from "react-router-dom";
import { Header } from "../screens/Welcome";
import Footer from "../components/Footer";

const feesData = [
	{ category: "Apparel", fee: "12%" },
	{ category: "Automotive", fee: "10%" },
	{ category: "Baby", fee: "7% & 12%" },
	{ category: "Books", fee: "10%" },
	{ category: "Beauty", fee: "5% & 11%" },
	{ category: "Consumer Electronics", fee: "5%" },
	{ category: "Electronics Accessories", fee: "12% & 7%" },
	{ category: "Furniture", fee: "13% & 8%" },
	{ category: "Toys", fee: "10%" },
	{ category: "Health & Personal Care", fee: "7% & 12%" },
	{ category: "Home", fee: "10%" },
	{ category: "Jewelry", fee: "13% & 5%" },
	{ category: "Kitchen", fee: "12%" },
	{ category: "Camera", fee: "7%" },
	{ category: "Mobile Phones", fee: "4%" },
	{ category: "Major appliances", fee: "7%" },
	{ category: "Music instruments", fee: "12%" },
	{ category: "Office products", fee: "10%" },
	{ category: "Outdoor", fee: "12%" },
	{ category: "PC store", fee: "5%" },
	{ category: "Perfumes", fee: "12%" },
	{ category: "Personal care Appliances", fee: "7% & 13%" },
	{ category: "Pet Products", fee: "8% & 13%" },
	{ category: "Shoes", fee: "12%" },
	{ category: "Small Appliances", fee: "12%" },
	{ category: "Software", fee: "8%" },
	{ category: "Sports", fee: "12%" },
	{ category: "Luggage", fee: "12%" },
	{ category: "Tools & Home Improvement", fee: "12%" },
	{ category: "Video & DVD", fee: "9%" },
	{ category: "Video Game Consoles", fee: "5%" },
	{ category: "Video Games", fee: "10%" },
	{ category: "Watches", fee: "12% & 5%" },
	{ category: "All Other Categories", fee: "10%" },
];

const FeeAndCommisions = () => {
	return (
		<div className="min-h-screen w-full bg-gray-100 flex flex-col justify-center">
			<Header upperButton={{}} />
			{/* Header */}
			<header className="bg-pink-600 text-white text-center py-4 mb-6">
				<h1 className="text-4xl font-bold">Make It Less</h1>
				<p>Empowering Sellers Everywhere</p>
			</header>

			<div className="relative py-3 sm:max-w-xl sm:mx-auto">
				<div className="absolute inset-0 bg-gradient-to-r from-pink-600 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
				<div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
					<div className="text-center mb-6">
						<h1 className="text-3xl font-bold mb-2">Fees Overview</h1>
						<p className="text-gray-600">
							Here are the fees associated with each category on our platform.
							These fees help us maintain and improve our services for both
							sellers and buyers.
						</p>
					</div>
					{/* <div
						className={`mb-10 h-24 w-36 text-center cursor-pointer justify-center flex content-center items-center`}
					>
						<Link to="/welcome">
							{" "}
						
							<Image
								className={`w-36`}
								src="https://res.cloudinary.com/dcpte972l/image/upload/v1692191670/Holiday_Vacation_Travel_Agent_Promotion_Banner_Landscape_wvx6bq.png"
								alt="Core"
							/>
						</Link>
					</div> */}
					<div className="w-full mx-auto">
						<div className="font-bold text-xl mb-4">
							Referral Fee by Category
						</div>
						<table className="min-w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Category
									</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Referral Fee
									</th>
								</tr>
							</thead>
							<tbody className="bg-white divide-y divide-gray-200">
								{feesData.map((data, index) => (
									<tr key={index}>
										<td className="px-6 py-4 whitespace-nowrap">
											{data.category}
										</td>
										<td className="px-6 py-4 whitespace-nowrap">{data.fee}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div className="mt-10 text-center">
						<h2 className="text-xl font-bold mb-2">Payments</h2>
						<p className="text-gray-600">
							All payments are processed securely through our platform. We
							ensure that transactions are smooth and trustworthy for both
							parties involved.
						</p>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default FeeAndCommisions;
