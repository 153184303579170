/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import cn from "classnames";
import Card from "../../../components/Card";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useProduct } from "../ProductContext"; // Import the useProduct hook
import GenericImageUpload from "../GenericImageUpload";
import styles from "./ImagesAndCTA.module.sass"




function ImagesAndCTA() {
// const ImagesAndCTA = ({ className }) => {
	// const { productData, setProductData } = useProduct(); // Use the hook
	// // const maxImages = 5;
	// const [images, setImages] = useState([]);
	
	// const handleRemoveImage = (id) => {
	// 	const updatedImages = productData.images.filter((image) => image.id !== id);
	// 	setProductData((prevData) => ({ ...prevData, images: updatedImages }));
	// };

	// const SortableImage = SortableElement(({ image, index }) => (
	// 	<div key={image.id} className="items-center space-x-4">
	// 		<img
	// 			className={index === 0 ? "w-80 h-80" : "w-44 h-56 rounded-xl"} // Increase size for the first image
	// 			src={image.imageUrl}
	// 			alt={`Image ${index + 1}`}
	// 		/>
	// 		{/* <button
	// 			className="text-red-500 hover:text-red-700"
	// 			onClick={() => handleRemoveImage(image.id)}
	// 		>
	// 			Remove
	// 		</button> */}
	// 	</div>
	// ));

	// const SortableImageList = SortableContainer(({ images }) => (
	// 	<div className="flex space-x-4">
	// 		{images.map((image, index) => (
	// 			<SortableImage key={image.id} index={index} image={image} />
	// 		))}
	// 	</div>
	// ));

	// const onSortEnd = ({ oldIndex, newIndex }) => {
	// 	const updatedImages = [...productData.images];
	// 	const movedImage = updatedImages[oldIndex];
	// 	updatedImages.splice(oldIndex, 1);
	// 	updatedImages.splice(newIndex, 0, movedImage);
	// 	setProductData((prevData) => ({ ...prevData, images: updatedImages }));
	// };

	return (
		// <Card className={cn("p-4", className)} title="Images & CTA">
		// 	<GenericImageUpload
          
        //   className={styles.card}
        //   maxImages={5}
        //   images={images}
        //   onImageUpload={(image) => {
        //     console.log({ image });
        //     setImages([...images, image]);
        //   }}
        //   onImageRemove={(image) => {
        //     console.log({ image });
        //     const removedArr = images.filter((img) => img !== image);
        //     console.log({ removedArr });
        //     setImages(removedArr);
        //   }}
        //   setSortedImages={(images) => {
        //     setImages(images);
        //   }}
        // />

		// 	<SortableImageList
		// 		images={productData.images}
		// 		onSortEnd={onSortEnd}
		// 		axis="xy"
		// 	/>

			
		// </Card>
		<></>
	);
};

export default ImagesAndCTA;
