import React from 'react';

const InfoModal = ({ isOpen, content, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg shadow-lg max-w-xl w-full">
        <button 
          onClick={onClose} 
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Close
        </button>
        <div className="mt-4">{content}</div>
      </div>
    </div>
  );
};

export default InfoModal;
