import React, { useState, useEffect, useRef } from "react";

function CommonInfoDialog(props) {
  const infoRef = useRef();


  useEffect(() => {
    if (props?.infoPopupStatus) {
      if (!infoRef.current.open) {
        //═════════════║ RESETTING THE FORM VALUE ║══════════════════
        infoRef.current.showModal();
      }
    } else {
      if (infoRef.current.open) {
        infoRef.current.close();
      }
    }
  }, [props?.infoPopupStatus]);
  

  return (
    <>
      <dialog ref={infoRef} className="bg-transparent">
        <div className="w-full md:w-auto mx-auto my-4 bg-white px-2 rounded-xl shadow shadow-slate-300 p-10">
          <div className="px-8 pt-4 w-full">
            {/* <button
              onClick={() => props?.setinfoPopupStatus()}
              type="button"
              class="absolute top-20 right-0 mrr-20 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center darks:hover:bg-gray-800 darks:hover:text-white"
            >
              <svg class="w-5 h-5" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button> */}
          </div>

        {props?.children}
        </div>
      </dialog>
    </>
  );
}

export default CommonInfoDialog;
