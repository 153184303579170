export default function Presentation() {
	return (
		<section className="flex flex-col">
			{[1, 2, 3, 4, 5, 6, 7].map((e) => {
				return (
					<img
						key={e}
						alt={e}
						className="object-cover w-full"
						src={`/Presentation/presentation-${e}.jpg`}
					></img>
				);
			})}
		</section>
	);
}
