import { createSlice } from "@reduxjs/toolkit";
import { logger } from "../utils/logger";
const initialState = {
	isAuthenticated: false,
	isLoading: true,
	isLoadingForProfileCheck: true,
	profile: null,
	profileData: null,
	user: null,
	isProfile: false,
	globalData: null,
	authData: null,
	coordinates: null,
	confirmationResult: null,
};
export const globalSlice = createSlice({
	name: "global",
	initialState: initialState,
	reducers: {
		setAuthentication: (state, action) => {
			state.isAuthenticated = action.payload;
			state.isLoading = false;
		},
		setGlobalData: (state, action) => {
			// state.isLoading = false;
			state.globalData = action.payload;
			state.isLoading = false;
		},
		setAuthData: (state, action) => {
			state.isAuthenticated = true;
			state.authData = action.payload;
			state.isLoading = false;
		},
		setProfleData: (state, action) => {
			state.isProfile = true;
			state.profile = action.payload;
			state.isLoading = false;
			state.isLoadingForProfileCheck = false;
		},
		setFullProfileData: (state, action) => {
			state.profileData = action.payload;
		},
		setCoordinates: (state, action) => {
			state.coordinates = action.payload;
			state.isLoading = false;
		},
		setUser: (state, action) => {
			state.user = action.payload;
			state.isLoading = false;
		},
		setConfirmationResult: (state, action) => {
			state.confirmationResult = action.payload;
		},

		logOut: () => initialState,
	},
});

export const {
	setAuthentication,
	setGlobalData,
	setAuthData,
	setProfleData,
	setCoordinates,
	logOut,
	setConfirmationResult,
	setUser,
	setFullProfileData,
} = globalSlice.actions;

export default globalSlice.reducer;
