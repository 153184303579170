// import React, { useState, useEffect } from "react";
// import cn from "classnames";
// import styles from "./CategoryAndAttributes.module.sass";
// import Card from "../../../components/Card";
// import Dropdown from "../../../components/Dropdown";
// import Checkbox from "../../../components/Checkbox";
// import { useProduct } from "../ProductContext";
// import './index.css'


// const CategoryAndAttributes = ({ className }) => {

//   const {productData, setProductData} = useProduct(); // to use the hook 

//   const [selectedParent, setSelectedParent] = useState("Select parent category");
//   const [selectedChild, setSelectedChild] = useState("Select child category");
//   const [selectedSubCategory, setSelectedSubCategory] = useState("Select Attributes");
//   const [selectedAttributeValues, setSelectedAttributeValues] = useState([]);

//   const handleChange = (attribute, value) => {
//     // Find the selected attribute in the list
//     const selectedAttributeIndex = selectedAttributeValues.findIndex(
//       (item) => item.attribute === attribute
//     );

//     // If the attribute is not in the list, create a new object for it
//     if (selectedAttributeIndex === -1) {
//       setSelectedAttributeValues([
//         ...selectedAttributeValues,
//         { attribute, value },
//       ]);
//     } else {
//       // If the attribute is in the list, update its value
//       const updatedAttributeValues = [...selectedAttributeValues];
//       updatedAttributeValues[selectedAttributeIndex].value = value;

//       // Update the selectedAttributeValues list
//       setSelectedAttributeValues(updatedAttributeValues);
//     }
//   };
//   // Define options for each parent category
//   const parentOptions = ["Select parent category", "Electronic", "Fashion", "Food"];

//   // Define child options for each parent category directly
//   const childOptions = {
//     Electronic: ["Select child category", "Mobile", "Tablet", "Laptop"],
//     Fashion: ["Select child category", "DressSize", "ShoeSize"],
//     Food: ["Select child category", "Cuisine", "DietaryRestrictions"],
//   };
  

//   // Define headings and associated data
//   const headingData = {
//     Mobile: {
//       headings: ["RAM", "Color", "Storage", "Camera"],
//       data: {
//         RAM: ["2GB", "4GB", "6GB", "8GB"],
//         Color: ["Black", "White", "Blue", "Red"],
//         Storage: ["32GB", "64GB", "128GB", "256GB"],
//         Camera: ["12MP", "16MP", "20MP", "24MP"],
//       },
//     },
//     Tablet: {
//       headings: ["RAM", "Color", "Storage", "Camera"],
//       data: {
//         RAM: ["3GB", "6GB", "8GB", "16GB"],
//         Color: ["Black", "Silver", "Gold", "Gray"],
//         Storage: ["64GB", "128GB", "256GB", "512GB"],
//         Camera: ["8MP", "12MP", "16MP", "20MP"],
//       },
//     },
//     // Define headings and data for other child categories here
//   };

//   const compatibilityOptions = headingData[selectedChild]?.data || {};
//   useEffect(() => {
//     setProductData(prevData => ({
//       ...prevData,
//       parentCategory: selectedParent,
//       childCategory: selectedChild,
//       subCategory: selectedSubCategory,
//       attributes: selectedAttributeValues
//     }));
//   }, [selectedParent, selectedChild, selectedSubCategory, selectedAttributeValues, setProductData]);


//   return (
//     <Card
//       className={cn(styles.card, className)}
//       title="Category & Attributes"
//       classTitle="title-purple"
//     >
//       <div className={styles.dropdowns}>
//         <Dropdown
//           className={styles.field}
//           label="Parent Category"
//           value={selectedParent}
//           setValue={setSelectedParent}
//           options={parentOptions}
//         />
//         {selectedParent !== "Select parent category" && (
//           <Dropdown
//             className={styles.field}
//             label="Child Category"
//             value={selectedChild}
//             setValue={(child) => {
//               setSelectedChild(child);
//               setSelectedSubCategory("Select Attributes"); // Reset sub category when changing child category
//               setSelectedAttributeValues([]); // Reset selectedAttributeValues when changing child category
//             }}
//             options={childOptions[selectedParent]}
//           />
//         )}
//         {selectedChild !== "Select child category" && (
//           <>
//             <div className={styles.compatibilityOptions}>
//               <Dropdown
//                 className={styles.field}
//                 label="Attributes"
//                 value={selectedSubCategory}
//                 setValue={setSelectedSubCategory}
//                 options={["Select Attributes", ...headingData[selectedChild]?.headings || []]}
//               />
//             </div>
//           </>
//         )}
//       </div>

//       {selectedChild !== "Select child category" && selectedSubCategory !== "Select Attributes" && (
//         <div className={styles.compatibility}>
//           <div className={styles.compatibilityOptions}>
//             {compatibilityOptions[selectedSubCategory]?.map((option, index) => {
//               const selectedValue = selectedAttributeValues.find(
//                 (item) =>
//                   item.attribute === selectedSubCategory
//               );
//               const isChecked = selectedValue && selectedValue.value === option;

//               return (
//                 <Checkbox
//                   className={styles.checkbox}
//                   content={option}
//                   value={isChecked}
//                   onChange={() => handleChange(selectedSubCategory, option)}
//                   key={index}
//                 />
//               );
//             })}
//           </div>
//           {selectedAttributeValues.length > 0 && (
//             <div className={styles.attributeTable}>
//               <h2>Selected Attributes:</h2>
//               <table>
//                 <thead>
//                   <tr>
//                     <th>Attribute</th>
//                     <th>Value</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {selectedAttributeValues.map((item, index) => (
//                     <tr key={index}>
//                       <td>{item.attribute}</td>
//                       <td>{item.value}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           )}
//         </div>
//       )}
//     </Card>
   
//   );
// };

// export default CategoryAndAttributes;




import React from 'react'

const CategoryAndAttributes = () => {
  return (
    <></>
  )
}

export default CategoryAndAttributes;