import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";

const ApprovalPage = () => {
	const {
		isAuthenticated,
		isLoading,
		isProfile,
		isLoadingForProfileCheck,
		profileData,
	} = useSelector((state) => state.global);

	const adminEmail = "support@makeitless.com";
	const isSuccessMessage =
		profileData?.message &&
		profileData.message.toLowerCase().includes("success");

	useEffect(() => {
		const timer = setTimeout(() => {
			window.location.reload();
		}, 300000);

		return () => clearTimeout(timer);
	}, []);

	if (isLoading || isLoadingForProfileCheck) {
		return (
			<div className="flex justify-center items-center h-screen">
				<RotatingLines
					strokeColor="#00BFFF"
					strokeWidth="5"
					animationDuration="0.75"
					width="50"
					visible={true}
				/>
			</div>
		);
	}

	return (
<div className="min-h-screen bg-gray-300 flex flex-col items-center justify-center">
    <div className="mb-8">
        <a href="/welcome">
            <img
                src="https://res.cloudinary.com/dcpte972l/image/upload/v1692191670/Holiday_Vacation_Travel_Agent_Promotion_Banner_Landscape_wvx6bq.png"
                alt="Company Logo"
                className="w-64 h-auto"
            />
        </a>
    </div>

    <div className="text-center">
        

        {isSuccessMessage ? (
            <section className="bg-white dark:bg-gray-900 rounded-md shadow-2xl">
                <div className="flex flex-col items-center px-4 py-12 mx-auto text-center">
                    <h2 className="max-w-2xl mx-auto text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl dark:text-white">
                    <span className="text-green-600"> Congratulations! </span> You have been approved. You can now access all the features of our platform.
                    </h2>
                    <div className="inline-flex w-full mt-6 sm:w-auto">
                        <a href="/" className="inline-flex items-center justify-center w-full px-6 py-2 text-white duration-300 bg-green-600 rounded-lg hover:bg-green-500 focus:ring focus:ring-green-300 focus:ring-opacity-80">
                            BACK TO HOME
                        </a>
                    </div>
                </div>
            </section>
        ) : (
            <section className="bg-white dark:bg-gray-900 rounded-md shadow-2xl">
                <div className="flex flex-col items-center px-4 py-12 mx-auto text-center">
                    <h2 className="max-w-2xl mx-auto text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl dark:text-white">
                        Your profile is currently under review. For further information or to expedite the process, please contact the admin. <span className="text-pink-600"> Once approved, kindly check your email for confirmation.</span>
                    </h2>
                    <div className="inline-flex w-full mt-6 sm:w-auto">
                        <a href={`mailto:${adminEmail}`} className="inline-flex items-center justify-center w-full px-6 py-2 text-white duration-300 bg-pink-600 rounded-lg hover:bg-pink-500 focus:ring focus:ring-pink-300 focus:ring-opacity-80">
                            Contact Admin
                        </a>
                    </div>
                </div>
            </section>
        )}
    </div>
</div>

	);
};

export default ApprovalPage;
