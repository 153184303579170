/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axios";
import { useParams, useNavigate } from "react-router-dom";
import { FiCopy } from "react-icons/fi";

import {
	CURRENCY,
	SOMETHING_WENT_WRONG,
	TOAST_SUCCESS,
	formatPrice,
	showToast,
} from "../../utils/constants";
import Loader from "../../components/Loader";
import { Feather } from "lucide-react";

const ProductDetail = () => {
	const { id } = useParams();
	const [product, setProduct] = useState(null);
	const [bids, setBids] = useState({});
	const navigate = useNavigate();
	const [showCopied, setShowCopied] = useState(false);

	const copyToClipboard = (sku) => {
		navigator.clipboard.writeText(sku).then(
			() => {
				setShowCopied(true);
				setTimeout(() => {
					setShowCopied(false);
				}, 1000); // Hide the message after 1 second
			},
			(err) => {
				console.error("Could not copy text: ", err);
			}
		);
	};

	useEffect(() => {
		axiosInstance
			.get(`/v1/product/detail/${id}`)
			.then((response) => {
				setProduct(response?.data?.payload);
				showToast(TOAST_SUCCESS, "success"); // Show success toast when product details are fetched

				// Fetch bids for each variant
				const bidPromises = response?.data?.payload?.variants.map((variant) =>
					axiosInstance.get(`/v1/seller/all-bids?variant=${variant?._id}`)
				);

				return Promise.all(bidPromises).then((bidResponses) => {
					const newBids = {};

					bidResponses.forEach((bidResponse, index) => {
						newBids[response?.data?.payload?.variants[index]?._id] =
							bidResponse?.data?.payload?.docs;
					});
					setBids(newBids);
					showToast("Bids fetched successfully", "success");
				});
			})
			.catch((error) => {
				console.error("Error fetching product details:", error);
				showToast(SOMETHING_WENT_WRONG, "error"); // Show error toast if there's an issue fetching product details
			});
	}, [id]);

	const handleDeleteVariant = async (variantId) => {
		try {
			const response = await axiosInstance.delete(
				`/v1/seller/delete/variant/${variantId}`
			);
			if (response?.data?.success) {
				showToast(
					`Variant with ID ${variantId} deleted successfully.`,
					"success"
				);
				setProduct((prevProduct) => {
					const updatedVariants = prevProduct?.variants?.filter(
						(variant) => variant?._id !== variantId
					);
					return { ...prevProduct, variants: updatedVariants };
				});
			} else {
				showToast(response?.data?.message || SOMETHING_WENT_WRONG, "error");
			}
		} catch (error) {
			console.error("Error deleting variant:", error);
			showToast(SOMETHING_WENT_WRONG, "error");
		}
	};

	if (!product) {
		return <Loader />;
	}

	return (
		<div className="bg-white shadow-xl rounded-2xl p-2 md:p-8 w-full space-y-8">
			{product?.variants?.length === 0 && (
				<div className="text-center">
					<div className="text-gray-700 text-xl font-normal">
						No Variants added yet.
					</div>
					<button
						onClick={() => navigate(`/variant/add/${id}`)}
						type="button"
						className="mt-4 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
					>
						Add Variants
					</button>
				</div>
			)}

			{/* Render Variant Details */}
			{product?.variants?.map((variant, index) => (
				<div
					key={index}
					className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-gray-200 p-2 md:p-4 rounded-md shadow-md hover:shadow-lg transition-shadow duration-300"
				>
					{/* Thumbnail Image */}
					<div>
						<div className="flex justify-between space-x-2">
							<button
								className="bg-pink-700 hover:bg-pink-600 text-white rounded px-3 py-2 transition duration-300"
								onClick={() =>
									navigate(`/variant-edit/${id}/${variant?.variantId?._id}`)
								}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
									/>
								</svg>
							</button>
							<button
								onClick={() => handleDeleteVariant(variant?.variantId?._id)}
								className="bg-pink-700 hover:bg-pink-600 text-white rounded px-3 py-2 transition duration-300"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
									/>
								</svg>
							</button>
						</div>

						<h3 className="font-bold text-center text-lg p-5">
							Thumbnail Image
						</h3>

						{variant?.thumbnailImage && (
							<div className="col-span-1 flex flex-col gap-y-3 items-center justify-center">
								<img
									src={variant?.thumbnailImage}
									alt={`Thumbnail Image for Variant ${index + 1}`}
									className="max-w-xs rounded-md shadow-lg border border-gray-300"
								/>
								{variant?.variantId?.images?.map((imgUrl, index) => {
									return (
										<img
											src={imgUrl}
											alt={`Thumbnail Image for Variant ${index + 1}`}
											className="max-w-xs rounded-md shadow-lg border border-gray-300"
										/>
									);
								})}
							</div>
						)}

						{variant?.images?.length > 0 && (
							<div className="mb-2">
								<h3 className="font-bold text-center text-lg p-5">Images</h3>
								<div className="flex flex-wrap">
									{variant?.images.map((image, imgIndex) => (
										<div key={imgIndex} className="w-1/3 sm:w-1/3 p-2">
											<img
												src={image}
												alt={`Variant ${index + 1} Image ${imgIndex + 1}`}
												className="w-25 h-25 rounded-md shadow border border-gray-300 transform hover:scale-105 transition-transform duration-300"
											/>
										</div>
									))}
								</div>
							</div>
						)}
					</div>

					{/* Variant Details */}
					<div className="col-span-1 p-2 md:p-10 bg-white border rounded-lg ">
						<p className="text-center relative">
							<span className="text-nd text-pink-600 p-2 bg-gray-200">
								MILUN-{variant?.SKU}
							</span>
							<button
								onClick={() => copyToClipboard(variant?.SKU)}
								className="ml-2 text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out"
							>
								<FiCopy size={20} />
							</button>
							{showCopied && (
								<span className="absolute top-full left-1/2 transform -translate-x-1/2 mt-1 text-sm text-gray-600 opacity-75">
									Copied
								</span>
							)}
						</p>

						<h3 className="font-bold text-center text-3xl text-pink-700 p-5 baiBold">
							Product Info
						</h3>
						<div className="table-auto w-full border-collapse border border-gray-400 mt-6">
							<div>
								<div className=" text-xl border border-gray-400">Name:</div>

								<div className="border border-gray-400">
									{variant?.variantId?.name || product?.name}
								</div>
							</div>

							<div className="mt-4">
								<div className=" text-xl  border border-gray-400">
									Description:
								</div>
								<div className=" border border-gray-400">
									{product?.description}
								</div>
							</div>
							<div className="mt-4">
								<div className=" text-xl  border border-gray-400">
									Category:
								</div>
								<div className=" border border-gray-400">{product?.brand}</div>
							</div>
							<div className="mt-4">
								<div className=" text-xl  border border-gray-400">Brand:</div>
								<div className=" border border-gray-400">
									{product?.category[0]?.name}
								</div>
							</div>
							<div className="mt-4">
								<div className=" text-xl  border border-gray-400">
									warranty(in months):
								</div>
								<div className=" border border-gray-400">
									{product?.warrantyDetails}
								</div>
							</div>
							<div className="mt-4">
								<div className=" text-xl  border border-gray-400">
									Warranty Details:
								</div>
								<div className=" border border-gray-400">
									{product?.warrantyExtraDetails}
								</div>
							</div>
							<div className="mt-4">
								<div className="text-xl border border-gray-400">
									Cash On Delivery:
								</div>
								<div className="border border-gray-400">
									{product?.isCodEnabled ? "Yes" : "No"}
								</div>
							</div>
							<div className="mt-4">
								<div className="text-xl border border-gray-400">
									Free Delivery:
								</div>
								<div className="border border-gray-400">
									{product?.isFreeDelivery ? "Yes" : "No"}
								</div>
							</div>
						</div>

						<div className="bg-gray-100 md:p-4 rounded-md mt-4">
							<h3 className="font-bold text-center text-3xl text-pink-700 px-5 py-2 baiBold">
								Specifications
							</h3>
							<div className="flex flex-col">
								{variant?.variantId?.specifications?.map((e, index) => (
									<div key={index} className="flex gap-2">
										<div>{e?.key}</div>
										<div>{" : "}</div>
										<div>{e?.value}</div>
									</div>
								))}
							</div>
						</div>

						<div className="bg-gray-100 md:p-4 rounded-md mt-4">
							<h3 className="font-bold text-center text-3xl text-pink-700 px-5 py-2 baiBold">
								Bullet points
							</h3>
							<div className="flex flex-col gap-y-3">
								{variant?.variantId?.bulletPoints?.map((e, index) => (
									<div key={index}>
										{"✪ "}
										{e}
									</div>
								))}
							</div>
						</div>

						<div className="bg-gray-100 md:p-4 rounded-md mt-4">
							<h3 className="font-bold text-center text-3xl text-pink-700 baiBold">
								Details
							</h3>
							<table className="table-auto w-full border-collapse border border-gray-400 mt-6">
								<tbody>
									<tr>
										<td className=" text-xl p-2 border border-gray-400">
											Market Price:
										</td>
										<td className="p-2 border border-gray-400">
											{CURRENCY} {formatPrice(variant?.variantId?.price?.marketPrice)}
										</td>
									</tr>
									<tr>
										<td className=" text-xl p-2 border border-gray-400">
											Discount Price:
										</td>
										<td className="p-2 border border-gray-400">
											{CURRENCY} {formatPrice(variant?.variantId?.price?.discountPrice)}
										</td>
									</tr>
									<tr>
										<td className=" text-xl p-2 border border-gray-400">
											Selling Preeeeice:
										</td>
										<td className="p-2 border border-gray-400">
											{CURRENCY} {formatPrice(variant?.variantId?.price?.sellingPrice)}
										</td>
									</tr>

									<tr>
										<td className=" text-xl p-2 border border-gray-400">
											Stock:
										</td>
										<td className="p-2 border border-gray-400">
											{variant?.variantId?.stock}
										</td>
									</tr>
									<tr>
										<td className=" text-xl p-2 border border-gray-400">
											Color:
										</td>
										<td className="p-2 border border-gray-400">
											{variant?.variantId?.color}
										</td>
									</tr>
									<tr>
										<td className=" text-xl p-2 border border-gray-400">
											Weight:
										</td>
										<td className="p-2 border border-gray-400">
											{variant?.variantId?.shippingDetails?.weight}
										</td>
									</tr>
									<tr>
										<td className=" text-xl p-2 border border-gray-400">
											Condition
										</td>
										<td className="p-2 border border-gray-400">
											{variant?.variantId?.condition}
										</td>
									</tr>
									<tr>
										<td className=" text-xl p-2 border border-gray-400">
											Delivery Channel
										</td>
										<td className="p-2 border border-gray-400">
											{variant?.variantId?.deliveryChannel}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<button
						onClick={() => navigate(`/variant/${variant?.variantId?._id}/bids`)}
						className="bg-pink-600 text-white px-4 py-2 rounded hover:bg-pink-700"
					>
						Show Bids
					</button>
				</div>
			))}
		</div>
	);
};

export default ProductDetail;
