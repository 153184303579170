import React from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import Icon from "../../../components/Icon";

const socials = [
	{
		title: "twitter",
		url: "https://twitter.com/ui8",
	},
	{
		title: "instagram",
		url: "https://www.instagram.com/ui8net/",
	},
	{
		title: "pinterest",
		url: "https://www.pinterest.com/ui8m/",
	},
];

const Profile = ({ sellerDetail }) => {
	return (
		<div className={styles.profile}>
			<div className={styles.details}>
				{/* <div className={styles.avatar}>
					<img src="/images/content/avatar.jpg" alt="Avatar" />
					<button className={styles.add}>
						<Icon name="add" />
					</button>
				</div> */}
				<div className={styles.wrap}>
					<div className={cn("h4", styles.man)}>{sellerDetail?.name}</div>
					<div className={styles.info}>
						<a
							className="hover:text-blue-700"
							href={"mailto:" + sellerDetail?.seller?.customerCareEmail}
						>
							{sellerDetail?.seller?.customerCareEmail}
						</a>
					</div>
					<div className={styles.info}>
						<p className="hover:text-blue-700">
							{sellerDetail?.seller?.customerCareNumber}
						</p>
					</div>
					<div className={styles.info}>
						<p className="hover:text-blue-700">
							{sellerDetail?.seller?.address}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;
