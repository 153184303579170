import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../../components/TextInput";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showToast, SOMETHING_WENT_WRONG } from "../../utils/constants";
import { useSelector } from "react-redux";
import "react-international-phone/style.css";
import { RotatingLines } from "react-loader-spinner";
import axiosInstance from "../../utils/axios";
import { setSession } from "../../utils/jwt";
import AntPhone from "../../../src/components/AntPhone";
import { Header } from "../Welcome";
import Footer from "../../components/Footer";
const SignIn = () => {
	const { isAuthenticated, isLoading } = useSelector((state) => state.global);
	const nav = useNavigate();
	localStorage.setItem("darkMode", false);
	useEffect(() => {
		if (isAuthenticated && !isLoading) {
			nav("/", { replace: true });
		}
	}, [isAuthenticated, isLoading, nav]);
	const [mobileNumber, setMobileNumber] = useState("");
	const [countryCode, setCountryCode] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const loginUser = async () => {
		try {
			// Cleaning the mobile number using regex
			const requestBody = {
				countryCode,
				mobileNumber,
				password,
				accountType: "SELLER",
			};
			// Cleaning ended
			console.table(requestBody);
			// return;
			setLoading(true);
			const response = await axiosInstance.post("/v1/auth/login", requestBody);
			if (response.data.error)
				showToast(response.data.message || SOMETHING_WENT_WRONG);
			else {
				// Extract token from response
				const { token } = response.data;
				if (token) {
					setSession(response?.data?.token);
					nav("/");
				}
			}
		} catch (error) {
			showToast(error.response?.data?.message || SOMETHING_WENT_WRONG);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="w-screen h-screen flex flex-col  bg-[rgb(255,255,247)]">
			<Header
				upperButton={{
					text: "Sign up",
					href: "/sign-up",
				}}
			/>
			<div className="flex w-full grow items-center justify-center">
				<div
					className={cn(
						"w-80 h-[30rem] max-sm:w-[19rem] flex flex-col justify-center bg-slate-300 border border-gray-300 shadow-2xl rounded-lg p-5 space-y-4"
					)}
				>
					<div className={cn("h2", styles.title, " text-center w-full")}>
						Sign in
					</div>
					<div className={styles.body}>
						<form
							className="flex flex-col gap-y-4 items-center px-1"
							onSubmit={(e) => {
								e.preventDefault();
								loginUser();
							}}
							autoComplete="off"
						>
							<div className="flex w-full">
								<label htmlFor="countryCode" className="sr-only">
									Country Code
								</label>{" "}
								{/* Invisible label for accessibility */}
								<AntPhone
									value={countryCode}
									onChange={setCountryCode}
									id="countryCode"
								/>
								<label htmlFor="mobileNumber" className="sr-only">
									Mobile Number
								</label>{" "}
								{/* Invisible label for accessibility */}
								<TextInput
									className={"w-auto"}
									name="mobileNumber"
									type="number"
									placeholder="Number"
									required
									icon="phone"
									value={mobileNumber}
									onChange={(e) => {
										setMobileNumber(e.target.value);
									}}
									id="mobileNumber"
								/>
							</div>
							<div>
								<label htmlFor="password" className="text-sm text-black">
									Password:
								</label>
								<TextInput
									name="password"
									type="password"
									placeholder="Password"
									required
									icon="lock"
									value={password}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									id="password"
								/>
							</div>
							<div className="">
								<label htmlFor="remember-me" className="text-sm text-black">
									By Signing in, You acknowledge and accept Make It Less Portal
									L.L.C's <br />
									<Link
										to="/termsandconditions"
										className="text-pink-600 decoration-2 hover:underline font-medium"
									>
										Terms and Conditions.
									</Link>
								</label>
							</div>
							<button
								className="px-4 py-3 hover:bg-pink-800 rounded-lg shadow-2xl bg-pink-600 btns w-full text-white flex justify-center"
								type="submit"
							>
								{loading ? (
									<RotatingLines
										strokeColor="white"
										strokeWidth="5"
										animationDuration="0.75"
										width="25"
										visible={true}
									/>
								) : (
									"Sign In"
								)}
							</button>
						</form>
						<div className={styles.info + " flex justify-center"}>
							<span className="mr-1">Don't have an account?</span>
							<Link to="/sign-up">
								<span className="text-pink-600 shadow-2xl hover:text-pink-500 transition-all">
									Sign Up
								</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default SignIn;
