import React from "react";
import { useState } from "react";

const RequestCategory = () => {
	const [category, setCategory] = useState("");

	const handleSubmit = () => {
		// Handle the submission logic here
		console.log("Category submitted:", category);
	};

	return (
		<div className="p-4">
			<label htmlFor="categoryName" className="block text-lg mb-2">
				Category Name
			</label>
			<div className=" items-center">
				<input
					type="text"
					id="categoryName"
					placeholder="Write your category"
					value={category}
					onChange={(e) => setCategory(e.target.value)}
					className="p-2 border border-gray-300 rounded mr-2 flex-grow"
				/>  
				<button
					onClick={handleSubmit}
					className="bg-pink-600 text-white px-4 py-2 rounded"
				>
					Send
				</button>
			</div>
		</div>
	);
};

export default RequestCategory;
