import React, { useState, useEffect } from "react";
import styles from "./Row.module.sass";
import axiosInstance from "../../../../utils/axios";
import { GET_ALL_PRODUCTS } from "../../../../utils/api";
import Icon from "../../../../components/Icon";
import Loader from "../../../../components/Loader";

const Row = ({ value, onChange }) => {
	const [products, setProducts] = useState([]); // State to store fetched products
	const [loading, setLoading] = useState(true); // State to handle loading state
	const [error, setError] = useState(null); // State to handle any errors

	useEffect(() => {
		axiosInstance
			.get(GET_ALL_PRODUCTS)
			.then((response) => {
				console.log("Response from backend:", response.data);
				if (
					response.data.error === false &&
					Array.isArray(response.data.payload.docs)
				) {
					setProducts(response.data.payload.docs);
				} else {
					throw new Error(
						"Unexpected data structure from the backend or an error occurred."
					);
				}
				setLoading(false);
			})
			.catch((err) => {
				console.error("Error fetching products:", err);
				setError(err);
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <Loader />;
	}

	if (error) {
		return <div>Error fetching products. Please try again later.</div>;
	}

	return (
		<>
			{Array.isArray(products) &&
				products.map((item, index) => (
					<div key={item.id || index} className={styles.row}>
						<div className={styles.col}></div>
						<div className={styles.col}>
							<div className={styles.item}>
								<div className={styles.preview}>
									<img
										srcSet={`${item.image2x} 2x`}
										src={item.image}
										alt="Product"
									/>
								</div>
								<div className={styles.details}>
									<div className={styles.product}>{item.name}</div>
									<div className={styles.wrap}></div>
								</div>
							</div>
						</div>
						<div className={styles.col}>{item.stock || "N/A"}</div>
						<div className={styles.details}>
							<div className={styles.wrap}>
								<div className={styles.details}>
									<div className={styles.wrap}>
										<div>{item.marketPrice || "Not available"}</div>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.col}>
							<button>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
									/>
								</svg>{" "}
								edit
							</button>
						</div>
						<div className={styles.col}>
							<button>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
									/>
								</svg>{" "}
								delete
							</button>
						</div>
						<div className={styles.col}>
							<button>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M12 4.5v15m7.5-7.5h-15"
									/>
								</svg>{" "}
								add variant
							</button>
						</div>
					</div>
				))}
		</>
	);
};

export default Row;
