import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../utils/axios";
import {
	ORDER_DETAIL,
	UPDATE_ORDER_STATUS,
	UPDATE_TRACKING_STATUS,
} from "../../../../utils/api";
import {
	formatPrice,
	showToast,
	TOAST_ERROR,
	TOAST_SUCCESS,
} from "../../../../utils/constants";
import { logger } from "../../../../utils/logger";
import OrderStatusModal from "../../../../components/OrderStatusModal/OrderStatusModal";
import OrderTrackingModal from "../../../../components/OrderTrackingModal/OrderTrackingModal";
import moment from "moment";

const OrderDetail = () => {
	const { id } = useParams();
	const [orderDetail, setOrderDetail] = useState(null);
	const [uploadFilePopupStatus, setuploadFilePopupStatus] = useState(false);
	const [updateTrackingStatus, setupdateTrackingStatus] = useState(false);
	const [currentId, setcurrentId] = useState(null);
	const [currentIdData, setcurrentIdData] = useState(null);
	const [isLoadingUpdate, setisLoadingUpdate] = useState(false);

	// ════════════════════║ THESE ARE OPTIONS FOR ORDER STATUS   ║═════════════════════════
	const orderStatusOptions = [
		{ label: "Pending", value: "PENDING" },
		{ label: "Processing", value: "PROCESSING" },
		{ label: "Shipped", value: "SHIPPED" },
		{ label: "Out for Delivery", value: "OUT_FOR_DELIVERY" },
		{ label: "Delivered", value: "DELIVERED" },
		{ label: "Canceled", value: "CANCELED" },
		{ label: "Refunded", value: "REFUNDED" },
		{ label: "On Hold", value: "ON_HOLD" },
		{ label: "Awaiting Payment", value: "AWAITING_PAYMENT" },
		{ label: "Partially Shipped", value: "PARTIALLY_SHIPPED" },
		{ label: "Return Requested", value: "RETURN_REQUESTED" },
		{ label: "Return in Progress", value: "RETURN_IN_PROGRESS" },
		{ label: "Return Completed", value: "RETURN_COMPLETED" },
		{ label: "Exchange Requested", value: "EXCHANGE_REQUESTED" },
		{ label: "Exchange in Progress", value: "EXCHANGE_IN_PROGRESS" },
		{ label: "Exchange Completed", value: "EXCHANGE_COMPLETED" },
		{ label: "Failed", value: "FAILED" },
		{ label: "Archived", value: "ARCHIVED" },
	];

	// ════════════════════║ THESE ARE OPTIONS FOR TRACKING STATUS   ║═════════════════════════
	const trackingStatusOptions = [
		{ label: "Pending", value: "PENDING" },
		{ label: "Processing", value: "PROCESSING" },
		{ label: "Shipped", value: "SHIPPED" },
		{ label: "Out for Delivery", value: "OUT_FOR_DELIVERY" },
		{ label: "Delivered", value: "DELIVERED" },
	];

	// ════════════════════║ THIS FUNCTION FETCHES ORDER DETAILS   ║═════════════════════════
	const fetchOrderDetail = async () => {
		try {
			const response = await axiosInstance.get(`${ORDER_DETAIL}/${id}`);
			setOrderDetail(response.data.payload);
		} catch (error) {
			console.error("Error fetching order details:", error);
		}
	};

	// ════════════════════║ THIS FUNCTION UPDATES THE ORDER STATUS   ║═════════════════════════
	const updateOrders = async (status) => {
		setisLoadingUpdate(true);
		let url = `${UPDATE_ORDER_STATUS}/${currentId}`;
		let requestBody = {
			status: status,
		};
		logger.log(`before updating status....${JSON.stringify(requestBody)}`);
		try {
			const response = await axiosInstance.post(url, requestBody);
			logger.log(`after updating status ${JSON.stringify(response)}`);
			if (response?.data?.error == false) {
				fetchOrderDetail();
				showToast("Order status updated successfully !", TOAST_SUCCESS);
			} else {
				showToast(response?.data?.message, TOAST_ERROR);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			showToast("Something went wrongg !", TOAST_ERROR);
		} finally {
			setisLoadingUpdate(false);
			handlePackageModalClose();
		}
	};

	// ════════════════════║ THIS FUNCTION UPDATES THE ORDER STATUS   ║═════════════════════════
	const updateTrackingDetails = async (values) => {
		setisLoadingUpdate(true);
		let url = `${UPDATE_TRACKING_STATUS}/${currentId}`;
		let requestBody = {
			company: values?.company,
			tracking_number: values?.trackingNo,
			status: values?.orderStatus,
			estimated_delivery: values?.date,
		};
		logger.log(
			`before updating tracking details....${JSON.stringify(requestBody)}`
		);
		try {
			const response = await axiosInstance.post(url, requestBody);
			logger.log(`after updating status ${JSON.stringify(response)}`);
			if (response?.data?.error == false) {
				fetchOrderDetail();
				showToast("tracking details updated successfully !", TOAST_SUCCESS);
			} else {
				showToast(response?.data?.message, TOAST_ERROR);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			showToast("Something went wrongg !", TOAST_ERROR);
		} finally {
			setisLoadingUpdate(false);
			handlePackageModalClose();
		}
	};

	// ════════════════════║ THIS FUNCTION CLOSES MODAL AND RESET ALL CURRENT VALUES TO NULL   ║═════════════════════════
	const handlePackageModalClose = () => {
		setcurrentId(null);
		setcurrentIdData(null);
		setuploadFilePopupStatus(false);
		setupdateTrackingStatus(false);
	};

	useEffect(() => {
		fetchOrderDetail();
	}, [id]);

	if (!orderDetail)
		return (
			<div className="flex justify-center items-center h-screen">
				Loading...
			</div>
		);

	return (
		<>
			<OrderStatusModal
				title="Select Status"
				type={""}
				uploadFilePopupStatus={uploadFilePopupStatus}
				setuploadFilePopupStatus={setuploadFilePopupStatus}
				currentId={null}
				currentIdData={null}
				isLoading={isLoadingUpdate}
				updateOrders={updateOrders}
				handlePackageModalClose={handlePackageModalClose}
				orderStatusOptions={orderStatusOptions}
			/>

			<OrderTrackingModal
				title="Update Tracking Status"
				type={""}
				uploadFilePopupStatus={updateTrackingStatus}
				setuploadFilePopupStatus={setupdateTrackingStatus}
				currentId={null}
				currentIdData={null}
				isLoading={isLoadingUpdate}
				updateTrackingDetails={updateTrackingDetails}
				handlePackageModalClose={handlePackageModalClose}
				orderStatusOptions={trackingStatusOptions}
			/>
			<div className="my-2 md:my-10 mx-0 md:mx-4 bg-white shadow-2xl rounded-lg p-4 md:p-8 space-y-6 overflow-hidden">
				<div className="flex flex-col md:flex-row justify-between md:items-center border-b-2 pb-4 mb-6">
					<h1 className="text-lg md:text-3xl font-bold text-gray-800">
						Order ID:{" "}
						<span className="text-pink-600">{orderDetail.orderId}</span>
					</h1>
					<p className="text-lg text-pink-600">{orderDetail.createdAt}</p>
				</div>

				<div className="space-y-6">
					{/* User Details Box */}
					<div className="bg-blue-100 p-4 rounded-lg shadow-md">
						<h2 className="text-2xl font-bold text-gray-800">User Details</h2>
						<p className="text-gray-700">Email: {orderDetail?.user?.email}</p>
						<p className="text-gray-700">
							Mobile Number: {orderDetail?.user?.mobileNumber || "N/A"}
						</p>
						{/* Add more user details as needed */}
					</div>

					{orderDetail?.cart?.map((item) => (
						<div
							key={item._id}
							className="flex flex-col md:flex-row space-x-6 bg-slate-300 p-4 rounded-lg shadow-md hover:shadow-lg transition"
						>
							<div className="flex-none">
								<img
									src={item?.variant?.thumbnailImage}
									alt={item?.product?.name}
									className="w-32 h-32 rounded-md object-cover"
								/>
							</div>
							<div className="flex-grow space-y-4">
								<h2 className="text-lg md:text-2xl font-bold text-gray-800 border-b">
									<span className="text-pink-600 underline">Product:</span>{" "}
									{item?.product?.name}
								</h2>
								<p>
									<span className="text-pink-600 font-semibold underline">
										Description:
									</span>{" "}
									{item?.product?.description}
								</p>
								<div>
									<span className="text-pink-600 font-semibold underline">
										Features:
									</span>
									<ul className="list-disc list-inside mt-2 text-gray-700">
										{item?.product?.features.map((feature, index) => (
											<li key={index}>{feature}</li>
										))}
									</ul>
								</div>
								<div className="flex">
									<p className="text-pink-600 font-bold">
										Price:{" "}
										<span className="text-gray-800 font-medium">
											AED {formatPrice(item?.purchasePrice)}
										</span>
									</p>
								</div>
								<div className="flex">
									<p className="text-pink-600 font-bold">
										Status:{" "}
										<span className="text-gray-800 font-medium">
											{/* {item?.orderStatus || 'NA'} */}
										</span>
									</p>
								</div>

								{/* ════════════════════║ TRACKING DETAILS BLOCK  ║═════════════════════════ */}
								<div className="flex  md:space-y-0 flex-row md:flex-row border-t border-t-gray-300 pt-4 mt-6">
									{Array.isArray(item?.orderStatus) &&
										item?.orderStatus?.length !== 0 &&
										item?.orderStatus?.map((item, index) => (
											<div className="relative flex-1 flex justify-center items-center">
												<div className={`flex-1 h-1 bg-amber-400`}></div>
												<div
													className={`w-6 h-6 bg-amber-400 rounded-full shadow-xl`}
												></div>
											</div>
										))}
								</div>

								<div className="flex flex-row md:flex-row mb-6 mt-2">
									{Array.isArray(item?.orderStatus) &&
										item?.orderStatus?.length !== 0 &&
										item?.orderStatus?.map((item, index) => (
											<div className="relative flex-1 flex justify-center items-center">
												<div className="text-right flex-1 text-xs font-semibold text-gray-600">
													{item?.status}
												</div>
											</div>
										))}
								</div>

								{item?.tracking?.tracking_number !== undefined && (
									<div className="flex space-y-2 md:space-y-0 flex-col md:flex-row mb-6  pt-4 mt-6">
										<div className="flex-1">
											<div className="text-sm">Tracking No.</div>
											<div className="font-semibold">
												{item?.tracking?.tracking_number}
											</div>
										</div>

										<div className="flex-1">
											<div className="text-sm">Status</div>
											<div className="font-semibold">
												{item?.orderStatus?.[item?.orderStatus?.length - 1]
													?.status || "N/A"}
											</div>
										</div>

										<div className="flex-1">
											<div className="text-sm">company</div>
											<div className="font-semibold">
												{item?.tracking?.company}
											</div>
										</div>

										<div className="flex-1">
											<div className="text-sm">Estimate Delivery</div>
											<div className="font-semibold">
												{moment(item?.tracking?.estimated_delivery).format(
													"DD-MM-YYYY hh:mm A"
												)}
											</div>
										</div>
									</div>
								)}

								<div className="flex flex-col md:flex-row">
									<button
										type="button"
										onClick={() => {
											setcurrentId(item?._id);
											setcurrentIdData(item);
											setuploadFilePopupStatus(true);
										}}
										className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
									>
										Update Status
									</button>
									<button
										onClick={() => {
											setcurrentId(item?._id);
											setcurrentIdData(item);
											setupdateTrackingStatus(true);
										}}
										type="button"
										className="mt-4 md:mt-0 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
									>
										Update Tracking Details
									</button>
								</div>
							</div>
						</div>
					))}
				</div>

				<div className="flex justify-between border-t-2 pt-6 mt-6 flex-col md:flex-row">
					<p className="text-md md:text-xl font-semibold text-gray-800">
						Delivery Charge:{" "}
						<span className="text-pink-600">
							AED {orderDetail?.deliveryCharge}
						</span>
					</p>
					<p className="text-xl md:text-2xl font-bold text-gray-800">
						Grand Total:{" "}
						<span className="text-pink-600">
							AED {formatPrice(orderDetail?.grandTotal)}
						</span>
					</p>
				</div>
			</div>
		</>
	);
};

export default OrderDetail;
