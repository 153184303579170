import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../../../utils/jwt";
import { auth } from "../../../network/auth/init";
import { setAuthentication } from "../../../store/globalSlice";
import { logger } from "../../../utils/logger";

const User = ({ className }) => {
	const [visible, setVisible] = useState(false);
	const { pathname } = useLocation();
	const global = useSelector((state) => state.global);
	console.log(global);
	const nav = useNavigate();
	const dispatch = useDispatch();

	logger.log(`global....${JSON.stringify(global,null,2)}`)

	const items = [
		{
			menu: [
				{
					title: "Profile",
					url: "/shop",
					onClick: () => {},
				},
				{
					title: "Edit profile",
					url: "/settings",
					onClick: () => {},
				},
			],
		},
		// {
		// 	menu: [
		// 		{
		// 			title: "Analytics",
		// 			icon: "bar-chart",
		// 			url: "/customers/overview",
		// 			onClick: () => {},
		// 		},
		// 		{
		// 			title: "Affiliate center",
		// 			icon: "ticket",
		// 			url: "/affiliate-center",
		// 			onClick: () => {},
		// 		},
		// 		{
		// 			title: "Explore creators",
		// 			icon: "grid",
		// 			url: "/explore-creators",
		// 			onClick: () => {},
		// 		},
		// 	],
		// },
		// {
		// 	menu: [
		// 		{
		// 			title: "Upgrade to Pro",
		// 			icon: "leaderboard",
		// 			color: true,
		// 			url: "/upgrade-to-pro",
		// 			onClick: () => {},
		// 		},
		// 	],
		// },
		{
			menu: [
				{
					title: "Account settings",
					url: "/settings",
					onClick: () => {},
				},
				{
					title: "Log out",
					onClick: () => {
						dispatch(setAuthentication(false));
						setSession();
						auth.signOut();
						nav("/login", {
							replace: true,
						});
					},
				},
			],
		},
	];
	// TODO
	return (
		<OutsideClickHandler onOutsideClick={() => setVisible(false)}>
			<div
				className={cn(styles.user, className, {
					[styles.active]: visible,
				})}
			>
				<button className={`cursor-pointer px-4 py-3 rounded text-sm transition duration-300 ease-in-out bg-slate-300 hover:bg-pink-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50 shadow-lg transform hover:scale-105 flex flex-col items-center justify-center`} onClick={() => setVisible(!visible)}>
					{/* <img
						src={
							global?.profile?.seller
								? global?.profile?.seller?.profilePic
								: "/images/content/avatar.jpg"
						}
						alt="Avatar"
					/> */}
						<span className="font-semibold"></span>
										{global?.profile?.seller?.businessName && (
											<span className=" w-full text-xs max-w-[200px] text-center">
												{/* Hi{" "} */}
												{global?.profile?.seller.businessName.length > 12
													? ` ${global?.profile?.seller.businessName.substring(0, 12)}...`
													: ` ${global?.profile?.seller.businessName}`}
											</span>
										)}
				</button>

				<div className={styles.body}>
					{items.map((item, index) => (
						<div className={styles.menu} key={index}>
							{item.menu.map((x, index) =>
								x.url ? (
									<NavLink
										className={cn(styles.item, {
											[styles.color]: x.color,
											[styles.active]: pathname === x.url,
										})}
										to={x.url}
										onClick={() => setVisible(false)}
										key={index}
									>
										{x.icon && <Icon name={x.icon} size="24" />}
										{x.title}
									</NavLink>
								) : (
									<button
										className={styles.item}
										onClick={x.onClick}
										key={index}
									>
										{x.title}
									</button>
								)
							)}
						</div>
					))}
				</div>
			</div>
		</OutsideClickHandler>
	);
};

export default User;
