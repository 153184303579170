import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axios";
import { useParams, useNavigate } from "react-router-dom";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Card from "../../components/Card";
import productSpecifications from "./productSpecification.json";
import TextInput from "../../components/TextInput";
import GenericImageUpload from "../../screens/NewProduct/GenericImageUpload";
import { Loader, X } from "lucide-react";
import { showToast, TOAST_ERROR, TOAST_SUCCESS } from "../../utils/constants";

import { useSearchParams } from "react-router-dom";
import { logger } from "../../utils/logger";
import { GET_VARIANT, GET_ALL_CATEGORY } from "../../utils/api";
import { RotatingLines } from "react-loader-spinner";
import CommonInfoDialog from "../../components/CommonInfoDialog/CommonInfoDialog";
import { FaCheckCircle } from "react-icons/fa";
import InfoModal from "./Modal";

const VariantEdit = () => {
	const { productId, variantId } = useParams();
	const [product, setProduct] = useState(null);
	const [currentMilun, setcurrentMilun] = useState("");
	const [infoPopupStatus, setinfoPopupStatus] = useState(false);
	const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
	const [selectOther, setSelectOther] = useState([false]);
	const [name, setName] = useState("");
	const [color, setColor] = useState("");
	const [sellingPrice, setSellingPrice] = useState("");
	const [marketPrice, setMarketPrice] = useState("");
	const [discountPrice, setDiscountPrice] = useState("");
	const [stock, setStock] = useState("");
	const [weight, setWeight] = useState("");
	const [searchParams] = useSearchParams();
	const isNewProduct = searchParams.get("newProduct") === "true";
	const [productName, setProductName] = useState("");
	const [variantDetails, setVariantDetails] = useState(null);
	// const [deliveryCharge, setDeliveryCharge] = useState("");
	const [thumbnailImage, setThumbnailImage] = useState("");
	const [images, setImages] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [bulletPoints, setBulletPoints] = useState([""]);
	const [upc, setUpc] = useState("");
	const [condition, setCondition] = useState("NEW");
	const [deliveryChannel, setDeliveryChannel] = useState("MAKEITLESS");
	const [checkboxState, setCheckboxState] = useState({
		sellAbovePrice: false,
		sellAtMinimumPrice: false,
	});
	const [height, setHeight] = useState("");
	const [width, setWidth] = useState("");
	const [depth, setDepth] = useState("");

	const [sku, setSku] = useState("");
	const [specifications, setSpecifications] = useState([
		{ key: "", value: "" },
	]);
	const [subCategories, setDubCategories] = useState([]);
	// ------------------------------------------------------------USe for Madal---------------------------------------------------------------

	const [modalContent, setModalContent] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleModalOpen = (content) => {
		setModalContent(content);
		setIsModalOpen(true);
	};

	const navigate = useNavigate();
	useEffect(() => {
		if (!variantId) {
			// This is a new variant
			setName("");
			// reset other fields as needed
		}
		// ... rest of your code
	}, [productId, variantId]);

	useEffect(() => {
		axiosInstance
			.get(`/v1/product/detail/${productId}`)
			.then((response) => {
				const productData = response?.data?.payload;
				logger.log({ productData });
				setProductName(productData?.name || ""); // Set product name in state
				setProduct(productData);
			})
			.catch((error) => {
				console.error("Error fetching product details:", error);
			});
	}, [productId]);

	useEffect(() => {
		if (!variantId) return;
		logger.log({ variantId, product });
		// fetch variant details
		// product/variant-detail/:variantId
		axiosInstance.get(`${GET_VARIANT}/${productId}`).then((res) => {
			const currentVariant = res.data?.payload;
			if (currentVariant) {
				setName(currentVariant?.name || "");
				setSellingPrice(currentVariant?.price?.sellingPrice || "");
				setMarketPrice(currentVariant?.price?.marketPrice || "");
				setDiscountPrice(currentVariant?.price?.discountPrice || "");
				setStock(currentVariant?.stock || "");
				setWeight(currentVariant.shippingDetails?.weight || "");
				setSku(currentVariant?.sku || "");
				setColor(currentVariant?.color || "");
				setUpc(currentVariant?.upc || "");
				setBulletPoints(currentVariant?.bulletPoints || "");
				setThumbnailImage(currentVariant?.thumbnailImage || "");
				setSpecifications(currentVariant?.specifications || []);
				setCondition(currentVariant?.condition || "");
				setDeliveryChannel(currentVariant?.deliveryChannel || "");
				setImages(currentVariant?.images || []);
			}
		});
	}, [variantId]);

	//handle checkbox
	const handleCheckboxChange = (checkboxName) => {
		setCheckboxState({
			sellAbovePrice: false,
			sellAtMinimumPrice: false,
			[checkboxName]: true,
		});
	};

	// Function to handle adding new bullet point
	const addBulletPoint = () => {
		if (bulletPoints?.length < 20) {
			setBulletPoints([...bulletPoints, ""]);
		}
	};

	// Function to handle bullet point change
	const handleBulletPointChange = (index, value) => {
		const newBulletPoints = [...bulletPoints];
		newBulletPoints[index] = value;
		setBulletPoints(newBulletPoints);
	};

	// Function to handle deletion of a bullet point
	const deleteBulletPoint = (index) => {
		const newBulletPoints = bulletPoints?.filter((_, i) => i !== index);
		setBulletPoints(newBulletPoints);
	};

	// ════════════════════║ THIS FUNCTION CHECKS THE MANDATORY INPUT FIELDS   ║═════════════════════════
	const requiredCheck = (updatedVariant) => {
		console.log("the product data is...", updatedVariant);
		// if (updatedVariant?.name === '' || updatedVariant?.name === undefined) {
		// 	showToast("Enter variant name", TOAST_ERROR);
		// 	return false
		// }
		if (
			updatedVariant?.sellingPrice === "" ||
			updatedVariant?.sellingPrice === undefined
		) {
			showToast("Enter Selling Price", TOAST_ERROR);
			return false;
		}
		if (
			updatedVariant?.marketPrice === "" ||
			updatedVariant?.marketPrice === undefined
		) {
			showToast("Enter Market Price", TOAST_ERROR);
			return false;
		}
		if (
			updatedVariant?.discountPrice === "" ||
			updatedVariant?.discountPrice === undefined
		) {
			showToast("Enter discount price", TOAST_ERROR);
			return false;
		}
		if (
			updatedVariant?.thumbnailImage === "" ||
			updatedVariant?.thumbnailImage === undefined
		) {
			showToast("Select proudct image", TOAST_ERROR);
			return false;
		}
		if (updatedVariant?.stock === "" || updatedVariant?.stock === undefined) {
			showToast("Enter stock", TOAST_ERROR);
			return false;
		}
		if (
			updatedVariant?.images?.length === 0 ||
			updatedVariant?.images === undefined
		) {
			showToast("Select image", TOAST_ERROR);
			return false;
		}
		if (updatedVariant?.weight === "" || updatedVariant?.weight === undefined) {
			showToast("Enter weight", TOAST_ERROR);
			return false;
		}
		if (updatedVariant?.color === "" || updatedVariant?.color === undefined) {
			showToast("Enter color", TOAST_ERROR);
			return false;
		}
		if (updatedVariant?.upc === "" || updatedVariant?.upc === undefined) {
			showToast("Enter UPC", TOAST_ERROR);
			return false;
		}
		if (
			updatedVariant?.condition === "" ||
			updatedVariant?.condition === undefined
		) {
			showToast("Selection condition", TOAST_ERROR);
			return false;
		}
		if (
			updatedVariant?.deliveryChannel === "" ||
			updatedVariant?.deliveryChannel === undefined
		) {
			showToast("Select delivery channel", TOAST_ERROR);
			return false;
		}
		if (updatedVariant?.height === "" || updatedVariant?.height === undefined) {
			showToast("Enter height", TOAST_ERROR);
			return false;
		}
		if (updatedVariant?.width === "" || updatedVariant?.width === undefined) {
			showToast("Enter width", TOAST_ERROR);
			return false;
		}

		if (updatedVariant?.depth === "" || updatedVariant?.depth === undefined) {
			showToast("Enter depth", TOAST_ERROR);
			return false;
		}
		return true;
	};

	const handleUpdate = () => {
		if (!checkboxState.sellAbovePrice && !checkboxState.sellAtMinimumPrice) {
			// if no checkbox give error
			showToast(
				"Please select at least one selling price option.",
				TOAST_ERROR
			);
			return;
		}
		// Check if at least one image has been uploaded
		if (!thumbnailImage) {
			showToast("Please upload a thumbnail image.", TOAST_ERROR);
			// Set the error message
			setErrorMessage("Please upload a thumbnail image.");
			return; // Prevent the form from submitting
		}

		const updatedVariant = {
			name,
			sellingPrice,
			marketPrice,
			discountPrice,
			thumbnailImage,
			stock,
			images,
			weight,
			color,
			specifications,
			// height,
			// deliveryCharge,
			bulletPoints,
			upc,
			condition,
			deliveryChannel,
			sku,
			height,
			width,
			depth,
		};

		let requiredStatus = requiredCheck(updatedVariant);
		if (!requiredStatus) {
			return;
		}

		let apiUrl;
		let method;

		if (variantId) {
			apiUrl = `/v1/product/edit/variant/${productId}/${variantId}`;

			method = "post";
		} else {
			apiUrl = `/v1/product/add/variant/${productId}`;
			method = "post";
		}

		setisLoadingSubmit(true);

		axiosInstance[method](apiUrl, updatedVariant)
			.then((response) => {
				if (response.data.success) {
					showToast("Successfully updated the variant!", TOAST_SUCCESS);
					// Reset the error message if there was a previous error
					setErrorMessage("");
				} else {
					showToast(response.data.message, TOAST_SUCCESS);
					// Set the error message
					setErrorMessage(response?.data?.message);
					setcurrentMilun(
						response?.data?.payload?.variants[
							response?.data?.payload?.variants?.length - 1
						]?.SKU
					);
					setinfoPopupStatus(true);
					// navigate("/products/released");
				}
				setisLoadingSubmit(false);
			})
			.catch((error) => {
				showToast(
					`Error ${variantId ? "updating" : "adding"} variant: ${
						error.message
					}`,
					TOAST_ERROR
				);
				console.error(
					Error`${variantId ? "updating" : "adding"} variant:`,
					error
				);
				// Set the error message
				setErrorMessage(
					Error`${variantId ? "updating" : "adding"} variant: ${error.message}`
				);
				setisLoadingSubmit(false);
			});
	};
	const addSpecification = () => {
		setSpecifications([...specifications, { key: "", value: "" }]);
	};

	const deleteSpecification = (index) => {
		const newSpecifications = specifications.filter((_, i) => i !== index);
		setSpecifications(newSpecifications);
	};

	const handleSpecificationChange = (index, field, value) => {
		const updatedSpecifications = [...specifications];
		updatedSpecifications[index][field] = value;
		setSpecifications(updatedSpecifications);
	};
	const productSpecificationsKeys = Object.keys(productSpecifications);

	return (
		<>
			<CommonInfoDialog
				infoPopupStatus={infoPopupStatus}
				setinfoPopupStatus={setinfoPopupStatus}
			>
				<div className="w-full md:w-[500px] min-w-fit flex justify-center items-center flex-col space-y-5">
					<div class="font-bold text-center text-3xl mb-2 w-full flex justify-center items-center space-x-4">
						<span>
							<FaCheckCircle className="inline text-green-400 mr-2" />{" "}
						</span>
						Variant Added Successfully
					</div>
					<div class="text-gray-700 text-lg text-semibold w-full text-center">
						<span>Milun - </span>
						<span>{currentMilun}</span>
					</div>
					<button
						onClick={() => navigate("/products/released")}
						type="button"
						className="rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-600/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
					>
						Explore Products
					</button>
				</div>
			</CommonInfoDialog>
			<div className="bg-gray-100 min-h-screen p-8">
				{variantDetails && (
					<div className="mb-6">
						<h2 className="text-2xl font-bold mb-4">
							{variantId ? "Editing" : "Adding"} Variant of Product:{" "}
							{variantDetails?.productName}
						</h2>
					</div>
				)}
				<Card
					className={cn(styles.card, "bg-white p-6 rounded-xl shadow-md")}
					title="Variant"
					classTitle="title-green text-lg font-semibold mb-4"
				>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleUpdate();
						}}
						className={styles.description}
					>
						{!isNewProduct && (
							<>
								{/* Render other inputs for editing existing variants */}
								{/* ... */}

								<div className="mt-2">
									<label className="block text-lg font-semibold text-gray-700 mb-2">
										Variant Name
									</label>
									<textarea
										className="mt-1 block w-full p-2 rounded-lg border-2 border-gray-300 bg-white shadow-sm focus:border-pink-500 focus:ring focus:ring-pink-200 focus:ring-opacity-50 transition duration-300 ease-in-out hover:border-pink-300"
										rows="4"
										onChange={(e) => setName(e.target.value)}
										value={name || ""}
										maxLength={500}
										placeholder="Enter the product description here"
									></textarea>
									<p className="text-right text-sm text-gray-600 mt-1">
										{name?.length || 0}/500
									</p>
								</div>

								<button
									type="button"
									className="ml-4 py-1 px-3 mt-4 mb-4 bg-pink-600 text-sm text-white rounded-md hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50 shadow transition ease-in duration-300"
									onClick={() => setName(productName)}
								>
									Copy Product Name
								</button>
							</>
						)}

						<div className="flex flex-col mb-4">
							<label
								htmlFor="productDescription"
								className="mb-2 text-lg font-semibold"
							>
								Bullet Points (About the Product)
							</label>
							{bulletPoints?.map((point, index) => (
								<div
									key={index}
									className="flex flex-col lg:flex-row items-center mb-2"
								>
									<input
										type="text"
										value={point}
										onChange={(e) =>
											handleBulletPointChange(index, e.target.value)
										}
										className="p-4 lg:flex-grow border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
										placeholder="Enter bullet point"
									/>
									{index > 0 && (
										<button
											onClick={() => deleteBulletPoint(index)}
											className="lg:ml-2 mt-2 lg:mt-0 py-2 px-4 bg-red-600 text-white rounded-md hover:bg-red-700"
										>
											Delete
										</button>
									)}
								</div>
							))}
							{bulletPoints?.length < 20 && (
								<button
									type="button"
									onClick={addBulletPoint}
									className="py-2 px-4 bg-pink-600 text-white rounded-md hover:bg-pink-700"
								>
									+
								</button>
							)}
						</div>

						<div className="flex flex-col mb-4">
							<label className="mb-2 text-lg font-semibold">
								Specifications
							</label>
							{specifications?.map((spec, index) => (
								<div
									key={index}
									className="flex flex-col lg:flex-row items-center mb-2"
								>
									<select
										value={spec.key}
										onChange={(e) =>
											handleSpecificationChange(index, "key", e.target.value)
										}
										className="p-4 lg:flex-grow border rounded-md shadow-sm"
										required
									>
										<option value="" disabled>
											Select a specification
										</option>
										{productSpecificationsKeys?.map((key) => (
											<option key={key} value={key}>
												{key}
											</option>
										))}
									</select>
									{spec?.key && (
										<>
											{spec?.value === "other..." || selectOther[index] ? (
												<>
													<input
														type="text"
														onChange={(e) => {
															let temp = selectOther?.slice(); // Use slice to create a new array
															temp[index] = true;
															setSelectOther(temp);
															handleSpecificationChange(
																index,
																"value",
																e.target.value
															);
														}}
														className="p-4 lg:flex-grow border rounded-md shadow-sm ml-0 lg:ml-2 mt-2 lg:mt-0"
														placeholder="Other Value"
													/>
													<button
														type="button"
														onClick={() => {
															let temp = selectOther?.slice();
															temp[index] = false;
															setSelectOther(temp);
															// Reset the value to an empty string when switching away from 'Other...'
															handleSpecificationChange(index, "value", "");
														}}
													>
														<X
															color="#D81B60"
															className="hover:scale-125 active:scale-100 ease-in-out transition-all"
														/>
													</button>
												</>
											) : (
												<select
													value={specifications[index]?.value}
													onChange={(e) =>
														handleSpecificationChange(
															index,
															"value",
															e.target.value
														)
													}
													className="p-4 lg:flex-grow border rounded-md shadow-sm ml-0 lg:ml-2 mt-2 lg:mt-0"
													required
												>
													<option value="" disabled>
														Select spec
													</option>
													{productSpecifications[spec?.key]?.map((value) => (
														<option key={value} value={value}>
															{value}
														</option>
													))}
													<option value="other...">Other...</option>
												</select>
											)}
										</>
									)}

									{index > 0 && (
										<button
											onClick={() => deleteSpecification(index)}
											className="lg:ml-2 mt-2 lg:mt-0 py-2 px-4 bg-red-600 text-white rounded-md hover:bg-red-700"
										>
											Delete
										</button>
									)}
								</div>
							))}
							<button
								type="button"
								onClick={addSpecification}
								className="py-2 px-4 bg-pink-600 text-white rounded-md hover:bg-pink-700"
							>
								Add Specification
							</button>
						</div>

						<TextInput
							className={styles.field}
							label="UPC"
							name="upc"
							type="text"
							value={upc}
							onChange={(e) => setUpc(e.target.value)}
							required
						/>
						<TextInput
							className={styles.field}
							label="SKU"
							name="sku"
							type="text"
							value={sku}
							onChange={(e) => setSku(e.target.value)}
						/>
						<div className="mb-4">
							<label
								htmlFor="condition"
								className="block text-sm mt-4 font-bold  text-gray-700"
							>
								Condition
							</label>
							<select
								id="condition"
								value={condition}
								onChange={(e) => setCondition(e.target.value)}
								className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
							>
								<option value="NEW">New</option>
								<option value="REFURBISHED">Refurbished</option>
								{/* <option value="USED">Used</option> */}
							</select>
						</div>
						<div>
							<label
								htmlFor="deliveryChannel"
								className="block text-sm font-bold  text-gray-700"
							>
								Delivery Channel
							</label>
							<select
								id="deliveryChannel"
								value={deliveryChannel}
								onChange={(e) => setDeliveryChannel(e.target.value)}
								className="mt-1 mb-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
							>
								<option value="MAKEITLESS">By Make It Less</option>
								<option value="MERCHANT">By Merchant</option>
							</select>
						</div>

						<TextInput
							className={styles.field}
							label="Market Price"
							name="marketPrice"
							type="number"
							value={marketPrice}
							onChange={(e) => setMarketPrice(e.target.value)}
							required
						/>

						<TextInput
							className={styles.field}
							label="Make It Less Price"
							name="discountPrice"
							type="number"
							value={discountPrice}
							onChange={(e) => setDiscountPrice(e.target.value)}
							required
						/>

						<TextInput
							className={styles.field}
							label="Minimum Selling Price"
							name="sellingPrice"
							type="number"
							value={sellingPrice}
							onChange={(e) => setSellingPrice(e.target.value)}
							required
						/>
						<div className="mt-4 mb-4">
							<p className="text-lg font-semibold text-pink-600">
								Choose how you want to sell your product
							</p>
							<div className="flex items-center mt-2">
								<input
									type="checkbox"
									id="sellAbovePrice"
									name="sellAbovePrice"
									className="w-4 h-4 accent-pink-600 border-gray-300 rounded focus:ring-pink-500"
									checked={checkboxState?.sellAbovePrice}
									onChange={() => handleCheckboxChange("sellAbovePrice")}
								/>
								<label
									htmlFor="sellAbovePrice"
									className="ml-2 text-sm font-medium text-gray-700"
								>
									I want the Minimum Selling Price to be fixed.
								</label>
							</div>
							<div className="flex items-center mt-2">
								<input
									type="checkbox"
									id="sellAtMinimumPrice"
									name="sellAtMinimumPrice"
									className="w-4 h-4 accent-pink-600 border-gray-300 rounded focus:ring-pink-500"
									checked={checkboxState?.sellAtMinimumPrice}
									onChange={() => handleCheckboxChange("sellAtMinimumPrice")}
								/>
								<label
									htmlFor="sellAtMinimumPrice"
									className="ml-2 text-sm font-medium text-gray-700"
								>
									I want the Minimum Selling Price to vary.
								</label>
							</div>
						</div>

						<TextInput
							className={styles.field}
							label="Quantity"
							name="stock"
							type="number"
							value={stock}
							onChange={(e) => setStock(e.target.value)}
							required
						/>

						<TextInput
							className={styles.field}
							label="Color"
							name="color"
							type="text"
							value={color}
							onChange={(e) => setColor(e.target.value.toUpperCase())} // Convert input to uppercase
							required
						/>

						<TextInput
							className={styles.field}
							label="Weight (In Grams*)"
							name="weight "
							type="text"
							value={weight}
							onChange={(e) => setWeight(e.target.value)}
							required
						/>

						<div className="flex mt-2 space-x-4 mb-4">
							<TextInput
								className={styles.field}
								label="Height (cm)"
								name="height"
								type="number"
								value={height}
								onChange={(e) => setHeight(e.target.value)}
								required
							/>
							<TextInput
								className={styles.field}
								label="Width (cm)"
								name="width"
								type="number"
								value={width}
								onChange={(e) => setWidth(e.target.value)}
								required
							/>
							<TextInput
								className={styles.field}
								label="Length (cm)"
								name="depth"
								type="number"
								value={depth}
								onChange={(e) => setDepth(e.target.value)}
								required
							/>
						</div>

						{/* <TextInput
						className={styles.field}
						label="Delhivery Charges (AED)"
						name="deliveryCharge"
						type="number"
						value={deliveryCharge}
						onChange={(e) => setDeliveryCharge(e.target.value)}
						required
					/> */}

						<span className="text-xl font-bold">Upload Main Image</span>
						<GenericImageUpload
							className={styles.card}
							maxImages={1} // Only one image for thumbnail
							images={thumbnailImage ? [thumbnailImage] : []}
							onImageUpload={(image) => {
								console.log("Thumbnail Image uploaded:", image);
								setThumbnailImage(image);
							}}
							onImageRemove={() => {
								setThumbnailImage("");
							}}
						>
							<div className={styles.imageContainer}>
								{thumbnailImage && (
									// eslint-disable-next-line jsx-a11y/img-redundant-alt
									<img
										src={thumbnailImage}
										alt="Thumbnail Image"
										style={{
											maxWidth: "1000px",
											maxHeight: "350px",
											width: "100%",
											height: "auto",
										}}
									/>
								)}
							</div>
						</GenericImageUpload>

						<div className="flex pt-5">
							<span className="text-xl font-bold">Upload Images</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6 text-blue-500 hover:text-blue-700 cursor-pointer"
								onClick={() =>
									handleModalOpen(
										<div className="p-4 bg-white shadow-lg rounded-lg">
											<h2 className="text-lg font-semibold">
												Second Image - Detail Page Focus:
											</h2>
											<p className="text-sm text-gray-600">
												Close-up highlighting quality and unique features.
											</p>

											<h2 className="text-lg font-semibold mt-2">
												Third Image - Bullet Points Visualization:
											</h2>
											<p className="text-sm text-gray-600">
												Simple, visual representation of key benefits.
											</p>

											<h2 className="text-lg font-semibold mt-2">
												Fourth Image - Refurbishing Process If It Is:
											</h2>
											<p className="text-sm text-gray-600">
												Showcases the stages and quality of refurbishment.
											</p>

											<h2 className="text-lg font-semibold mt-2">
												Fifth Image - Specification Overview:
											</h2>
											<p className="text-sm text-gray-600">
												Technical details with clear layout, using charts or
												diagrams.
											</p>

											<h2 className="text-lg font-semibold mt-2">
												Sixth to Eighth Images - Detailed Exploration:
											</h2>
											<ul className="list-disc list-inside text-sm text-gray-600">
												<li>Sixth: Lifestyle shot for real-world use.</li>
												<li>
													Seventh: Focus on accessories or complementary items.
												</li>
												<li>
													Eighth: Special features and unique design elements.
												</li>
											</ul>
										</div>
									)
								}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
								/>
							</svg>
						</div>

						<GenericImageUpload
							className={styles.card}
							maxImages={8}
							images={images}
							onImageUpload={(image) => {
								console.log("Image uploaded:", image);
								setImages([...images, image]);
							}}
							onImageRemove={(removedImage) => {
								const updatedImages = images.filter(
									(image) => image !== removedImage
								);
								setImages(updatedImages);
							}}
							setSortedImages={(sortedImages) => {
								setImages(sortedImages);
							}}
						>
							<div className={`${styles.imageContainer}`}>
								{images?.map((image, index) => (
									<div
										key={index}
										className={index % 2 === 0 ? "evenImage" : "oddImage"}
									>
										<img
											src={image}
											alt={`Uploaded Image ${index + 1}`}
											style={{
												maxWidth: "1000px",
												maxHeight: "350px",
												width: "100%",
												height: "auto",
											}}
										/>
									</div>
								))}
							</div>
						</GenericImageUpload>

						{/* ... (other form fields similar to above for color, size, etc.) */}

						<div className="mt-6 flex flex-col sm:flex-row justify-end">
							{isLoadingSubmit ? (
								<button
									type="button"
									disabled
									className={cn(
										"bg-pink-700 px-6 py-4 text-white hover:bg-pink-600 rounded-xl",
										styles.button,
										{ "opacity-50 cursor-not-allowed": !thumbnailImage } // Add styles for disabled state
									)}
								>
									<RotatingLines
										strokeColor="white"
										strokeWidth="5"
										animationDuration="0.75"
										width="25"
										visible={true}
									/>
								</button>
							) : (
								<button
									type="submit"
									disabled={!thumbnailImage} // Disable the button if no thumbnail image is uploaded
									className={cn(
										"bg-pink-700 px-6 py-4 text-white hover:bg-pink-600 rounded-xl",
										styles.button,
										{ "opacity-50 cursor-not-allowed": !thumbnailImage } // Add styles for disabled state
									)}
								>
									{variantId ? "Update Variant" : "Add Variant"}
								</button>
							)}
						</div>
					</form>
				</Card>
				{/* ------------------------------------------------------------USe for Madal-------------------------------------------------------- */}
				<InfoModal
					isOpen={isModalOpen}
					content={modalContent}
					onClose={() => setIsModalOpen(false)}
				/>
			</div>
		</>
	);
};

export default VariantEdit;
