import React, { useState, useEffect } from "react";
import styles from "./Market.module.sass";
import Row from "./Row";
import axiosInstance from "../../../utils/axios";
import { GET_ALL_PRODUCTS } from "../../../utils/api";
import Loader from "../../../components/Loader";

const Market = () => {
	const [chooseAll, setСhooseAll] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState([]);
	const [products, setProducts] = useState([]); // State to store fetched products
	const [loading, setLoading] = useState(true); // State to handle loading state
	const [error, setError] = useState(null); // State to handle any errors

	useEffect(() => {
		// Fetch products from backend when component mounts
		axiosInstance
			.get(GET_ALL_PRODUCTS)
			.then((response) => {
				setProducts(response.data); // Assuming the backend returns an array of products
				setLoading(false); // Set loading to false once data is fetched
			})
			.catch((err) => {
				console.error("Error fetching products:", err);
				setError(err); // Set error state
				setLoading(false); // Set loading to false once data is fetched
			});
	}, []); // Empty dependency array means this useEffect runs once when component mounts

	const handleChange = (id) => {
		if (selectedFilters.includes(id)) {
			setSelectedFilters(selectedFilters.filter((x) => x !== id));
		} else {
			setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
		}
	};

	if (loading) {
		return <Loader />; // Display loader while fetching data
	}

	if (error) {
		return <div>Error fetching products. Please try again later.</div>; // Display error message
	}

	return (
		<div className={styles.market}>
			<div className={styles.table}>
				<div className={styles.row}>
					<div className={styles.col}></div>
					{/* <div className={styles.col}>Product</div>
          <div className={styles.col}>Brand </div>
          <div className={styles.col}>Description</div>
          <div className={styles.col}>Edit</div>
          <div className={styles.col}>Delete</div>
          <div className={styles.col}>Add Variant</div> */}
				</div>
				{Array.from({ length: 1 }).map(
					(
						_,
						index // Assuming you want to render 10 rows for now
					) => (
						<Row
							key={index}
							value={selectedFilters.includes(index)} // Assuming index as id for now
							onChange={() => handleChange(index)}
						/>
					)
				)}
			</div>
		</div>
	);
};

export default Market;
