import React, { useState } from "react";
import styles from "./Market.module.sass";
import Row from "./Row";

const Market = () => {
	const [chooseAll, setСhooseAll] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState([]);

	const handleChange = (id) => {
		if (selectedFilters.includes(id)) {
			setSelectedFilters(selectedFilters.filter((x) => x !== id));
		} else {
			setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
		}
	};

	return (
		<div className={styles.market}>
			<div className={styles.table}>
				<div className={styles.row}>
					<div className={styles.col}></div>
					<div className={styles.col}>Product</div>
					<div className={styles.col}>Stock </div>
					<div className={styles.col}>Market Price</div>
					<div className={styles.col}>Edit</div>
					<div className={styles.col}>Delete</div>
					<div className={styles.col}>Add Variant</div>
				</div>
				{Array.from({ length: 1 }).map(
					(
						_,
						index // Assuming you want to render 10 rows for now
					) => (
						<Row
							key={index}
							value={selectedFilters.includes(index)} // Assuming index as id for now
							onChange={() => handleChange(index)}
						/>
					)
				)}
			</div>
		</div>
	);
};

export default Market;
