import React, { useState } from "react";
import { RESET_PASSWORD } from "../../utils/api";
import axiosInstance from "../../utils/axios";
import { TOAST_SUCCESS, showToast, TOAST_ERROR } from "../../utils/constants";
import { RotatingLines } from "react-loader-spinner";
import {  useNavigate } from "react-router-dom";


const ChangePassword = () => {
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
	
		// Check if new password and confirm password are the same
		if (newPassword !== confirmPassword) {
			showToast("New password and confirm password do not match.", TOAST_ERROR);
			setLoading(false); // Stop loading here as well since we're not proceeding with the request
			return;
		}
	
		try {
			const response = await axiosInstance.post(RESET_PASSWORD, {
				password,
				newPassword,
				confirmPassword,
			});
	
			if (response?.data?.error) {
				showToast(response?.data?.message, TOAST_ERROR);
			} else {
				showToast(response?.data?.message, TOAST_SUCCESS);
				navigate("/");
			}
		} catch (error) {
			console.error("Error:", error.response ? error.response.data : error.message);
			showToast("An error occurred while changing the password.", TOAST_ERROR);
		} finally {
			setLoading(false); // This ensures that loading is stopped regardless of the outcome
		}
	};
	

	return (
		<div className="flex flex-col items-center justify-center p-6 bg-gray-100 ">
			<div className="w-full max-w-md p-8 space-y-3 rounded-xl bg-white shadow-lg">
				<h1 className="text-2xl font-bold text-center">Change Password</h1>
				<form className="space-y-6" onSubmit={handleSubmit}>
					<div>
						<label
							htmlFor="current-password"
							className="text-sm font-medium text-gray-700"
						>
							Current Password
						</label>
						<input
							type="password"
							id="current-password"
							className="w-full p-3 mt-2 border rounded-md focus:outline-none focus:ring focus:border-pink-300"
							placeholder="Enter current password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div>
						<label
							htmlFor="new-password"
							className="text-sm font-medium text-gray-700"
						>
							New Password
						</label>
						<input
							type="password"
							id="new-password"
							className="w-full p-3 mt-2 border rounded-md focus:outline-none focus:ring focus:border-pink-300"
							placeholder="Enter new password"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
						/>
					</div>
					<div>
						<label
							htmlFor="confirm-password"
							className="text-sm font-medium text-gray-700"
						>
							Confirm New Password
						</label>
						<input
							type="password"
							id="confirm-password"
							className="w-full p-3 mt-2 border rounded-md focus:outline-none focus:ring focus:border-pink-300"
							placeholder="Confirm new password"
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
					</div>
					<button
    type="submit"
    disabled={loading}
    className="w-full p-3 text-white bg-pink-600 rounded-md hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50 flex justify-center items-center"
>
    {loading ? (
        <div className="flex justify-center items-center">
            <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="25"
                visible={true}
            />
        </div>
    ) : (
        "Change Password"
    )}
</button>


				</form>
			</div>
		</div>
	);
};

export default ChangePassword;
