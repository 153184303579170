import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useNavigation } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios"; // Import Axios
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
	ADMIN_SIGNIN,
	SELLER_ACCOUNT_API,
	SELLER_SIGNIN,
} from "../../utils/api";
import {
	PDF,
	showToast,
	SOMETHING_WENT_WRONG,
	TOAST_SUCCESS,
} from "../../utils/constants";
import { logger } from "../../utils/logger";
import axiosInstance from "../../utils/axios";
import { setSession } from "../../utils/jwt";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAdmin } from "../../utils";
import { _initSendOTP, _verifyOTP } from "../../network/auth/init";
import GenericImageUpload from "../../screens/NewProduct/GenericImageUpload";
import { RotatingLines } from "react-loader-spinner";

const SignIn = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const heightWindow = use100vh();

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	const [customerCareEmail, setCustomerCareEmail] = useState("");
	const [customerCareNumber, setCustomerCareNumber] = useState("");
	const [profilePic, setProfilePic] = useState("");
	const [address, setAddress] = useState("");
	const [businessName, setBusinessName] = useState("");
	const [bannerUrl, setBannerUrl] = useState(""); //TODO from public website
	// for uploading file
	const [identityProof, setIdentityProof] = useState(null);
	const [commercialLicense, setCommercialLicense] = useState(null);
	// for commercial license expiry date
	const [commercialLicenseExpiryDate, setCommercialLicenseExpiryDate] =
		useState(new Date());
	const nav = useNavigate();
	const _handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		// Check if mandatory fields are filled
		if (!identityProof || !commercialLicense) {
			showToast("Please upload all required documents.");
			setLoading(false);
			return;
		}
		try {
			const { data } = await axiosInstance.post(SELLER_ACCOUNT_API, {
				firstName,
				lastName,

				customerCareEmail,
				customerCareNumber,
				address,
				businessName,
				bannerUrl,
				identityProof,
				commercialLicense,
				commercialLicenseExpiryDate,
			});
			if (data?.error) return showToast(data?.message || SOMETHING_WENT_WRONG);
			console.log({
				data: data,
			});
			showToast(data?.message, TOAST_SUCCESS);
			nav("/approval");
		} catch (error) {
			console.log({ error });
			showToast(error?.response?.message || SOMETHING_WENT_WRONG);
		}
		setLoading(false);
	};
	return (
		<div className={styles.login} style={{ minHeight: heightWindow }}>
			<div className={styles.wrapper}>
				<Link to="/">
					<img
						className="h-26 w-64 text-center justify-center"
						src="https://res.cloudinary.com/dcpte972l/image/upload/v1692191670/Holiday_Vacation_Travel_Agent_Promotion_Banner_Landscape_wvx6bq.png"
						alt=""
					/>
				</Link>

				<div className={cn("h2", styles.title)}>Create Profile</div>

				<div className={styles.body}>
					<form
						onSubmit={_handleSubmit}
						className="flex flex-wrap flex-col  justify-center gap-4"
					>
						<TextInput
							className={styles.field}
							name="firstName"
							type="firstName"
							placeholder="First Name"
							required
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<TextInput
							className={styles.field}
							name="lastName"
							type="text"
							placeholder="Last Name"
							required
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							style={{ flexShrink: 0 }}
						/>

						<TextInput
							className={styles.field}
							type="text"
							placeholder="Address"
							required
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							style={{ flexShrink: 0 }}
						/>

						<TextInput
							className={styles.field}
							name="customerCareEmail"
							type="email"
							placeholder="Customer Care Email"
							required
							value={customerCareEmail}
							onChange={(e) => setCustomerCareEmail(e.target.value)}
							style={{ flexShrink: 0 }}
						/>

						<TextInput
							className={styles.field}
							name="customerCareNumber"
							type="number"
							placeholder="Customer Care Number"
							required
							value={customerCareNumber}
							onChange={(e) => setCustomerCareNumber(e.target.value)}
							style={{ flexShrink: 0 }}
						/>

						<TextInput
							className={styles.field}
							name="businessName"
							type="text"
							placeholder="Business Name"
							required
							value={businessName}
							onChange={(e) => setBusinessName(e.target.value)}
							style={{ flexShrink: 0 }}
						/>

						<div className="w-full flex flex-col md:flex-row justify-center md:justify-between bg-gray-100">
							<div className="w-full md:w-1/2">
								<div className="flex flex-col justify-center items-center border p-4 rounded-lg">
									<span className="text-lg font-semibold mb-4 block text-center">
										Emirates ID or Passport
									</span>
									<span className="text-sm font-medium mb-2">
										(Please Upload PDF File)
									</span>
									<GenericImageUpload
										type={"PDF"}
										assetType={PDF}
										className={styles.card}
										maxImages={1}
										images={identityProof ? [identityProof] : []}
										onImageUpload={(image) => {
											console.log("Thumbnail Image uploaded:", image);
											setIdentityProof(image);
										}}
										onImageRemove={() => {
											setIdentityProof("");
										}}
									>
										<div className={styles.imageContainer}>
											{identityProof && (
												<img src={identityProof} alt="Thumbnail Image" />
											)}
										</div>
									</GenericImageUpload>
								</div>
							</div>
							<div className="w-full md:w-1/2">
								<div className="flex flex-col justify-center items-center border bg-gray-100 p-4 rounded-lg">
									<span className="text-lg font-semibold mb-4 block text-center">
										Commercial License
									</span>
									<span className="text-sm font-medium mb-2">
										(Please Upload PDF File)
									</span>
									<GenericImageUpload
										type={"PDF"}
										assetType={PDF}
										className={styles.card}
										maxImages={1}
										images={commercialLicense ? [commercialLicense] : []}
										onImageUpload={(image) => {
											console.log("Thumbnail Image uploaded:", image);
											setCommercialLicense(image);
										}}
										onImageRemove={() => {
											setCommercialLicense("");
										}}
									>
										<div className={styles.imageContainer}>
											{commercialLicense && (
												<img src={commercialLicense} alt="Thumbnail Image" />
											)}
										</div>
									</GenericImageUpload>
									<hr className="my-4 border-t border-gray-300 w-full" />
									<div className="mt-6 w-1/2">
										<label className="block text-lg font-semibold mb-2 text-center">
											Commercial License Expiry Date{" "}
											<span className="text-red-500">*</span>
										</label>
										<ReactDatePicker
											selected={commercialLicenseExpiryDate}
											onChange={(date) => setCommercialLicenseExpiryDate(date)}
											dateFormat="dd/MM/yyyy"
											placeholderText="DD/MM/YYYY"
											className="form-input block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="w-full bg-gray-100">
							<div className="flex justify-center border  dark:bg-transparent shadow-md rounded-lg p-4">
								<div className="flex flex-col items-center">
									<span className="text-lg font-semibold mb-4 block text-center">
										Banner Image
									</span>
									<span className="text-sm font-medium mb-2">
										(Please Upload Image File)
									</span>
									<div className="mb-4 flex justify-center items-center">
										<GenericImageUpload
											type={"Image"}
											className={styles.card}
											maxImages={1}
											images={bannerUrl ? [bannerUrl] : []}
											onImageUpload={(image) => {
												console.log("Thumbnail Image uploaded:", image);
												setBannerUrl(image);
											}}
											onImageRemove={() => {
												setBannerUrl("");
											}}
										>
											<div className={styles.imageContainer}>
												{bannerUrl && (
													<img src={bannerUrl} alt="Thumbnail Image" />
												)}
											</div>
										</GenericImageUpload>
									</div>
								</div>
							</div>
						</div>

						<div className="w-full flex justify-center">
							<button
								className="bg-pink-700 px-6 py-4 text-white hover:bg-pink-600 rounded-xl"
								id="sign-in-button"
								type="submit"
							>
								Create Profile
							</button>
						</div>
					</form>
					<div className={styles.note}>
						This site is protected by reCAPTCHA and the Google Privacy Policy.
					</div>
					<div>
						Don’t have an account?{" "}
						<Link className=" text-pink-700 hover:text-pink-600" to="/sign-up">
							Sign up
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
