import { Routes, Route, redirect, useNavigate } from "react-router-dom";
import "./styles/app.sass";
import { ProductProvider } from "./screens/NewProduct/ProductContext";
import Page from "./components/Page";
import Home from "./screens/Home";
import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import AddVariant from "./pages/AddVariant";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released";
import VariantList from "./pages/VariantList";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./screens/Customers";
import CustomerList from "./screens/CustomerList";
import Orders from "./screens/Promote";
import OrderDetail from "./screens/Promote/RecentPost/Orderdetails";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Earning from "./screens/Earning";
import Refunds from "./screens/Refunds";
import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
import Shop from "./screens/Shop";
import PageList from "./screens/PageList";
import VariantBids from "./screens/ProductDetail/VariantBid";
import { Provider, useDispatch } from "react-redux";
import store from "./store";
import {
	logOut,
	setAuthentication,
	setFullProfileData,
	setProfleData,
	setUser,
} from "./store/globalSlice";
import { useEffect } from "react";
import { setSession } from "./utils/jwt";
import { SOMETHING_WENT_WRONG, TOKEN, showToast } from "./utils/constants";
import { useSelector } from "react-redux";
import PrivateRoutes from "./components/PrivateRoutes";
import { LandinngPage } from "./screens/LandingPage/Index";
import axiosInstance from "./utils/axios";
import { onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { auth } from "./network/auth/init";
import axios from "axios";
import { SELLER_PROFILE } from "./utils/api";
import CreateProfile from "./screens/CreateProfile";
import ProductDetail from "./screens/ProductDetail";
import Swal from "sweetalert2";
import RequestCategory from "./screens/RequestCategory";
import AllBids from "./screens/AllBids";
import TermsAndConditions from "./pages/termsandconditions";
import FeeAndCommisions from "./pages/fee-and-commisions";
import Approval from "./screens/Aprroval";
import * as Sentry from "@sentry/react";

import Welcome from "./screens/Welcome";
import Loader from "./components/Loader";
import Page404 from "./screens/Page404";
import { logger } from "./utils/logger";
import ContactAdmin from "./screens/ContactAdmin";
import ChangePassword from "./screens/ChangePassword";
import LoadingComponent from "./components/LoadingComponent";
localStorage.setItem("darkMode", false);
Sentry.init({
	dsn: "https://c3123ae739141c20e65e3c9b35698423@o4506233393577984.ingest.sentry.io/4506233423462400",
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
	const navigate = useNavigate();
	const { isAuthenticated, isLoading } = useSelector((state) => state.global);
	const dispatch = useDispatch();

	const _getProfile = async () => {
		try {
			const { data } = await axiosInstance.get(SELLER_PROFILE);
			console.log({ data });
			// if (data?.error) return showToast(data?.message || SOMETHING_WENT_WRONG);

			if (!data.isProfile) {
				dispatch(setProfleData(data?.payload));

				console.log("No Profile");
				// redirect("/profile/create");
				// navigate("/profile/create", { replace: true });
				Swal.fire({
					title: "Need to create profile first.",
					showDenyButton: false,
					showCancelButton: false,
					confirmButtonText: "Create Profile",
					showConfirmButton: true,
					// denyButtonText: `Don't save`,
				});
				// return showToast("Please create profile");
			} else {
				if (data?.error) {
					Swal.fire({
						title: data?.message,
						showDenyButton: false,
						showCancelButton: false,
						showConfirmButton: true,
						confirmButtonText: "Ok",
						// denyButtonText: `Don't save`,
					});
				}
				dispatch(setFullProfileData(data));
				dispatch(setProfleData(data?.payload));
			}
			// TODO
		} catch (error) {
			console.log({ error });
		}
	};

	const _checkAuthentication = async () => {
		const token = localStorage.getItem(TOKEN);
		logger.log({ token });
		if (token) {
			dispatch(setAuthentication(true));
			setSession(token);
			_getProfile();
		} else {
			dispatch(setAuthentication(false));
		}
	};
	useEffect(() => {
		_checkAuthentication();
	}, []);
	useEffect(() => {
		if (isLoading) return;
		if (isAuthenticated) {
			_getProfile();
		}
	}, [isAuthenticated]);
	if (isLoading) return <LoadingComponent />;
	// useEffect(() => {
	// 	if (isLoading) return;
	// 	console.log("RUNNN");
	// 	logger.log({
	// 		isAuthenticated,
	// 	});
	// 	if (isAuthenticated) {
	// 		_getProfile();
	// 	} else {
	// 		navigate("/login");
	// 	}
	// 	// if (auth.currentUser === null) {
	// 	// 	dispatch(setAuthentication(false));
	// 	// 	return;
	// 	// }
	// 	// onIdTokenChanged(auth, async (user) => {
	// 	// 	console.log({ user });

	// 	// 	if (user) {
	// 	// 		console.log({ user });
	// 	// 		const lastSignInTime = new Date(user.metadata.lastSignInTime);
	// 	// 		const lastSignInTimeTimeStamp = Math.round(
	// 	// 			lastSignInTime.getTime() / 1000
	// 	// 		);
	// 	// 		const yesterdayTimeStamp =
	// 	// 			Math.round(new Date().getTime() / 1000) - 24 * 3600;
	// 	// 		if (lastSignInTimeTimeStamp < yesterdayTimeStamp) {
	// 	// 			await auth.signOut();
	// 	// 			localStorage.removeItem(TOKEN);
	// 	// 			dispatch(logOut());
	// 	// 			return false;
	// 	// 		}

	// 	// 		const uid = user.uid;

	// 	// 		// const token = await auth.currentUser.getIdToken();
	// 	// 		console.log({ token: user.accessToken });
	// 	// 		// setting token in local storage
	// 	// 		localStorage.setItem(TOKEN, user.accessToken);

	// 	// 		// axios.defaults.headers.common = {
	// 	// 		// 	Authorization: `Bearer AED{user.accessToken}`,
	// 	// 		// };
	// 	// 		setSession(user.accessToken);
	// 	// 		dispatch(setUser(user));
	// 	// 		dispatch(setAuthentication(true));
	// 	// 		// ...
	// 	// 	} else {
	// 	// 		// User is signed out
	// 	// 		// ...
	// 	// 		// navigate("/login");
	// 	// 		dispatch(setAuthentication(false));
	// 	// 	}
	// 	// });
	// }, [isAuthenticated]);

	if (isLoading) return <Loader />;
	return (
		<Routes>
			<Route path="/">
				{/* <Route element={<PrivateRoutes />}> */}
				<Route
					path="/"
					element={
						<Page title="Dashboard">
							<Home />
						</Page>
					}
				/>
				<Route path="/termsandconditions" element={<TermsAndConditions />} />
				<Route path="/fee-and-commisions" element={<FeeAndCommisions />} />
				{/* </Route> */}

				{/* <Route path="/" element={<LandinngPage />} /> */}
				<Route
					path="products/dashboard"
					element={
						<Page title="Products dashboard">
							<ProductsDashboard />
						</Page>
					}
				/>
				<Route
					path="products/add"
					element={
						<Page title="New product">
							<NewProduct />
						</Page>
					}
				/>
				<Route
					path="/products/add/edit/:productId"
					element={
						<Page title="Edit Product">
							<NewProduct />
						</Page>
					}
				/>
				<Route
					path="/variant/:variantId/bids"
					element={
						<Page title="Bids">
							<VariantBids />
						</Page>
					}
				/>

				<Route
					path="/variant-edit/:productId/:variantId"
					element={
						<Page title="Edit Variant">
							<AddVariant />
						</Page>
					}
				/>
				<Route
					path="productdetails/:id"
					element={
						<Page title="Product Detail">
							<ProductDetail />
						</Page>
					}
				/>

				<Route
					path="variants/all"
					element={
						<Page title="All Variants">
							<VariantList />
						</Page>
					}
				/>

				<Route
					path="/variant/dashboard"
					element={
						<Page title="Variant Dashboard">
							<ProductsDashboard />
						</Page>
					}
				/>

				<Route
					path="/variant/add/:productId"
					element={
						<Page title="Variant">
							<AddVariant />
						</Page>
					}
				/>
				<Route
					path="productdetails/:id"
					element={
						<Page title="Product Detail">
							<ProductDetail />
						</Page>
					}
				/>

				<Route
					path="variants/all"
					element={
						<Page title="All Variants">
							<VariantList />
						</Page>
					}
				/>

				<Route
					path="/variant/dashboard"
					element={
						<Page title="Variant Dashboard">
							<ProductsDashboard />
						</Page>
					}
				/>

				<Route
					path="products/drafts"
					element={
						<Page title="Drafts">
							<Drafts />
						</Page>
					}
				/>
				<Route path="profile/create" element={<CreateProfile />} />
				<Route
					path="products/released"
					element={
						<Page title="Released">
							<Released />
						</Page>
					}
				/>
				<Route
					path="products/comments"
					element={
						<Page title="Comments">
							<Comments />
						</Page>
					}
				/>
				<Route
					path="products/scheduled"
					element={
						<Page title="Scheduled">
							<Scheduled />
						</Page>
					}
				/>
				<Route
					path="customers/overview"
					element={
						<Page title="Customers">
							<Customers />
						</Page>
					}
				/>
				<Route
					path="customers/customer-list"
					element={
						<Page title="Customer list">
							<CustomerList />
						</Page>
					}
				/>
				<Route
					path="shop"
					element={
						<Page wide>
							<Shop />
						</Page>
					}
				/>
				<Route
					path="income/earning"
					element={
						<Page title="Earning">
							<Earning />
						</Page>
					}
				/>
				<Route
					path="income/refunds"
					element={
						<Page title="Refunds">
							<Refunds />
						</Page>
					}
				/>
				<Route
					path="income/payouts"
					element={
						<Page title="Payouts">
							<Payouts />
						</Page>
					}
				/>
				<Route
					path="income/statements"
					element={
						<Page title="Statements">
							<Statements />
						</Page>
					}
				/>
				<Route
					path="Orders"
					element={
						<Page title="Orders">
							<Orders />
						</Page>
					}
				/>
				<Route
					path="/order/detail/:id"
					element={
						<Page title="OrderDetail">
							<OrderDetail />
						</Page>
					}
				/>

				<Route path="/variant/add/:productId" element={<AddVariant />} />

				<Route
					path="requestcategory"
					element={
						<Page title="Request Category">
							<RequestCategory />
						</Page>
					}
				/>
				<Route
					path="documentupload"
					element={
						<Page title="Documents Upload">
							<AllBids />
						</Page>
					}
				/>
				<Route
					path="contactadmin"
					element={
						<Page title="Contact Admin">
							<ContactAdmin />
						</Page>
					}
				/>
				<Route
					path="changepassword"
					element={
						<Page title="Change Password">
							<ChangePassword />
						</Page>
					}
				/>

				{/* <Route
					path="notification"
					element={
						<Page title="Notification">
							<Notification />
						</Page>
					}
				/> */}
				<Route
					path="settings"
					element={
						<Page title="Settings">
							<Settings />
						</Page>
					}
				/>
				<Route
					path="upgrade-to-pro"
					element={
						<Page title="Upgrade to Pro">
							<UpgradeToPro />
						</Page>
					}
				/>
				<Route
					path="message-center"
					element={
						<Page title="Message center">
							<MessageCenter />
						</Page>
					}
				/>
				<Route
					path="explore-creators"
					element={
						<Page title="Explore creators">
							<ExploreCreators />
						</Page>
					}
				/>
				<Route
					path="affiliate-center"
					element={
						<Page title="Affiliate center">
							<AffiliateCenter />
						</Page>
					}
				/>
				<Route path="sign-up" element={<SignUp />} />
				<Route path="login" element={<SignIn />} />
				{/* <Route path="adminsign-up" element={<AdminSignup />} /> */}
				{/* <Route path="login" element={<AdminSignin />} /> */}
				<Route path="pagelist" element={<PageList />} />
			</Route>
			<Route path="/approval" element={<Approval />} />

			{/*  Changes made by Aquib */}
			<Route path="/welcome" element={<Welcome />} />
			<Route path="/*" element={<Page404 />} />
		</Routes>
	);
}

function Main() {
	return (
		<Provider store={store}>
			<ProductProvider>
				<App />
			</ProductProvider>
		</Provider>
	);
}

export default Main;
