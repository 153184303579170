import React, { useState } from "react";
import cn from "classnames";
import styles from "./Shop.module.sass";
import Profile from "./Profile";
import Settings from "./Settings";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Filters from "../../components/Filters";
import Product from "../../components/Product";
import Follower from "./Follower";
import axiosInstance from "../../utils/axios";
import { SELLER_PROFILE } from "../../utils/api";
import { useSelector } from "react-redux";

// data
// import { products } from "../../mocks/products";
// import { followers } from "../../mocks/followers";

const navigation = ["Products", "Followers", "Following"];
const intervals = ["Most recent", "Most new", "Most popular"];

const Shop = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [sorting, setSorting] = useState(intervals[0]);
	const [seller, setSeller] = useState(null);
	const {
		isAuthenticated,
		isLoading,
		isProfile,
		isLoadingForProfileCheck,
		profileData,
		user,
		profile,
	} = useSelector((state) => state.global);
	console.log({
		getProfilegetProfile: "getProfilegetProfile",
		isAuthenticated,
		isLoading,
		isProfile,
		isLoadingForProfileCheck,
		profileData,
		user,
		profile,
	});
	function getProfile() {
		// axiosInstance.get(SELLER_PROFILE).then((res) => {
		// 	setSeller(res?.data?.payload);
		// });
	}
	useState(getProfile, []);

	//   return(
	//     <>
	//     <div className="w-[100%]">
	//       <h1 className="text-2xl font-bold mb-4">Seller Panel Overview</h1>

	//       <div className="grid grid-cols-12 w-full bg-red-100 space-x-2 py-10 px-4">
	//         {/* Card 1: Total Products */}
	//         <div className="col-span-12 md:col-span-3 bg-white p-4 py-6 rounded-md shadow-md">
	//           <h2 className="text-xl font-bold mb-2"><span className="bg-green-100 text-indigo-600 rounded-lg px-2">100</span></h2>
	//           <p className="text-gray-600">Total Products</p>
	//         </div>

	//         {/* Card 2: Total Variants */}
	//         <div className="col-span-12 md:col-span-3 bg-white p-4 py-6 rounded-md shadow-md">
	//           <h2 className="text-xl font-bold mb-2">300</h2>
	//           <p className="text-gray-600">Total Variants</p>
	//         </div>

	//         {/* Card 3: Total Sold */}
	//         <div className="col-span-12 md:col-span-3 bg-white p-4 py-6 rounded-md shadow-md">
	//           <h2 className="text-xl font-bold mb-2">3000</h2>
	//           <p className="text-gray-600">Total Sold</p>
	//         </div>

	//         {/* Card 4: Total Income */}
	//         <div className="col-span-12 md:col-span-3 bg-white p-4 py-6 rounded-md shadow-md">
	//           <h2 className="text-xl font-bold mb-2"><span className="bg-green-100 text-green-600 rounded-lg px-2">$5000</span></h2>
	//           <p className="text-gray-600">Total Income</p>
	//         </div>

	//         {/* Add more cards as needed */}

	//       </div>
	//     </div>
	// </>

	//   );

	return (
		<>
			<div className={styles.shop}>
				<div className={styles.background}>
					<img src={profile?.seller?.bannerUrl} className="" alt="Background" />
				</div>
				<Card className={styles.card}>
					<Profile sellerDetail={profile} />
					<div className={styles.control}>
						{/* <div className={styles.nav}>
            {/* <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div> */}
						<div className={styles.dropdownBox}>
							{/* <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={intervals}
                small
              /> */}
						</div>
						{/* <Filters
              className={styles.filters}
              title="Showing 9 of 32 products"
            >
              <Settings />
            </Filters> */}
					</div>
					<div className={styles.wrap}>
						{activeIndex === 0 && (
							<>
								{/* <div className={styles.products}>
                  {products.map((x, index) => (
                    <Product
                      className={styles.product}
                      item={x}
                      key={index}
                      withoutСheckbox
                    />
                  ))}
                </div> */}
								{/* <div className={styles.foot}>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                  >
                    Load more
                  </button>
                </div> */}
							</>
						)}
						{activeIndex === 1 && (
							<>
								{/* <div className={styles.followers}>
                  {followers.map((x, index) => (
                    <Follower
                      className={styles.follower}
                      item={x}
                      key={index}
                      followers
                    />
                  ))}
                </div> */}
								{/* <div className={styles.foot}>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                  >
                    Load more
                  </button>
                </div> */}
							</>
						)}
						{activeIndex === 2 && (
							<>
								{/* <div className={styles.followers}>
                  {followers.map((x, index) => (
                    <Follower
                      className={styles.follower}
                      item={x}
                      key={index}
                    />
                  ))}
                </div> */}
								{/* <div className={styles.foot}>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                  >
                    Load more
                  </button>
                </div> */}
							</>
						)}
					</div>
				</Card>
			</div>
		</>
	);
};

export default Shop;
