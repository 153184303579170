import React, { useState, useEffect, useRef } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useFormik } from "formik";
import * as yup from "yup";

function OrderStatusModal(props) {
  const [selectedFileUrl, setselectedFileUrl] = useState(null);
  const uploadFilePopupRef = useRef();

  //═════════════║ THIS IS YUP VALIDATION SCHEMA ║══════════════════
  let validationSchema = yup.object({
    orderStatus: yup.string().required("Select order Status"),
  });

  //═════════════║ THIS IS FORMIK INITIAL VALUES ║══════════════════
  const initialValues = {
    orderStatus: "",
  };

  //═════════════║ THIS IS FORMIK OBJECT ║══════════════════
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("form values", values);
      props?.updateOrders(values?.orderStatus);
    },
    validationSchema,
  });

 

  //═════════════║ THIS FUNCTION FEEDS DATA IN CASE OF EDIT ║══════════════════
  const feedFormData = () => {
    console.log("inside feed data", props?.currentIdData);
        setselectedFileUrl(props?.currentIdData?.orderStatus);
  };

  useEffect(() => {
    if (props?.currentId !== null) {
      feedFormData();
    }
  }, [props?.currentId]);

  useEffect(() => {
    if (props?.uploadFilePopupStatus) {
      if (!uploadFilePopupRef.current.open) {
        //═════════════║ RESETTING THE FORM VALUE ║══════════════════
        formik.resetForm();
        uploadFilePopupRef.current.showModal();
      }
    } else {
      if (uploadFilePopupRef.current.open) {
        uploadFilePopupRef.current.close();
      }
    }
  }, [props?.uploadFilePopupStatus]);
  
  

  return (
    <>
      <dialog ref={uploadFilePopupRef} className="bg-transparent">
        <div className="w-full mx-auto my-4 bg-white relative px-2 rounded-xl shadow shadow-slate-300">
          <div className="px-8 pt-4 bg-red-400">
            <button
              onClick={() => props?.handlePackageModalClose()}
              type="button"
              class="absolute top-20 right-0 text-gray-400 bg-transparent hover:bg-red-300 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center "
            >
              <svg class="w-5 h-5" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <h1 className="text-2xl font-medium">Update Order Status</h1>
          </div>

          <form
            className="p-8"
            onSubmit={formik.handleSubmit}
          >
            <div className="form-group col-span-12 md:col-span-2 mb-4 md:px-4">
              <label
                className={
                  "form-label inline-block mb-1 text-gray-600 text-sm font-semibold"
                }
              >
                {props?.title}
              </label>
              <select
               accept={props?.type=='bannerUrl'?".png, .jpg, .jpeg":".pdf"}
                {...formik.getFieldProps("orderStatus")}
              
                className={
                  "form-control block w-full px-3 2xl:py-1.5 py-1 2xl:text-base text-sm font-normal text-gray-700  bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none placeholder-gray-300 shadow-md h-10 cursor-pointer "
                }
              >
                {Array.isArray(props?.orderStatusOptions) && props?.orderStatusOptions?.map((data,index)=>(
                  <option key={`orderStaus${index}`} value={data?.value}>{data?.label}</option>
                ))}
                </select>
              <span className="text-red-600 absolute text-xs">
                {formik.touched.orderStatus && formik.errors.orderStatus
                  ? formik.errors.orderStatus
                  : null}
              </span>
            </div>

            {/* ═════════════║4 THIS IS FILE PREVIEW ║══════════════════  */}
            <div className="col-span-12">
              <img
                className="w-28 mx-auto rounded-full"
                src={selectedFileUrl}
                alt=""
              />
            </div>

            {/* ══════════════════════════════║🔰 LOGIN BUTTONS 🔰║═══════════════════════════════════ */}
            <div>
              {props?.isLoading ? (
                <button
                  type="button"
                  disabled
                  className="inline-flex w-full items-center justify-center rounded-md bg-pink-600 px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-pink-700"
                >
                  <RotatingLines
                    strokeColor="white"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="25"
                    visible={true}
                  />
                </button>
              ) : (
                <button
                  type="submit"
                  className="inline-flex w-full items-center justify-center rounded-md bg-pink-600 px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-pink-700"
                >
                  Update
                </button>
              )}
            </div>
          </form>
        </div>
      </dialog>
    </>
  );
}

export default OrderStatusModal;
