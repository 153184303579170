import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../utils/axios";
import { showToast, TOAST_ERROR, TOAST_SUCCESS } from "../../utils/constants";
import {
	UPDATE_DOCUMENTS,
	SELLER_PROFILE,
	IMAGE_UPLOAD_API,
} from "../../utils/api";
import CommonUploadFilePopup from "../../components/CommonFileUpload/CommonUploadFilePopup";
import { RotatingLines } from "react-loader-spinner";

const Documents = () => {
	const [uploadedDocuments, setuploadedDocuments] = useState(null);
	const [uploadFilePopupStatus, setuploadFilePopupStatus] = useState(false);
	const [currentSelectedDocument, setcurrentSelectedDocument] = useState(null);
	const [isLoading, setisLoading] = useState(false);
	const [isLoadingDocumentUpload, setisLoadingDocumentUpload] = useState(false);
	const [docKey, setdocKey] = useState(null);
	const [sellerData, setsellerData] = useState(null);
	const profile = useSelector((state) => state.global.profile);
	const identityProof = profile?.seller?.identityProof;
	const commercialLicense = profile?.seller?.commercialLicense;
	const bannerUrl = profile?.seller?.bannerUrl;
	const isPdf = (url) => url && url.endsWith(".pdf");

	// function to open the pdf in new tab
	const previewPdf = (url) => {
		const viewerUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
			url
		)}`;
		window.open(viewerUrl, "_blank");
	};

	// ════════════════════║ THIS FUNCTION FETCHES UPLOADED DOCUMENTS ║═════════════════════════
	const fetchUploadedDocuments = () => {
		setisLoading(true);

		axiosInstance
			.get(SELLER_PROFILE)
			.then((response) => {
				if (response?.data?.error === false) {
					console.log("uplaoded doucments...", response?.data);
					setsellerData(response?.data?.payload);
				}
			})
			.catch((error) => console.error("Error fetching doucments:", error))
			.finally(() => {
				setisLoading(false);
			});
	};

	// ════════════════════║ THIS FUNCTION UPDATES THE DOCUMENT BY UPLOAD ║═════════════════════════
	const updateDocumentsUpload = (docUrl, docName) => {
		let requestBody = {
			key: docName,
			documentUrl: docUrl,
		};
		console.log(" 3 before update document", requestBody);

		axiosInstance
			.post(UPDATE_DOCUMENTS, requestBody)
			.then((response) => {
				console.log(" 4 after update document");

				if (response?.data?.error === false) {
					showToast("Document uploaded successfully !", TOAST_SUCCESS);
					handleModalClose();
					fetchUploadedDocuments();
				} else {
					showToast(response?.data?.message, TOAST_ERROR);
				}
			})
			.catch((error) => {
				console.error("Error fetching product details:", error);
				showToast("Something went wrongg !", TOAST_ERROR);
			})
			.finally(() => {
				setisLoadingDocumentUpload(false);
			});
	};

	// ════════════════════║ THIS FUNCTION UPDATES THE DOCUMENT BY UPLOAD ║═════════════════════════
	const uploadImageHelper = (docName) => {
		setisLoadingDocumentUpload(true);

		const formData = new FormData();
		formData.append("image", currentSelectedDocument);
		console.log(" 1 before upload image", docName);

		axiosInstance
			.post(IMAGE_UPLOAD_API, formData, {
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then((response) => {
				console.log(" 2 after upload image", response);

				if (response?.data?.error === false) {
					updateDocumentsUpload(response?.data?.payload?.full_url, docName);
				} else {
					showToast(response?.data?.message, TOAST_ERROR);
				}
			})
			.catch((error) => {
				console.error("Error fetching product details:", error);
				showToast("Something went wrongg !", TOAST_ERROR);
			});
	};

	const handleModalClose = () => {
		setuploadFilePopupStatus(false);
	};

	useEffect(() => {
		fetchUploadedDocuments();
	}, []);

	return (
		<>
			<CommonUploadFilePopup
				title="Upload Documents"
				type={docKey}
				uploadFilePopupStatus={uploadFilePopupStatus}
				setuploadFilePopupStatus={setuploadFilePopupStatus}
				currentId={null}
				currentIdData={null}
				isLoading={isLoadingDocumentUpload}
				setpackageImage={setcurrentSelectedDocument}
				commonImageUrl={""}
				uploadImageHelper={uploadImageHelper}
				handlePackageModalClose={handleModalClose}
			/>
			<div className="bg-gray-200 p-6 shadow-lg rounded-lg space-y-6">
				<h1 className="text-3xl font-semibold text-center text-indigo-700 mb-6 baiBold">
					Your Documents
				</h1>

				<div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
					{["identityProof", "commercialLicense", "bannerUrl"].map(
						(docType) => {
							const docUrl = sellerData?.seller?.[docType];

							const labelMap = {
								identityProof: "Identity Proof",
								commercialLicense: "Commercial License",
								bannerUrl: "Banner Image",
							};

							return (
								<div
									key={docType}
									className="h-auto border p-4 rounded-lg bg-white shadow-sm hover:shadow-md transition-shadow relative"
								>
									<label className="block text-lg font-semibold text-gray-800 mb-4">
										{labelMap[docType]}
									</label>

									{isLoading && (
										<div className="w-full h-40 flex justify-center items-center">
											<RotatingLines
												strokeColor="#2563EB"
												strokeWidth="5"
												animationDuration="0.75"
												width="25"
												visible={true}
											/>
										</div>
									)}

									{!isLoading && (
										<div className="flex flex-col items-center space-y-2">
											{!isPdf(docUrl) ? (
												<img
													src={docUrl}
													// src={'/photo.png'}
													alt={labelMap[docType]}
													className=" h-28 rounded-lg object-cover object-center"
												/>
											) : (
												<img
													src={"/pdf.png"}
													alt={labelMap[docType]}
													className=" h-28 rounded-lg object-cover object-center"
												/>
											)}
											<div className="flex space-x-4 pt-4">
												{isPdf(docUrl) && (
													<button
														onClick={() => previewPdf(docUrl)}
														className="flex items-center bg-blue-500 text-white px-2 py-2 rounded-full hover:bg-blue-600 focus:outline-none absolute top-0 right-0"
														title="Preview PDF"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="none"
															viewBox="0 0 24 24"
															strokeWidth={1.5}
															stroke="currentColor"
															className="w-6 h-6"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
															/>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
															/>
														</svg>
														{/* Preview */}
													</button>
												)}
												{docUrl ? (
													<a
														href={docUrl}
														download
														className="flex items-center bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 focus:outline-none"
														title="Download Document"
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="none"
															viewBox="0 0 24 24"
															strokeWidth={1.5}
															stroke="currentColor"
															className="w-6 h-6"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
															/>
														</svg>
														Download
													</a>
												) : null}

												<button
													onClick={() => {
														setdocKey(docType);
														setuploadFilePopupStatus(true);
													}}
													className="flex items-center bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 focus:outline-none"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-6 h-6"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M12 5v10M5 12l7-7 7 7M12 15l-1.5-1.5M12 15l1.5-1.5"
														/>
													</svg>
													Update
												</button>
											</div>
										</div>
									)}
								</div>
							);
						}
					)}
				</div>
			</div>
		</>
	);
};

export default Documents;
