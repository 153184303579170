import React, { createContext, useState, useContext } from "react";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
	const [productData, setProductData] = useState({
		name: "",
		description: "",
		images: [],
		marketPrice: "", // Initialized as number
		discountPrice: "", // Initialized as number
		sellingPrice: "", // Initialized as number
		stock: "", // Initialized as number
		heading: "",
		data: "",
		brand: "",
		category: null,
		isCodEnabled: false,
		isReturnApplicable: false,
		isFreeDelivery: false,
		warrantyDetails: "",
		// ... add other necessary initial states
	});

	return (
		<ProductContext.Provider value={{ productData, setProductData }}>
			{children}
		</ProductContext.Provider>
	);
};

export const useProduct = () => {
	return useContext(ProductContext);
};
