/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Footer = () => {
	return (
		<div>
			<footer className="fixed flex gap-x-5 px-2 text-xs left-0 bottom-0 w-full text-black py-1 max-md:text-xs max-md:justify-between max-md:px-0">
				<p className="m-0 p-0">
					&copy; {new Date().getFullYear()} Make It Less
				</p>
				<div>
					<a href="#" className="text-light-blue-300 hover:underline mr-4 max-md:hidden">
						Privacy Policy
					</a>
					<a href="#" className="text-light-blue-300 hover:underline max-md:hidden">
						Terms of Service
					</a>
				</div>
			</footer>
		</div>
	);
};

export default Footer;
