import React, { useState } from "react";
import { SEND_QUERIES } from "../../utils/api";
import axiosInstance from "../../utils/axios";
import { RotatingLines } from "react-loader-spinner";
import {  useNavigate } from "react-router-dom";

const ContactAdmin = () => {
	const [formData, setFormData] = useState({
		businessName: "",
		businessEmail: "",
		message: "",
	});
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};


	const handleRedirect = () => {
        navigate('/'); // Redirects to the home page
    };

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true); // Start loading

		try {
			const response = await axiosInstance.post(SEND_QUERIES, formData);
			console.log(response.data);
			setSubmitted(true);
		} catch (error) {
			console.error("Error submitting form:", error);
		} finally {
			setLoading(false); // Stop loading regardless of success or error
		}
	};

	return (
		<div className="flex justify-center h-screen bg-gray-100">
			{!submitted ? (
				<form
					onSubmit={handleSubmit}
					className="w-full max-w-lg p-8 bg-white shadow-lg rounded-lg"
				>
					<h2 className="text-2xl font-semibold text-gray-800 mb-6">
						Contact Admin
					</h2>
					<div className="mb-4">
						<label className="block text-gray-700 text-sm font-bold mb-2">
							Business Name:
						</label>
						<input
							type="text"
							name="businessName"
							value={formData.businessName}
							onChange={handleChange}
							required
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						/>
					</div>
					<div className="mb-4">
						<label className="block text-gray-700 text-sm font-bold mb-2">
							Business Email:
						</label>
						<input
							type="email"
							name="businessEmail"
							value={formData.businessEmail}
							onChange={handleChange}
							required
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						/>
					</div>
					<div className="mb-6">
						<label className="block text-gray-700 text-sm font-bold mb-2">
							Write Your Message:
						</label>
						<textarea
							name="message"
							value={formData.message}
							onChange={handleChange}
							required
							className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							rows="4"
						/>
					</div>
					<button
						type="submit"
						disabled={loading}
						className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out flex justify-center items-center"
					>
						{loading ? (
							<RotatingLines
								strokeColor="white"
								strokeWidth="5"
								animationDuration="0.75"
								width="25"
								visible={true}
							/>
						) : (
							"Submit"
						)}
					</button>
				</form>
			) : (
				<section className="py-10">
				<div className="mx-auto max-w-7xl">
				  <div className="mx-auto max-w-2xl  text-center">
		
					<h2 className="mt-8 text-3xl font-bold leading-tight text-black sm:text-4xl lg:mt-12 lg:text-5xl">
					  Join <span className="border-b-8 border-yellow-300">5,482</span> other developers
					</h2>
					<p className="mx-auto mt-6 max-w-xl text-base text-gray-600 md:mt-10 lg:text-xl">
					  Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
					  consequat duis.
					</p>
		  
					<button
					onClick={handleRedirect} 
					  type="button"
					  className="mt-8 rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500"
					>
					  Get Access Now
					</button>
				  </div>
				</div>
			  </section>
			)}
		</div>
	);
};

export default ContactAdmin;
