import React, { useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";

// import RequestCategory from "../../screens/RequestCategory";

const navigation = [
	{
		title: "Home",
		icon: "home",
		url: "/",
	},

	{
		title: "Product",
		slug: "category",
		icon: "diamond",
		add: true,
		addUrl: "/products/add",
		dropdown: [
			// {
			// 	title: "Category",
			// 	url: "/category/dashboard",
			// },
			// {
			//     title: "Drafts",
			//     url: "/products/drafts",
			//     counter: "2",
			//     colorCounter: "#FFBC99",
			// },

			// {
			//     title: "Comments",
			//     url: "/products/comments",
			// },
			// {
			//     title: "Scheduled",
			//     url: "/products/scheduled",
			//     counter: "8",
			//     colorCounter: "#B5E4CA",
			// },
			{
				title: "Released",
				url: "/products/released",
			},
		],
	},

	// {
	// 	title: "Vairant",
	// 	slug: "product",
	// 	icon: "pie-chart",
	// 	add: true,
	// 	addUrl: "/variant/add",
	// 	dropdown: [
	// 		// {
	// 		// 	title: "Category",
	// 		// 	url: "/category/dashboard",
	// 		// },
	// 		// {
	// 		//     title: "Drafts",
	// 		//     url: "/products/drafts",
	// 		//     counter: "2",
	// 		//     colorCounter: "#FFBC99",
	// 		// },
	// 		// {
	// 		// 	title: "All Variant",
	// 		// 	url: "/variants/all",
	// 		// },
	// 		// {
	// 		//     title: "Comments",
	// 		//     url: "/products/comments",
	// 		// },
	// 		// {
	// 		//     title: "Scheduled",
	// 		//     url: "/products/scheduled",
	// 		//     counter: "8",
	// 		//     colorCounter: "#B5E4CA",
	// 		// },
	// 	],
	// },

	// {
	// 	title: "Customers",
	// 	slug: "customers",
	// 	icon: "profile-circle",
	// 	dropdown: [
	// 		// {
	// 		// 	title: "Customer list",
	// 		// 	url: "/customers/customer-list",
	// 		// },
	// 		// {
	// 		// 	title: "Refund",
	// 		// 	url: "/income/refunds",
	// 		// },
	// 	],
	// },
	{
		title: "Orders",
		icon: "pie-chart",
		url: "/Orders",
	},
	// {
	// 	title: "Request Category",
	// 	icon: "pie-chart",
	// 	url: "/requestcategory",
	// },
	{
		title: "Documents",
		icon: "pie-chart",
		url: "/documentupload",
	},

	{
		title: "Shop",
		icon: "store",
		url: "/shop",
	},
	{
		title: "Contact Admin",
		icon: "profile-circle",
		url: "/contactadmin",
	},
	{
		title: "Change Password",
		icon: "profile-circle",
		url: "/changepassword",
	},
	// {
	// 	title: "Administrator",
	// 	icon: "store",
	// 	url: "/products/scheduled",
	// },
	// {
	//     title: "Income",
	//     slug: "income",
	//     icon: "pie-chart",
	//     dropdown: [
	//         {
	//             title: "Earning",
	//             url: "/income/earning",
	//         },
	//         {
	//             title: "Refunds",
	//             url: "/income/refunds",
	//         },
	//         {
	//             title: "Payouts",
	//             url: "/income/payouts",
	//         },
	//         {
	//             title: "Statements",
	//             url: "/income/statements",
	//         },
	//     ],
	// },
];

const Sidebar = ({ className, onClose }) => {
	const [visibleHelp, setVisibleHelp] = useState(false);
	const [visible, setVisible] = useState(false);

	const { pathname } = useLocation();

	return (
		<>
			<div
				className={`${cn(styles.sidebar, className, {
					[styles.active]: visible,
				})} `}
			>
				<div className="flex ">
				<div className="flex-1">
						<div className={`${styles.pic} h-24 w-32`} onClick={onClose}>
							<a href="/">
								<Image
									className={`${styles.pic} cursor-pointer`}
									src="https://res.cloudinary.com/dcpte972l/image/upload/v1692191670/Holiday_Vacation_Travel_Agent_Promotion_Banner_Landscape_wvx6bq.png"
									srcDark="https://res.cloudinary.com/dcpte972l/image/upload/v1692191670/Holiday_Vacation_Travel_Agent_Promotion_Banner_Landscape_wvx6bq.png"
									alt="Core"
								/>
							</a>
						</div>
					</div>
					<div className="flex-1 flex justify-end  items-start pt-4 pr-4 md:hidden">
						<button className={''} onClick={onClose}>
							<Icon name="close" size="24" style={{ color: "red" }} />
						</button>
					</div>
					
				</div>
				<div className={styles.menu}>
					{navigation.map((x, index) =>
						x.url ? (
							<NavLink
								className={cn(styles.item, {
									[styles.active]: pathname === x.url,
								})}
								to={x.url}
								key={index}
								onClick={onClose}
							>
								<Icon name={x.icon} size="24" />
								{x.title}
							</NavLink>
						) : (
							<Dropdown
								className={styles.dropdown}
								visibleSidebar={visible}
								setValue={setVisible}
								key={index}
								item={x}
								onClose={onClose}
							/>
						)
					)}
				</div>
				<button className={styles.toggle} onClick={() => setVisible(!visible)}>
					<Icon name="arrow-right" size="24" />
					<Icon name="close" size="24" />
				</button>
				<div className={styles.foot}>
					{/* <button className={styles.link} onClick={() => setVisibleHelp(true)}>
						<Icon name="help" size="24" />
						Help & getting started
						<div className={styles.counter}>8</div>
					</button> */}
					<Theme className={styles.theme} visibleSidebar={visible} />
				</div>
			</div>
			<Help
				visible={visibleHelp}
				setVisible={setVisibleHelp}
				onClose={onClose}
			/>
			<div
				className={cn(styles.overlay, { [styles.active]: visible })}
				onClick={() => setVisible(false)}
			></div>
		</>
	);
};

export default Sidebar;
