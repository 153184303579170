import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axios";
import { Link, useNavigate } from "react-router-dom";
import { ALL_ORDERS, UPDATE_ORDER_STATUS } from "../../../utils/api";
import OrderStatusModal from "../../../components/OrderStatusModal/OrderStatusModal";
import {
	CURRENCY,
	formatPrice,
	showToast,
	TOAST_ERROR,
	TOAST_SUCCESS,
} from "../../../utils/constants";
import { logger } from "../../../utils/logger";
import moment from "moment";
const Table = () => {
	// Do not change orders state from [false].
	// if changed the loading animation and message `No orders' won't appear.
	const [uploadFilePopupStatus, setuploadFilePopupStatus] = useState(false);
	const [currentId, setcurrentId] = useState(null);
	const [currentIdData, setcurrentIdData] = useState(null);
	const [isLoadingOrderUpdate, setisLoadingOrderUpdate] = useState(false);
	const [orders, setOrders] = useState([false]);
	const navigate = useNavigate();

	const orderStatusOptions = [
		{ label: "Pending", value: "PENDING" },
		{ label: "Processing", value: "PROCESSING" },
		{ label: "Shipped", value: "SHIPPED" },
		{ label: "Out for Delivery", value: "OUT_FOR_DELIVERY" },
		{ label: "Delivered", value: "DELIVERED" },
		{ label: "Canceled", value: "CANCELED" },
		{ label: "Refunded", value: "REFUNDED" },
		{ label: "On Hold", value: "ON_HOLD" },
		{ label: "Awaiting Payment", value: "AWAITING_PAYMENT" },
		{ label: "Partially Shipped", value: "PARTIALLY_SHIPPED" },
		{ label: "Return Requested", value: "RETURN_REQUESTED" },
		{ label: "Return in Progress", value: "RETURN_IN_PROGRESS" },
		{ label: "Return Completed", value: "RETURN_COMPLETED" },
		{ label: "Exchange Requested", value: "EXCHANGE_REQUESTED" },
		{ label: "Exchange in Progress", value: "EXCHANGE_IN_PROGRESS" },
		{ label: "Exchange Completed", value: "EXCHANGE_COMPLETED" },
		{ label: "Failed", value: "FAILED" },
		{ label: "Archived", value: "ARCHIVED" },
	];

	// ════════════════════║ THIS FUNCTION UPDATES THE ORDER STATUS   ║═════════════════════════
	const updateOrders = async (status) => {
		setisLoadingOrderUpdate(true);
		let url = `${UPDATE_ORDER_STATUS}/${currentId}`;
		let requestBody = {
			status: status,
		};
		logger.log(`before updating status....${JSON.stringify(requestBody)}`);
		try {
			const response = await axiosInstance.post(url);
			logger.log(`after updating status ${JSON.stringify(response)}`);
			if (response?.data?.error == false) {
				showToast("Order status updated successfully !", TOAST_SUCCESS);
				fetchData();
				handlePackageModalClose();
			} else {
				showToast(response?.data?.message, TOAST_ERROR);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			showToast("Something went wrongg !", TOAST_ERROR);
		} finally {
			setisLoadingOrderUpdate(false);
		}
	};

	const handlePackageModalClose = () => {
		setcurrentId(null);
		setcurrentIdData(null);
		setuploadFilePopupStatus(false);
	};

	// ════════════════════║ THIS FUNCTION FETCHES ORDER LIST   ║═════════════════════════
	const fetchData = async () => {
		try {
			const response = await axiosInstance.get(ALL_ORDERS);
			setOrders(response.data.payload.docs);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<OrderStatusModal
				title="Update Order Status"
				type={""}
				uploadFilePopupStatus={uploadFilePopupStatus}
				setuploadFilePopupStatus={setuploadFilePopupStatus}
				currentId={null}
				currentIdData={null}
				isLoading={isLoadingOrderUpdate}
				updateOrders={updateOrders}
				handlePackageModalClose={handlePackageModalClose}
				orderStatusOptions={orderStatusOptions}
			/>
			<section
				className="container px-4 mx-auto"
				style={{ width: "100%", padding: 0, paddingRight: 25 }}
			>
				<div className="flex flex-col mt-6">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
							{orders[0] == false ? (
								<div className="w-full flex justify-center text-2xl gap-x-2">
									Loading Orders
									<svg
										version="1.1"
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										enable-background="new 0 0 0 0"
										className="w-8 scale-150 ml-2"
									>
										<circle
											fill="#fff"
											stroke="none"
											cx="6"
											cy="50"
											r="6"
											className="fill-pink-600"
										>
											<animateTransform
												attributeName="transform"
												dur="1s"
												type="translate"
												values="0 15 ; 0 -15; 0 15"
												repeatCount="indefinite"
												begin="0.1"
											/>
										</circle>
										<circle
											fill="#fff"
											stroke="none"
											cx="30"
											cy="50"
											r="6"
											className="fill-pink-600"
										>
											<animateTransform
												attributeName="transform"
												dur="1s"
												type="translate"
												values="0 10 ; 0 -10; 0 10"
												repeatCount="indefinite"
												begin="0.2"
											/>
										</circle>
										<circle
											fill="#fff"
											stroke="none"
											cx="54"
											cy="50"
											r="6"
											className="fill-pink-600"
										>
											<animateTransform
												attributeName="transform"
												dur="1s"
												type="translate"
												values="0 5 ; 0 -5; 0 5"
												repeatCount="indefinite"
												begin="0.3"
											/>
										</circle>
									</svg>
								</div>
							) : orders.length ? (
								<table className="min-w-full overflow-hidden bg-white shadow-lg rounded-lg">
									<thead>
										<tr className="text-xs md:text-md">
											<th className="px-2 py-3 text-center tracking-wider  text-white uppercase bg-pink-600">
												Order ID
											</th>
											<th className="px-2 py-3 text-center tracking-wider  text-white uppercase bg-pink-600">
												User Name
											</th>
											<th className="px-2 py-3 text-center tracking-wider  text-white uppercase bg-pink-600">
												User Name Email
											</th>
											<th className="px-2 py-3 text-center tracking-wider  text-white uppercase bg-pink-600">
												User Mobile Number
											</th>
											<th className="px-2 py-3 text-center tracking-wider  text-white uppercase bg-pink-600">
												Grand Total
											</th>
											<th className="px-2 py-3 text-center tracking-wider  text-white uppercase bg-pink-600">
												created At
											</th>
											<th className="px-2 py-3 text-center tracking-wider  text-white uppercase bg-pink-600">
												Payment Status
											</th>
											<th className="px-2 py-3 text-center tracking-wider  text-white uppercase bg-pink-600">
												Order Id
											</th>
											<th className="px-2 py-3 text-center tracking-wider  text-white uppercase bg-pink-600">
												Action
											</th>
										</tr>
									</thead>
									<tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700">
										{orders.map((order, index) => (
											<tr key={index}>
												<td className="p-2 text-xs text-center text-gray-900 hover:text-pink-600 whitespace-nowrap cursor-pointer dark:text-gray-900">
													<Link to={`/order/detail/${order?._id}`}>
														{order?.orderId}
													</Link>
												</td>
												<td className="p-2 text-xs text-center text-gray-900  whitespace-nowrap dark:text-gray-900">
													{order?.user?.name}
												</td>
												<td className="p-2 text-xs text-center text-gray-900  whitespace-nowrap dark:text-gray-900">
													{order?.user?.email}
												</td>
												<td className="p-2 text-xs text-center text-gray-900  whitespace-nowrap dark:text-gray-900">
													{order?.user?.mobileNumber}
												</td>
												<td className="p-2 text-xs text-center text-gray-900  whitespace-nowrap dark:text-gray-900">
													{CURRENCY} {formatPrice(order?.grandTotal)}
												</td>
												<td className="p-2 text-xs text-center text-gray-900  whitespace-nowrap dark:text-gray-900">
													{moment(order?.createdAt).format(
														"dddd, MMMM Do YYYY, h:mm:ss a"
													)}
												</td>
												<td
													className={`p-2 text-xs text-center whitespace-nowrap  ${
														order.paymentStatus === "COMPLETED"
															? "text-green-600"
															: "text-red-600"
													}`}
												>
													{order.paymentStatus}
												</td>
												<td
													className={`p-2 text-xs text-center whitespace-nowrap  ${
														order.orderStatus === "PENDING"
															? "text-red-600"
															: "text-green-600"
													}`}
												>
													{order?.orderId}
												</td>
												<td
													className={`p-2 text-xs text-center whitespace-nowrap`}
												>
													{/* <button
														onClick={() =>{ 
															setcurrentId(order?._id)
															setcurrentIdData(order)
															setuploadFilePopupStatus(true)
														}}
														type="button"
														className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
													>
														Update Status
													</button> */}

													<button
														onClick={() =>
															navigate(`/order/detail/${order?._id}`)
														}
														type="button"
														className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
													>
														View
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							) : (
								<div className="w-full flex justify-center text-2xl">
									No orders yet...
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Table;
