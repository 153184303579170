import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const DeleteModal = ({ isOpen, onClose, onConfirm }) => {
	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-y-auto"
				onClose={onClose}
			>
				<div className="min-h-screen px-4 text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-50" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="inline-block h-screen align-middle"
						aria-hidden="true"
					>
						&#8203;
					</span>

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
							<div className="flex justify-between items-start">
								<Dialog.Title
									as="h3"
									className="text-xl font-semibold leading-6 text-gray-900"
								>
									Confirm Deletion
								</Dialog.Title>
								<button
									type="button"
									className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
									onClick={onClose}
								>
									<span className="sr-only">Close</span>
									{/* Assuming you are using Heroicons, for example */}
									<svg
										className="h-6 w-6"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M6 18L18 6M6 6l12 12"
										/>
									</svg>
								</button>
							</div>
							<div className="mt-2">
								<p className="text-sm text-gray-500">
									Are you sure you want to delete this item? This action cannot
									be undone.
								</p>
							</div>

							<div className="mt-4 flex justify-end gap-3">
								<button
									type="button"
									className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-slate-500 border border-gray-300 rounded-md hover:bg-slate-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-pink-600"
									onClick={onClose}
								>
									Cancel
								</button>
								<button
									type="button"
									className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
									onClick={onConfirm}
								>
									Delete
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default DeleteModal;
